import { useState, useEffect } from "react";

import { Row, Col, CardBody } from "reactstrap";

import styled, { css } from "styled-components";

import moment from "moment";

import {
  DashboardCardTitle,
  DashboardCardSubTitle,
  LinkToReport,
  TimesheetTime,
} from "../../styledComponents/dashboard";
import {
  CommonCardContainer,
  NoDataContainer,
  NoDataImg,
  NoDataText,
  WeeklyActivityTitleContainer,
  CardTitleAndSubtitleContainer,
  TimsheetSectionContainer,
  TimesheetSingleSection,
  TimesheetDate,
} from "./cardStyles";

import WeeklyTimesheetLoader from "../ContentLoader/WeeklyTimesheetLoader";

import clockIcon from "../../assets/img/icons/clock_icon.svg";
import noDataImg from "../../assets/img/common/nodata_timesheet.svg";

const TimesheetTimeText = styled.div`
  font-size: 13px;
  color: ${({ color, theme }) => color || theme.colors.main.textSecondary};
  margin-left: 7px;
`;
const TimesheetTimeIcon = styled.img`
  height: 15px;
  width: 15px;
  ${({ whiteFilter }) =>
    whiteFilter &&
    css`
      filter: brightness(0) saturate(100%) invert(99%) sepia(1%) saturate(0%)
        hue-rotate(52deg) brightness(108%) contrast(101%);
    `}
`;

const getTotal = (timesheet, getTime) => {
  let total = 0;
  timesheet.map((item) => {
    total += item.duration;
  });
  return getTime(total);
};

const WeeklyTimesheetCard = (props) => {
  const [timesheet, setTimesheet] = useState([]);

  useEffect(() => {
    if (props.weeklyTimesheet && props.weeklyTimesheet.length > 0) {
      let times = [];
      let startDate = moment().subtract(1, "day").startOf("isoWeek");
      while (
        moment(startDate).isSameOrBefore(
          moment().subtract(1, "day").endOf("isoWeek")
        )
      ) {
        times.push({
          date: startDate,
          time: "0h 00m",
          duration: 0,
        });
        startDate = moment(startDate).add(1, "days");
      }
      props.weeklyTimesheet.map((item) => {
        times.map((date) => {
          if (moment(date.date).format("YYYY-MM-DD") === item.date) {
            date.time = props.getTime(item.total_hours);
            date.duration = parseInt(item.total_hours);
          }
        });
      });
      setTimesheet(times);
    } else if (
      (props.weeklyTimesheet && props.weeklyTimesheet.length === 0) ||
      !props.weeklyTimesheet
    ) {
      setTimesheet([]);
    }
  }, [props.weeklyTimesheet]);

  return (
    <CommonCardContainer>
      <WeeklyActivityTitleContainer>
        <CardTitleAndSubtitleContainer>
          <DashboardCardTitle>Timesheet</DashboardCardTitle>
          <DashboardCardSubTitle>All Projects</DashboardCardSubTitle>
        </CardTitleAndSubtitleContainer>
        {timesheet && timesheet.length > 0 && (
          <LinkToReport to="/user/timesheet/custom-range">
            View More
          </LinkToReport>
        )}
      </WeeklyActivityTitleContainer>
      {!props.isLoading && timesheet && timesheet.length === 0 && (
        <NoDataContainer marginTop="5px">
          <NoDataImg height="100px" src={noDataImg} alt="no-data" />
          <NoDataText>No data found.</NoDataText>
        </NoDataContainer>
      )}
      {!props.isLoading && timesheet && timesheet.length > 0 && (
        <TimsheetSectionContainer
          full={props.plan && props.plan === "unmonitored"}
        >
          {timesheet.map((item, index) => {
            return (
              <TimesheetSingleSection key={index}>
                <TimesheetDate>
                  {moment(item.date).format("MMM DD")}
                </TimesheetDate>
                <TimesheetTime>
                  <TimesheetTimeIcon src={clockIcon} alt="" />
                  <TimesheetTimeText>{item.time}</TimesheetTimeText>
                </TimesheetTime>
              </TimesheetSingleSection>
            );
          })}
          <TimesheetSingleSection>
            <TimesheetDate fontWeight="600">Weekly Total</TimesheetDate>
            <TimesheetTime borderColor="#20bead" background="#20bead">
              <TimesheetTimeIcon src={clockIcon} alt="" whiteFilter />
              <TimesheetTimeText color="#fff">
                {getTotal(timesheet, props.getTime)}
              </TimesheetTimeText>
            </TimesheetTime>
          </TimesheetSingleSection>
        </TimsheetSectionContainer>
      )}
      {props.isLoading && (
        <Row style={{ padding: "20px" }}>
          <WeeklyTimesheetLoader />
        </Row>
      )}
    </CommonCardContainer>
  );
};

export default WeeklyTimesheetCard;
