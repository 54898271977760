import { useState, useRef, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import moment from "moment";

import {
  SmallCardLabel,
  SmallCardPercentage,
  SmallCardContent,
  SmallCardLabelEllipsis,
} from "../../styledComponents/dashboard";
import {
  SmallCardContainer,
  DataDiv,
  HourPercentageContainer,
  PercentageArrowIcon,
  BarGraphAndInfoContainer,
  ProjectLastWeekInfo,
  ProjectLastWeekInfoTitle,
  ProjectLastWeekInfoData,
  BarChartCanvasContainer,
} from "./cardStyles";

import {
  totalHourCardChartOptions,
  drawBlankBarPlugin,
} from "../../utils/chartOptions";

import upArrowIcon from "../../assets/img/icons/up_arrow.svg";
import downArrowIcon from "../../assets/img/icons/down_arrow.svg";

const chartLabels = ["Last Week", "This Week"];
const initialChartData = {
  labels: chartLabels,
  datasets: [
    {
      label: "Hour Logged",
      maxBarThickness: 22,
      borderWidth: 1,
      borderRadius: 4,
      borderSkipped: false,
      backgroundColor: "#E9E9E9",
      borderColor: "#E9E9E9",
      hoverBackgroundColor: "#E9E9E9",
      data: [0, 0],
    },
  ],
};

const MostProjectActivityCard = (props) => {
  const [cardValueOrg, setCardValueOrg] = useState("0%");
  const [cardValueUser, setCardValueUser] = useState("0%");
  const [lastWeekOrg, setLastWeekOrg] = useState("0%");
  const [lastWeekUser, setLastWeekUser] = useState("0%");
  const [projectNameOrg, setProjectNameOrg] = useState("");
  const [projectNameUser, setProjectNameUser] = useState("");
  const [cardPercentageOrg, setCardPercentageOrg] = useState("0.00%");
  const [cardPercentageUser, setCardPercentageUser] = useState("0.00%");
  const [dataIncreaseOrg, setDataIncreaseOrg] = useState(true);
  const [dataIncreaseUser, setDataIncreaseUser] = useState(true);

  const [barChartData, setBarChartData] = useState(initialChartData);

  const { organization, value, currentMode } = props;

  const clearState = () => {
    setCardValueOrg("0%");
    setCardValueUser("0%");
    setLastWeekOrg("0%");
    setLastWeekUser("0%");
    setProjectNameOrg("");
    setProjectNameUser("");
    setCardPercentageOrg("0.00%");
    setCardPercentageUser("0.00%");
    setDataIncreaseOrg(true);
    setDataIncreaseUser(true);
  };

  useEffect(() => {
    if (value) {
      const data = { ...initialChartData };
      let chartDataOrg = [0, 0];
      let chartDataUser = [0, 0];
      if (currentMode && currentMode === "organization") {
        if (value.orgThisWeek && value.orgThisWeek.average_activity) {
          setCardValueOrg(
            `${parseInt(value.orgThisWeek.average_activity * 100)}%`
          );
          setProjectNameOrg(value.orgThisWeek.project_name);
          chartDataOrg[1] = parseInt(value.orgThisWeek.average_activity * 100);
        }
        if (value.orgLastWeek && value.orgLastWeek.average_activity) {
          setLastWeekOrg(
            `${parseInt(value.orgLastWeek.average_activity * 100)}%`
          );
          chartDataOrg[0] = parseInt(value.orgLastWeek.average_activity * 100);
        }
        if (
          value.orgThisWeek &&
          value.orgThisWeek.average_activity &&
          value.orgLastWeek &&
          value.orgLastWeek.average_activity
        ) {
          if (
            parseInt(value.orgThisWeek.average_activity * 100) <
            parseInt(value.orgLastWeek.average_activity * 100)
          ) {
            setDataIncreaseOrg(false);
          }
          let percentage = Math.abs(
            parseInt(value.orgThisWeek.average_activity * 100) -
              parseInt(value.orgLastWeek.average_activity * 100)
          );
          setCardPercentageOrg(`${percentage}%`);
        }
      } else if (currentMode && currentMode === "me") {
        if (value.userThisWeek && value.userThisWeek.average_activity) {
          setCardValueUser(
            `${parseInt(value.userThisWeek.average_activity * 100)}%`
          );
          setProjectNameUser(value.userThisWeek.project_name);
          chartDataUser[1] = parseInt(
            value.userThisWeek.average_activity * 100
          );
        }
        if (value.userLastWeek && value.userLastWeek.average_activity) {
          setLastWeekUser(
            `${parseInt(value.userLastWeek.average_activity * 100)}%`
          );
          chartDataUser[0] = parseInt(
            value.userLastWeek.average_activity * 100
          );
        }

        if (
          value.userThisWeek &&
          value.userThisWeek.average_activity &&
          value.userLastWeek &&
          value.userLastWeek.average_activity
        ) {
          if (
            parseInt(value.userThisWeek.average_activity * 100) <
            parseInt(value.userLastWeek.average_activity * 100)
          ) {
            setDataIncreaseUser(false);
          }
          let percentage = Math.abs(
            parseInt(value.userThisWeek.average_activity * 100) -
              parseInt(value.userLastWeek.average_activity * 100)
          );
          setCardPercentageUser(`${percentage}%`);
        }
      }

      setBarChartData({
        labels: chartLabels,
        datasets: [
          {
            ...data.datasets[0],
            data:
              currentMode && currentMode === "organization"
                ? chartDataOrg
                : chartDataUser,
            backgroundColor: "#5ACEC5",
            hoverBackgroundColor: "#5ACEC5",
            borderColor: "#5ACEC5",
          },
        ],
      });
    } else if (value === null) {
      clearState();
      setBarChartData(initialChartData);
    }
  }, [value, currentMode]);

  return (
    <SmallCardContainer>
      <DataDiv>
        <HourPercentageContainer>
          <SmallCardLabelEllipsis title={
            currentMode && currentMode === "organization"
              ? projectNameOrg
              : projectNameUser
          }>
            {currentMode && currentMode === "organization"
              ? projectNameOrg
              : projectNameUser}
          </SmallCardLabelEllipsis>
          <SmallCardPercentage
            increase={
              currentMode && currentMode === "organization"
                ? dataIncreaseOrg
                : dataIncreaseUser
            }
            percentage={
              currentMode && currentMode === "organization"
                ? cardPercentageOrg
                : cardPercentageUser
            }
          >
            <PercentageArrowIcon
              alt=""
              src={
                currentMode && currentMode === "organization"
                  ? dataIncreaseOrg
                    ? upArrowIcon
                    : downArrowIcon
                  : dataIncreaseUser
                  ? upArrowIcon
                  : downArrowIcon
              }
            />
            {currentMode && currentMode === "organization"
              ? cardPercentageOrg
              : cardPercentageUser}
          </SmallCardPercentage>
        </HourPercentageContainer>
        <SmallCardContent>
          {currentMode && currentMode === "organization"
            ? cardValueOrg
            : cardValueUser}
        </SmallCardContent>
      </DataDiv>
      <BarGraphAndInfoContainer>
        <ProjectLastWeekInfo>
          <ProjectLastWeekInfoTitle>Last Week</ProjectLastWeekInfoTitle>
          <ProjectLastWeekInfoData>
            {currentMode && currentMode === "organization"
              ? lastWeekOrg
              : lastWeekUser}
          </ProjectLastWeekInfoData>
        </ProjectLastWeekInfo>
        <BarChartCanvasContainer height="95px" small>
          <Bar
            options={totalHourCardChartOptions({
              tick: false,
              activity: true,
            })}
            data={barChartData}
            width={100}
            plugins={[drawBlankBarPlugin()]}
          />
        </BarChartCanvasContainer>
      </BarGraphAndInfoContainer>
    </SmallCardContainer>
  );
};

export default MostProjectActivityCard;
