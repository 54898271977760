import styled from "styled-components";

export const MemberSettingsSection = styled.div`
  padding: 0 0 20px 0;
  margin: ${({ margin }) => margin || "0"};
`;
export const MemberNameDiv = styled.div`
  cursor: pointer;
  /* &:hover {
    text-decoration: underline;
  }  */
`;

export const MembersTableHeadContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ activity }) =>
    activity ? "18% 10% 5% 10% 12% 10% 9% 7% 10%" : "45% 55%"};
  grid-column-gap: ${({ gap }) => gap || "15px"};
  justify-content: space-between;
  align-items: ${({ activity }) => (activity ? "flex-start" : "initial")};
  padding: 0 40px;
  margin-top: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.secondary};
  @media (max-width: 1250px) {
    grid-template-columns: ${({ activity }) =>
      activity ? "20% 10% 15% repeat(4, 10%)" : "45% 55%"};
    grid-column-gap: 10px;
  }
`;
export const MembersTableContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ activity }) =>
    activity ? "18% 10% 5% 10% 12% 10% 9% 7% 10%" : "45% 55%"};
  grid-column-gap: ${({ gap }) => gap || "15px"};
  justify-content: space-between;
  padding: 0 40px;
  :nth-of-type(even) {
    background-color: #f8f9fc;
  }
  @media (max-width: 1250px) {
    grid-template-columns: ${({ activity }) =>
      activity ? "20% 10% 15% repeat(4, 10%)" : "70% 30%"};
    grid-column-gap: 10px;
  }
`;

export const Container = styled.div`
  display: grid;
  background-color: white;
  border-radius: 10px;
  //border: 1px solid #c2cce1;
  margin-top: 2.7rem;
  margin-right: -40px;
  margin-left: -40px;
  @media (max-width: 1250px) {
    max-width: 100vw;
    overflow: auto;
  }
`;

export const Input = styled.input`
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-position: 8px 9px;
  background-size: 20px;
  background-repeat: no-repeat;
  max-width: 270px;
  font-size: 13px;
  padding: 12px 20px 12px 40px;
  background-color: ${({ theme }) => theme.colors.main.whiteBg};
  border: 1px solid ${({ theme }) => theme.colors.border.secondaryLight};
  border-radius: ${({ theme }) => theme.input.borderRadius};
  // opacity: 0.7;
  height: 40px;
  &:hover {
    border: 1px solid ${({ theme }) => theme.input.borderColor.hover};
    box-shadow: 0 0 0 4px ${({ theme }) => theme.colors.main.focusShadow};
  }
  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.border.primary};
    box-shadow: 0 0 0 4px ${({ theme }) => theme.colors.main.focusShadow};
    outline: none;
  }
`;
