import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import moment from "moment";
import {
  CommonFlex,
  CommonText,
  ModalCrossIcon,
} from "../../../styledComponents/common";
import {
  IdleTimeModalRow,
  DeleteButton,
} from "../../../styledComponents/timesheet";
import { ActivityTextSection } from "../../../styledComponents/members";
import { getZeroHourTime } from "./helperFunctions";
import deleteWhite from "../../../assets/img/icons/delete_white.svg";
import crossIcon from "../../../assets/img/icons/cross_black.svg";
import NoDataComponent from "../../../components/NoDataComponent/NoDataComponent";
import { formatDurationToHoursMinutes } from "../../../utils/helper";

const IdleTimeModal = ({
  isOpen,
  toggle,
  timesheets,
  toggleDeleteTimeModal,
}) => {
  const [idleTimes, setIdleTimes] = useState([]);
  const [totalIdleTime, setTotalIdleTime] = useState(0);
  const handleDeleteIdleTime = (
    startTime,
    endTime,
    duration,
    timesheetId,
    memberId,
    idleTime
  ) => {
    const deleteTimeData = {
      id: timesheetId,
      start_time: startTime,
      end_time: endTime,
      duration: duration,
      memberId: memberId,
      idleTime: idleTime,
    };
    toggleDeleteTimeModal(deleteTimeData);
  };

  useEffect(() => {
    const calculatedIdleTimes = timesheets
      ?.flatMap((timesheet) =>
        timesheet?.idleTimes?.map((idleTime) => {
          return {
            ...idleTime,
            project_name: timesheet.project?.name,
            task_name: timesheet.task?.name,
            start_time: moment(idleTime.start_timestamp).format("h:mm a"),
            end_time: moment(idleTime.end_timestamp).format("h:mm a"),
            duration: moment(idleTime.end_timestamp).diff(
              moment(idleTime.start_timestamp),
              "seconds"
            ),
            timesheet_id: timesheet.id,
            user: timesheet.user,
            date: timesheet.date,
          };
        })
      )
      .filter((idleTime) => idleTime);
    setIdleTimes(calculatedIdleTimes);

    const calculatedTotalIdleTime =
      calculatedIdleTimes?.length > 0
        ? calculatedIdleTimes.reduce(
            (acc, idleTime) => acc + idleTime.duration,
            0
          )
        : 0;
    setTotalIdleTime(calculatedTotalIdleTime);
  }, [timesheets, isOpen]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} style={{ maxWidth: "800px" }}>
      <ModalHeader>
        <CommonFlex
          justifyContent="center"
          align="center"
          gap="10px"
          style={{
            fontSize: "16px",
            fontWeight: "600",
          }}
        >
          Total Idle Time{" "}
          <CommonText danger={totalIdleTime ? true : false}>
            {" "}
            {totalIdleTime
              ? formatDurationToHoursMinutes(totalIdleTime, true)
              : ""}
          </CommonText>
        </CommonFlex>{" "}
        <ModalCrossIcon src={crossIcon} alt="" onClick={() => toggle()} />
      </ModalHeader>
      {idleTimes?.length > 0 ? (
        <ModalBody>
          <CommonFlex
            direction="column"
            width="100%"
            gap="5px"
            padding="20px 0"
            style={{
              overflowY: "auto",
              maxHeight: "50vh",
              paddingRight: "10px",
            }}
          >
            {idleTimes.map((idleTime) => {
              return (
                <IdleTimeModalRow
                  key={idleTime.id}
                  style={{
                    transition: "background-color 0.3s ease",
                    padding: "10px",
                    borderRadius: "10px",
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor = "#f0f0f0";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor = "transparent";
                  }}
                >
                  <ActivityTextSection justify="flex-start">
                    <CommonText name fontSize="12px" fontWeight="600">
                      {idleTime.project_name
                        ? idleTime.project_name
                        : "No project"}
                    </CommonText>

                    <CommonText fontSize="12px">
                      {idleTime.task_name ? idleTime.task_name : "No task"}
                    </CommonText>
                  </ActivityTextSection>
                  <ActivityTextSection>
                    <CommonText name fontSize="12px" fontWeight="600">
                      {idleTime.start_time}
                    </CommonText>
                    <CommonText fontSize="12px">Start</CommonText>
                  </ActivityTextSection>
                  <ActivityTextSection>
                    <CommonText name fontSize="12px" fontWeight="600">
                      {idleTime.end_time}
                    </CommonText>
                    <CommonText fontSize="12px">End</CommonText>
                  </ActivityTextSection>
                  <ActivityTextSection>
                    <CommonText name fontSize="12px" fontWeight="600">
                      {formatDurationToHoursMinutes(idleTime.duration, true)}
                    </CommonText>
                    <CommonText fontSize="12px">Idle Time</CommonText>
                  </ActivityTextSection>

                  <CommonFlex alignItems="center">
                    <DeleteButton
                      position="relative"
                      style={{
                        top: "0px",
                        right: "0px",
                      }}
                      onClick={() => {
                        handleDeleteIdleTime(
                          idleTime.start_timestamp,
                          idleTime.end_timestamp,
                          idleTime.duration,
                          idleTime.timesheet_id,
                          idleTime.user?.id,
                          idleTime
                        );
                      }}
                    >
                      <img
                        src={deleteWhite}
                        style={{
                          width: "16px",
                          height: "16px",
                        }}
                        alt="delete"
                      />
                    </DeleteButton>
                  </CommonFlex>
                </IdleTimeModalRow>
              );
            })}
          </CommonFlex>
        </ModalBody>
      ) : (
        <NoDataComponent
          animationSize="400px"
          padding="0 0 5vh 0"
          title="No idle time found."
          subTitleFontSize="14px"
        />
      )}
    </Modal>
  );
};

export default IdleTimeModal;
