import { useState, useEffect } from "react";

import { CardBody } from "reactstrap";

import { DashboardCard, ColorCircle, LargeCardNameEllipsis } from "../../styledComponents/dashboard";

import { CommonFlex, CommonGrid } from "../../styledComponents/common";
import { CardText } from "./cardStyles";

import TopUsersLoader from "../ContentLoader/TopUsersLoader";
import LinearActivityBar from "../ProgressBar/LinearActivityBar";

import { Header } from "./TopUsersTimeCard";

import { getFullName } from "../../utils/helper";

import noDataImg from "../../assets/img/common/nodata_top_employee.svg";

const colors = ["#73C0FF", "#CAB4FE", "#AFC0F9", "#61E5AC", "#748BA7"];

const TopUsersActivityCard = (props) => {
  const [activityData, setActivityData] = useState([]);

  useEffect(() => {
    setActivityData([]);
    return () => {
      setActivityData([]);
    };
  }, []);

  useEffect(() => {
    let data = [];
    if (props.usersData && props.usersData.length > 0) {
      props.usersData.map((item, index) => {
        data.push({
          color: colors[index],
          name: getFullName(item),
          activity: parseInt(item.average_activity * 100),
        });
      });
      setActivityData(data);
    } else if (props.usersData && props.usersData.length === 0) {
      setActivityData([]);
    }
  }, [props.usersData]);

  return (
    <DashboardCard>
      <CardBody>
        {!props.isLoading && <Header title="Top 5 Members Based On Activity" />}
        {props.isLoading && activityData.length === 0 && (
          <CommonFlex padding="0px 20px">
            <TopUsersLoader />
          </CommonFlex>
        )}
        {!props.isLoading && activityData.length === 0 && (
          <div className="d-flex flex-column align-items-center col-12">
            <img src={noDataImg} alt="no-data" className="img-fluid my-4" />
            <p style={{ fontSize: "14px", color: "rgba(61, 77, 105, 0.7)" }}>
              No data found.
            </p>
          </div>
        )}
        {activityData &&
          activityData.length > 0 &&
          activityData.map((item, index) => {
            return (
              <CommonFlex
                key={index}
                justifyContent="space-between"
                margin="15px 0px 15px 4px"
              >
                <CommonGrid gap="15px" columns="auto 1fr" alignItem="center">
                  <ColorCircle style={{ background: item.color }} />
                  <LargeCardNameEllipsis name>{item.name}</LargeCardNameEllipsis>
                </CommonGrid>
                <CommonGrid
                  columns="145px auto"
                  mobileColumns="100px auto"
                  gap="0"
                  alignItem="center"
                >
                  <LinearActivityBar
                    width="145px"
                    mobileWidth="100px"
                    height="10px"
                    mobileHeight="8px"
                    borderRadius="5px"
                    red="#FE5151"
                    yellow="#F2C741"
                    green="#20bead"
                    percent={item.activity}
                    marginTop="0"
                    backgroundColor="#B5B5B5"
                  />
                  <div>
                    <CardText margin="0 0 0 15px">
                      {`${item.activity}%`}
                    </CardText>
                  </div>
                </CommonGrid>
              </CommonFlex>
            );
          })}
      </CardBody>
    </DashboardCard>
  );
};

export default TopUsersActivityCard;
