import {
  GET_INVOICE_LIST,
  GET_INVOICE_LIST_SUCCESS,
  GET_INVOICE_LIST_FAILURE,
  CREATE_INVOICE_REQUEST,
  CREATE_INVOICE_REQUEST_SUCCESS,
  CREATE_INVOICE_REQUEST_FAILURE,
  GET_INVOICE_DETAILS,
  GET_INVOICE_DETAILS_SUCCESS,
  GET_INVOICE_DETAILS_FAILURE,
  UPDATE_INVOICE_REQUEST,
  UPDATE_INVOICE_REQUEST_SUCCESS,
  UPDATE_INVOICE_REQUEST_FAILURE,
  DELETE_INVOICE,
  DELETE_INVOICE_SUCCESS,
  DELETE_INVOICE_FAILURE,
  CREATE_INVOICE_ITEM,
  CREATE_INVOICE_ITEM_SUCCESS,
  CREATE_INVOICE_ITEM_FAILURE,
  UPDATE_INVOICE_ITEM,
  UPDATE_INVOICE_ITEM_SUCCESS,
  UPDATE_INVOICE_ITEM_FAILURE,
  DELETE_INVOICE_ITEM,
  DELETE_INVOICE_ITEM_SUCCESS,
  DELETE_INVOICE_ITEM_FAILURE,
  CREATE_INVOICE_TAX,
  CREATE_INVOICE_TAX_SUCCESS,
  CREATE_INVOICE_TAX_FAILURE,
  UPDATE_INVOICE_TAX,
  UPDATE_INVOICE_TAX_SUCCESS,
  UPDATE_INVOICE_TAX_FAILURE,
  GET_INVOICE_TAX,
  GET_INVOICE_TAX_SUCCESS,
  GET_INVOICE_TAX_FAILURE,
  UPDATE_INVOICE_DISCOUNT,
  UPDATE_INVOICE_DISCOUNT_SUCCESS,
  UPDATE_INVOICE_DISCOUNT_FAILURE,
  SEND_INVOICE_EMAIL,
  SEND_INVOICE_EMAIL_SUCCESS,
  SEND_INVOICE_EMAIL_FAILURE,
  GET_INVOICE_NUMBER,
  GET_INVOICE_NUMBER_SUCCESS,
  GET_INVOICE_NUMBER_FAILURE,
  RECORD_PAYMENT_INVOICE,
  RECORD_PAYMENT_INVOICE_SUCCESS,
  RECORD_PAYMENT_INVOICE_FAILURE,
  GET_RECORD_PAYMENT_INVOICE,
  GET_RECORD_PAYMENT_INVOICE_SUCCESS,
  GET_RECORD_PAYMENT_INVOICE_FAILURE,
  DELETE_RECORD_PAYMENT_INVOICE,
  DELETE_RECORD_PAYMENT_INVOICE_SUCCESS,
  DELETE_RECORD_PAYMENT_INVOICE_FAILURE,
  GET_INVOICE_HISTORY,
  GET_INVOICE_HISTORY_SUCCESS,
  GET_INVOICE_HISTORY_FAILURE,
  INVOICE_PUBLIC_VIEW,
  INVOICE_PUBLIC_VIEW_SUCCESS,
  INVOICE_PUBLIC_VIEW_FAILURE,
  INVOICE_UPDATE_SEND_LOG,
  INVOICE_UPDATE_SEND_LOG_SUCCESS,
  INVOICE_UPDATE_SEND_LOG_FAILURE,
  INVOICE_LINK_PROJECT,
  INVOICE_LINK_PROJECT_SUCCESS,
  INVOICE_LINK_PROJECT_FAILURE,
  CLEAR_INVOICE_LINKED_PROJECT,
  IS_MAIL_SEND_FROM_EDIT,
} from "../../modules/constants";
import { createNotification } from "../../modules/notificationManager";

// ------------------------------------
// Action handler methods
// ------------------------------------

export const handleInvoiceListRequest = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    isLoading: !payload.background,
    noData: false,
    // invoiceList: [],
  };
};
export const handleInvoiceListRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    isLoading: false,
    noData: true,
    invoiceList: data.data.results,
    totalInvoicesCount: data.data.count,
    invoicePageSize: data.data.page_size,
  };
};
export const handleInvoiceListRequestFailure = (state, action) => {
  createNotification("error", "Something went wrong", 2000);
  return {
    ...state,
    isLoading: false,
    noData: false,
    invoiceList: [],
    totalInvoicesCount: null,
    invoicePageSize: null,
  };
};

//CREATE INVOICE
export const createInvoiceRequestHandler = (state, action) => {
  return {
    ...state,
    createInvoiceLoading: true,
    invoiceNumberAlreadyExists: false,
  };
};
export const createInvoiceRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
    saveAndSend,
  } = action;
  if (data && data.status === 201) {
    createNotification("success", "Invoice created.", 2000);
    return {
      ...state,
      createInvoiceLoading: false,
      createdInvoiceInfo: { ...data.data, saveAndSend },
    };
  }
  return {
    ...state,
    createInvoiceLoading: false,
    createdInvoiceInfo: null,
  };
};
export const createInvoiceRequestFailureHandler = (state, action) => {
  if (action.payload.response === undefined) {
    createNotification(
      "error",
      "No internet connection found. Please connect to the internet.",
      3000
    );
    return {
      ...state,
      createInvoiceLoading: false,
      createdInvoiceInfo: null,
    };
  }
  const {
    payload: { response },
  } = action;

  if (response.status === 400) {
    if (response.data?.error) {
      createNotification("error", response.data.error[0], 3000);
      if (response.data.error[0] === "Invoice number already exists.") {
        return {
          ...state,
          invoiceNumberAlreadyExists: true,
          createInvoiceLoading: false,
          createdInvoiceInfo: null,
        };
      }
    } else if (response.data?.non_field_errors) {
      createNotification("error", response.data.non_field_errors[0], 2000);
      return {
        ...state,
        invoiceNumberAlreadyExists: true,
        createInvoiceLoading: false,
        createdInvoiceInfo: null,
      };
    } else {
      createNotification(
        "error",
        "Sorry! could not create invoice. Please try again.",
        2000
      );
    }
  }
  return {
    ...state,
    createInvoiceLoading: false,
    createdInvoiceInfo: null,
  };
};

//GET INVOICE DETAILS
export const handleInvoiceDetails = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    isLoading: !payload.background,
  };
};
export const handleInvoiceDetailsSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 200) {
    return {
      ...state,
      isLoading: false,
      invoiceDetails: data.data,
    };
  }
  return {
    ...state,
    isLoading: false,
    invoiceDetails: null,
  };
};
export const handleInvoiceDetailsFailure = (state, action) => {
  return {
    ...state,
    isLoading: false,
    invoiceDetails: null,
  };
};

//UPDATE INVOICE
export const updateInvoiceRequestHandler = (state, action) => {
  return {
    ...state,
    invoiceNumberAlreadyExists: false,
    updateInvoiceLoading: true,
    callback: false,
  };
};
export const updateInvoiceRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 200) {
    createNotification("success", "Invoice updated.", 2000);
    return {
      ...state,
      updateInvoiceLoading: false,
      updatedInvoiceDetails: data.data,
      callback: true,
    };
  }
  return {
    ...state,
    updateInvoiceLoading: false,
    callback: false,
  };
};
export const updateInvoiceRequestFailureHandler = (state, action) => {
  const {
    payload: { response },
  } = action;

  if (response.status === 404) {
    createNotification("error", "Client not found.", 2000);
    return {
      ...state,
      updateInvoiceLoading: false,
      callback: false,
    };
  }

  if (response.data === undefined) {
    createNotification("error", "Something went wrong!", 2000);
    return {
      ...state,
      updateInvoiceLoading: false,
      callback: false,
    };
  }

  if (response?.status === 400) {
    if (response.data?.error) {
      createNotification("error", response.data.error[0], 3000);
      if (response.data.error[0] === "Invoice number already exists.") {
        return {
          ...state,
          invoiceNumberAlreadyExists: true,
          updateInvoiceLoading: false,
          callback: false,
        };
      }
    } else {
      createNotification("error", "Failed!! please try again", 2000);
    }
  }
  return {
    ...state,
    updateInvoiceLoading: false,
    callback: false,
  };
};

//DELETE INVOICE
export const deleteInvoiceHandler = (state, action) => {
  return {
    ...state,
    deleteInvoiceLoading: true,
    deletedInvoiceCallback: false,
  };
};
export const deleteInvoiceSuccessHandler = (state, action) => {
  const {
    payload: { data, invoice_id, currentPageNumber },
  } = action;
  if (data && data.status === 204) {
    createNotification("success", "Invoice deleted.", 3000);

    let newList = JSON.parse(JSON.stringify(state.invoiceList));
    if (state.invoiceList && state.invoiceList.length > 0 && invoice_id) {
      newList = newList.filter((invoice) => invoice.id !== invoice_id);
    }
    const lastPage = Math.ceil(
      state.totalInvoicesCount / state.invoicePageSize
    );

    return {
      ...state,
      deleteInvoiceLoading: false,
      deletedInvoiceCallback: true,
      reloadInvoiceList:
        lastPage === currentPageNumber
          ? state.reloadInvoiceList
          : !state.reloadInvoiceList,
      totalInvoicesCount: state.totalInvoicesCount - 1,
      invoiceList: [...newList],
    };
  }
  return {
    ...state,
    deleteInvoiceLoading: false,
    deletedInvoiceCallback: false,
  };
};
export const deleteInvoiceFailureHandler = (state, action) => {
  createNotification(
    "error",
    "Could not delete invoice. Please try again!",
    3000
  );
  return {
    ...state,
    deleteInvoiceLoading: false,
    deletedInvoiceCallback: false,
  };
};

//CREATE INVOICE ITEM
export const createInvoiceItemHandler = (state, action) => {
  return {
    ...state,
    createItemLoading: true,
  };
};
export const createInvoiceItemSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 201) {
    createNotification("success", "Invoice item added.", 2000);
    let details = { ...state.invoiceDetails };
    if (details && details.items && data.data) {
      details.items.push(data.data);
    }
    return {
      ...state,
      createItemLoading: false,
      invoiceDetails: details,
    };
  }
  return {
    ...state,
    createItemLoading: false,
  };
};
export const createInvoiceItemFailureHandler = (state, action) => {
  if (action.payload.data === undefined) {
    createNotification("error", "Something went wrong!", 2000);
    return {
      ...state,
      createItemLoading: false,
    };
  }
  const {
    payload: { data },
  } = action;
  if (data.status === 400) {
    createNotification("error", "Failed!! please try again", 2000);
  }
  return {
    ...state,
    createItemLoading: false,
  };
};

//UPDATE INVOICE ITEM
export const updateInvoiceItemHandler = (state, action) => {
  return {
    ...state,
    updateItemLoading: true,
  };
};
export const updateInvoiceItemSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 200) {
    createNotification("success", "Invoice item updated.", 2000);
    return {
      ...state,
      updateItemLoading: false,
      updatedInvoiceItemInfo: data.data,
    };
  }
  return {
    ...state,
    updateItemLoading: false,
  };
};
export const updateInvoiceItemFailureHandler = (state, action) => {
  if (action.payload.data === undefined) {
    createNotification("error", "Something went wrong!", 2000);
    return {
      ...state,
      updateItemLoading: false,
    };
  }
  const {
    payload: { data },
  } = action;
  if (data.status === 400) {
    createNotification("error", "Failed!! please try again", 2000);
  }
  return {
    ...state,
    updateItemLoading: false,
  };
};

//DELETE INVOICE ITEM
export const deleteInvoiceItemHandler = (state, action) => {
  return {
    ...state,
    deleteItemLoading: true,
    deletedInvoiceItem: false,
  };
};
export const deleteInvoiceItemSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 204) {
    createNotification("success", "Invoice item deleted.", 2000);
    return {
      ...state,
      deleteItemLoading: false,
      deletedInvoiceItem: true,
    };
  }
  return {
    ...state,
    deleteItemLoading: false,
    deletedInvoiceItem: false,
  };
};
export const deleteInvoiceItemFailureHandler = (state, action) => {
  createNotification("error", "Failed to delete. Please try again!", 2000);
  return {
    ...state,
    deleteItemLoading: false,
    deletedInvoiceItem: false,
  };
};

//CREATE INVOICE TAX
export const createInvoiceTaxHandler = (state, action) => {
  return {
    ...state,
    createTaxLoading: true,
  };
};
export const createInvoiceTaxSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 201) {
    createNotification("success", "Invoice tax added successfully", 2000);
    return {
      ...state,
      createTaxLoading: false,
      createdInvoiceTaxId: data.data.id,
    };
  }
  return {
    ...state,
    createTaxLoading: false,
    createdInvoiceTaxId: null,
  };
};
export const createInvoiceTaxFailureHandler = (state, action) => {
  if (action.payload.data === undefined) {
    createNotification("error", "Something went wrong!", 2000);
    return {
      ...state,
      createTaxLoading: false,
      createdInvoiceTaxId: null,
    };
  }
  const {
    payload: { data },
  } = action;
  if (data.status === 400) {
    createNotification("error", "Failed!! please try again", 2000);
  }
  return {
    ...state,
    createTaxLoading: false,
    createdInvoiceTaxId: null,
  };
};

//UPDATE TAX
export const updateInvoiceTaxHandler = (state, action) => {
  return {
    ...state,
    updateTaxLoading: true,
    updatedInvoiceTax: null,
  };
};
export const updateInvoiceTaxSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 200) {
    createNotification("success", "Invoice tax updated successfully", 2000);
    return {
      ...state,
      updateTaxLoading: false,
      updatedInvoiceTax: data.data,
    };
  }
  return state;
};
export const updateInvoiceTaxFailureHandler = (state, action) => {
  if (action.payload.data === undefined) {
    createNotification("error", "Something went wrong!", 2000);
    return {
      ...state,
      updatedInvoiceTax: null,
      updateTaxLoading: false,
    };
  }
  const {
    payload: { data },
  } = action;
  if (data.status === 400) {
    createNotification("error", "Failed!! please try again", 2000);
  }
  return {
    ...state,
    updatedInvoiceTax: null,
    updateTaxLoading: false,
  };
};

//GET INVOICE TAX
export const getInvoiceTaxHandler = (state, action) => {
  return state;
};
export const getInvoiceTaxSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 200) {
    return {
      ...state,
      invoiceTaxInfo: data.data,
    };
  }
  return {
    ...state,
    invoiceTaxInfo: null,
  };
};
export const getInvoiceTaxFailureHandler = (state, action) => {
  return {
    ...state,
    invoiceTaxInfo: null,
  };
};

//UPDATE DISCOUNT
export const updateInvoiceDiscountHandler = (state, action) => {
  return {
    ...state,
    updateDiscountLoading: true,
  };
};
export const updateInvoiceDiscountSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 200) {
    createNotification(
      "success",
      "Invoice discount updated successfully",
      2000
    );
    return {
      ...state,
      updateDiscountLoading: false,
      updatedInvoiceDiscount: data.data,
    };
  }
  return {
    ...state,
    updateDiscountLoading: false,
  };
};
export const updateInvoiceDiscountFailureHandler = (state, action) => {
  if (action.payload.data === undefined) {
    createNotification("error", "Something went wrong!", 2000);
    return {
      ...state,
      updateDiscountLoading: false,
    };
  }
  const {
    payload: { data },
  } = action;
  if (data.status === 400) {
    createNotification("error", "Failed!! please try again", 2000);
  }
  return {
    ...state,
    updateDiscountLoading: false,
  };
};

//SEND EMAIL
export const sendInvoiceEmailHandler = (state, action) => {
  return {
    ...state,
    sendMailLoading: true,
  };
};
export const sendInvoiceSuccessHandler = (state, action) => {
  const {
    payload: { data, invoiceId },
  } = action;
  if (data && data.status === 200) {
    createNotification("success", "Invoice email sent.", 2000);

    const list = JSON.parse(JSON.stringify(state.invoiceList));

    list.some((invoice) => {
      if (invoice.id === invoiceId && invoice.status === "draft") {
        invoice.status = "sent";
        return true;
      }
      return false;
    });
    return {
      ...state,
      sendInvoiceEmailInfo: data.data,
      sendMailLoading: false,
      invoiceList: [...list],
    };
  }
  return {
    ...state,
    sendMailLoading: false,
    sendInvoiceEmailInfo: null,
  };
};

export const sendInvoiceFailureHandler = (state, action) => {
  const response = action.payload?.response;

  if (response.status === 400) {
    if (response?.data.non_field_errors) {
      createNotification("error", "Client is already deleted.", 2000);
    } else {
      createNotification("error", "Send email failed. Please try again!", 2000);
    }
    return {
      ...state,
      sendMailLoading: false,
      sendInvoiceEmailInfo: null,
    };
  }

  if (response.status === undefined) {
    createNotification(
      "error",
      "No internet connection found. Please try again!",
      2000
    );
    return {
      ...state,
      sendMailLoading: false,
      sendInvoiceEmailInfo: null,
    };
  }
  return {
    ...state,
    sendMailLoading: false,
    sendInvoiceEmailInfo: null,
  };
};

//GET INVOICE NUMBER
export const getInvoiceNumberHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const getInvoiceNumberSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 200) {
    return {
      ...state,
      isLoading: false,
      invoiceNumber: data.data,
    };
  }
  return {
    ...state,
    isLoading: false,
    invoiceNumber: null,
  };
};
export const getInvoiceNumberFailureHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
    invoiceNumber: null,
  };
};

//RECORD PAYMENT
export const recordPaymentInvoiceHandler = (state, index) => {
  return {
    ...state,
    recordPaymentLoading: true,
  };
};
export const recordPaymentInvoiceSuccessHandler = (state, action) => {
  const {
    payload: { data, invoiceId },
  } = action;
  if (data && data.status === 201) {
    createNotification("success", "Payment recorded.", 2000);
    return {
      ...state,
      recordPaymentInfo: data.data,
      recordPaymentLoading: false,
    };
  }
  return {
    ...state,
    recordPaymentInfo: null,
    recordPaymentLoading: false,
  };
};
export const recordPaymentInvoiceFailureHandler = (state, action) => {
  if (action.payload.data === undefined) {
    createNotification("error", "Something went wrong!", 2000);
    return {
      ...state,
      recordPaymentInfo: null,
      recordPaymentLoading: false,
    };
  }
  const {
    payload: { data },
  } = action;
  if (data.status === 400) {
    createNotification("error", "Failed!! please try again", 2000);
    return {
      ...state,
      recordPaymentInfo: null,
      recordPaymentLoading: false,
    };
  }
  return {
    ...state,
    recordPaymentInfo: null,
    recordPaymentLoading: false,
  };
};

//GET RECORD PAYMENT
export const getRecordPaymentInvoiceHandler = (state, action) => {
  return state;
};
export const getRecordPaymentInvoiceSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 200) {
    return {
      ...state,
      getRecordPaymentInfo: data.data,
    };
  }
  return {
    ...state,
    getRecordPaymentInfo: null,
  };
};
export const getRecordPaymentInvoiceFailureHandler = (state, action) => {
  return {
    ...state,
    getRecordPaymentInfo: null,
  };
};

//DELETE RECORD PAYMENT
export const deleteRecordPaymentInvoiceHandler = (state, action) => {
  return {
    ...state,
    isDeletedRecordPayment: false,
  };
};
export const deleteRecordPaymentInvoiceSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 204) {
    createNotification("success", "Payment record deleted.", 2000);
    return {
      ...state,
      isDeletedRecordPayment: true,
    };
  }
  return {
    ...state,
    isDeletedRecordPayment: false,
  };
};
export const deleteRecordPaymentInvoiceFailureHandler = (state, action) => {
  createNotification("error", "Something went wrong!", 2000);
  return {
    ...state,
    isDeletedRecordPayment: false,
  };
};

//GET INVOICE HISTORY
export const getInvoiceHistoryHandler = (state, action) => {
  return {
    ...state,
  };
};
export const getInvoiceHistorySuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 200) {
    return {
      ...state,
      invoiceHistory: data.data,
    };
  }
  return {
    ...state,
    invoiceHistory: null,
  };
};
export const getInvoiceHistoryFailureHandler = (state, action) => {
  createNotification("error", "Something went wrong! in invoice history", 2000);
  return {
    ...state,
    invoiceHistory: null,
  };
};

//GET INVOICE PUBLIC VIEW
export const invoicePublicViewHandler = (state, action) => {
  return state;
};
export const invoicePublicViewSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 200) {
    return {
      ...state,
      invoicePublicViewInfo: data.data,
    };
  }
  return {
    ...state,
    invoicePublicViewInfo: null,
  };
};
export const invoicePublicViewFailureHandler = (state, action) => {
  return {
    ...state,
    invoicePublicViewInfo: null,
  };
};

//SEND LOG IN UPDATE INVOICE
export const invoiceUpdateSendLogHandler = (state, index) => {
  return state;
};
export const invoiceUpdateSendLogSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 201) {
    return {
      ...state,
      updateInvoiceSendLog: data.data,
    };
  }
  return {
    ...state,
    updateInvoiceSendLog: null,
  };
};
export const invoiceUpdateSendLogFailureHandler = (state, action) => {
  if (action.payload.data === undefined) {
    return {
      ...state,
      updateInvoiceSendLog: null,
    };
  }
  const {
    payload: { data },
  } = action;
  if (data.status === 400) {
    // console.log("error", "Failed!! please try again");
  }
  return {
    ...state,
    updateInvoiceSendLog: null,
  };
};

//Invoice Project link for billing
export const invoiceLinkProjectHandler = (state, action) => {
  return {
    ...state,
    linkProjectLoading: true,
  };
};
export const invoiceLinkProjectSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 200) {
    return {
      ...state,
      linkProjectLoading: false,
      linkedProjectState: data.data,
    };
  }
  return {
    ...state,
    linkProjectLoading: false,
    linkedProjectState: null,
  };
};
export const invoiceLinkProjectFailureHandler = (state, action) => {
  const {
    payload: { response },
  } = action;
  if (response === undefined) {
    createNotification(
      "error",
      "No internet connection found. Please connect to the internet.",
      3000
    );
  }
  // if (response.status === 400) {
  //   if (response.data && response.data.linked_project_id) {
  //     createNotification("error", response.data.linked_project_id[0], 3000);
  //   } else {
  //     createNotification(
  //       "error",
  //       "Failed to link project!! please try again",
  //       2000
  //     );
  //   }
  // }
  return {
    ...state,
    linkProjectLoading: false,
    linkedProjectState: null,
  };
};

export const clearLinkedProjectHandler = (state, action) => {
  return {
    ...state,
    linkedProjectState: null,
  };
};

export const mailSendFromEditHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    isMailSend: data,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  [GET_INVOICE_LIST]: handleInvoiceListRequest,
  [GET_INVOICE_LIST_SUCCESS]: handleInvoiceListRequestSuccess,
  [GET_INVOICE_LIST_FAILURE]: handleInvoiceListRequestFailure,

  [CREATE_INVOICE_REQUEST]: createInvoiceRequestHandler,
  [CREATE_INVOICE_REQUEST_SUCCESS]: createInvoiceRequestSuccessHandler,
  [CREATE_INVOICE_REQUEST_FAILURE]: createInvoiceRequestFailureHandler,

  [GET_INVOICE_DETAILS]: handleInvoiceDetails,
  [GET_INVOICE_DETAILS_SUCCESS]: handleInvoiceDetailsSuccess,
  [GET_INVOICE_DETAILS_FAILURE]: handleInvoiceDetailsFailure,

  [UPDATE_INVOICE_REQUEST]: updateInvoiceRequestHandler,
  [UPDATE_INVOICE_REQUEST_SUCCESS]: updateInvoiceRequestSuccessHandler,
  [UPDATE_INVOICE_REQUEST_FAILURE]: updateInvoiceRequestFailureHandler,

  [DELETE_INVOICE]: deleteInvoiceHandler,
  [DELETE_INVOICE_SUCCESS]: deleteInvoiceSuccessHandler,
  [DELETE_INVOICE_FAILURE]: deleteInvoiceFailureHandler,

  [CREATE_INVOICE_ITEM]: createInvoiceItemHandler,
  [CREATE_INVOICE_ITEM_SUCCESS]: createInvoiceItemSuccessHandler,
  [CREATE_INVOICE_ITEM_FAILURE]: createInvoiceItemFailureHandler,

  [UPDATE_INVOICE_ITEM]: updateInvoiceItemHandler,
  [UPDATE_INVOICE_ITEM_SUCCESS]: updateInvoiceItemSuccessHandler,
  [UPDATE_INVOICE_ITEM_FAILURE]: updateInvoiceItemFailureHandler,

  [DELETE_INVOICE_ITEM]: deleteInvoiceItemHandler,
  [DELETE_INVOICE_ITEM_SUCCESS]: deleteInvoiceItemSuccessHandler,
  [DELETE_INVOICE_ITEM_FAILURE]: deleteInvoiceItemFailureHandler,

  [CREATE_INVOICE_TAX]: createInvoiceTaxHandler,
  [CREATE_INVOICE_TAX_SUCCESS]: createInvoiceTaxSuccessHandler,
  [CREATE_INVOICE_TAX_FAILURE]: createInvoiceTaxFailureHandler,

  [UPDATE_INVOICE_TAX]: updateInvoiceTaxHandler,
  [UPDATE_INVOICE_TAX_SUCCESS]: updateInvoiceTaxSuccessHandler,
  [UPDATE_INVOICE_TAX_FAILURE]: updateInvoiceTaxFailureHandler,

  [GET_INVOICE_TAX]: getInvoiceTaxHandler,
  [GET_INVOICE_TAX_SUCCESS]: getInvoiceTaxSuccessHandler,
  [GET_INVOICE_TAX_FAILURE]: getInvoiceTaxFailureHandler,

  [UPDATE_INVOICE_DISCOUNT]: updateInvoiceDiscountHandler,
  [UPDATE_INVOICE_DISCOUNT_SUCCESS]: updateInvoiceDiscountSuccessHandler,
  [UPDATE_INVOICE_DISCOUNT_FAILURE]: updateInvoiceDiscountFailureHandler,

  [SEND_INVOICE_EMAIL]: sendInvoiceEmailHandler,
  [SEND_INVOICE_EMAIL_SUCCESS]: sendInvoiceSuccessHandler,
  [SEND_INVOICE_EMAIL_FAILURE]: sendInvoiceFailureHandler,

  [GET_INVOICE_NUMBER]: getInvoiceNumberHandler,
  [GET_INVOICE_NUMBER_SUCCESS]: getInvoiceNumberSuccessHandler,
  [GET_INVOICE_NUMBER_FAILURE]: getInvoiceNumberFailureHandler,

  [RECORD_PAYMENT_INVOICE]: recordPaymentInvoiceHandler,
  [RECORD_PAYMENT_INVOICE_SUCCESS]: recordPaymentInvoiceSuccessHandler,
  [RECORD_PAYMENT_INVOICE_FAILURE]: recordPaymentInvoiceFailureHandler,

  [GET_RECORD_PAYMENT_INVOICE]: getRecordPaymentInvoiceHandler,
  [GET_RECORD_PAYMENT_INVOICE_SUCCESS]: getRecordPaymentInvoiceSuccessHandler,
  [GET_RECORD_PAYMENT_INVOICE_FAILURE]: getRecordPaymentInvoiceFailureHandler,

  [DELETE_RECORD_PAYMENT_INVOICE]: deleteRecordPaymentInvoiceHandler,
  [DELETE_RECORD_PAYMENT_INVOICE_SUCCESS]:
    deleteRecordPaymentInvoiceSuccessHandler,
  [DELETE_RECORD_PAYMENT_INVOICE_FAILURE]:
    deleteRecordPaymentInvoiceFailureHandler,

  [GET_INVOICE_HISTORY]: getInvoiceHistoryHandler,
  [GET_INVOICE_HISTORY_SUCCESS]: getInvoiceHistorySuccessHandler,
  [GET_INVOICE_HISTORY_FAILURE]: getInvoiceHistoryFailureHandler,

  [INVOICE_PUBLIC_VIEW]: invoicePublicViewHandler,
  [INVOICE_PUBLIC_VIEW_SUCCESS]: invoicePublicViewSuccessHandler,
  [INVOICE_PUBLIC_VIEW_FAILURE]: invoicePublicViewFailureHandler,

  [INVOICE_UPDATE_SEND_LOG]: invoiceUpdateSendLogHandler,
  [INVOICE_UPDATE_SEND_LOG_SUCCESS]: invoiceUpdateSendLogSuccessHandler,
  [INVOICE_UPDATE_SEND_LOG_FAILURE]: invoiceUpdateSendLogFailureHandler,

  [INVOICE_LINK_PROJECT]: invoiceLinkProjectHandler,
  [INVOICE_LINK_PROJECT_SUCCESS]: invoiceLinkProjectSuccessHandler,
  [INVOICE_LINK_PROJECT_FAILURE]: invoiceLinkProjectFailureHandler,

  [CLEAR_INVOICE_LINKED_PROJECT]: clearLinkedProjectHandler,
  [IS_MAIL_SEND_FROM_EDIT]: mailSendFromEditHandler,
};

// default initial state
const initialState = {
  isLoading: false,
  createInvoiceLoading: false,
  updateInvoiceLoading: false,
  deleteInvoiceLoading: false,
  createItemLoading: false,
  updateItemLoading: false,
  deleteItemLoading: false,
  createTaxLoading: false,
  updateTaxLoading: false,
  updateDiscountLoading: false,
  linkProjectLoading: false,
  sendMailLoading: false,
  recordPaymentLoading: false,
  noData: false,
  invoiceList: null,
  createdInvoiceInfo: null,
  callback: false,
  invoiceDetails: null,
  deletedInvoiceCallback: false,
  updatedInvoiceItemInfo: null,
  deletedInvoiceItem: false,
  createdInvoiceTaxId: null,
  updatedInvoiceTax: null,
  invoiceTaxInfo: null,
  updatedInvoiceDiscount: null,
  sendInvoiceEmailInfo: null,
  invoiceNumber: null,
  recordPaymentInfo: null,
  getRecordPaymentInfo: null,
  isDeletedRecordPayment: false,
  invoiceHistory: null,
  invoicePublicViewInfo: null,
  updateInvoiceSendLog: null,
  linkedProjectState: null,
  isMailSend: false,
  invoiceNumberAlreadyExists: false,

  // pagination
  totalInvoicesCount: null,
  invoicePageSize: null,

  reloadInvoiceList: false,
};

export default function invoiceReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
