import {
  GET_SCREENSHOT_SETTINGS,
  UPDATE_SCREENSHOT_SETTINGS,
  GET_MEMBERWISE_ACTIVITY_SETTINGS,
  UPDATE_MEMBERWISE_ACTIVITY_SETTINGS,
  GET_IDLE_TIME_SETTINGS,
  UPDATE_IDLE_TIME_SETTINGS,
  UPDATE_SCREEN_RECORD_SETTINGS,
} from "../../../modules/constants";

// ------------------------------------
// Actions
// ------------------------------------
export function getScreenShotSettings(value) {
  return {
    type: GET_SCREENSHOT_SETTINGS,
    payload: value,
  };
}

export function updateScreenShotSettings(value) {
  return {
    type: UPDATE_SCREENSHOT_SETTINGS,
    payload: value,
  };
}

export function getMembersList(value) {
  return {
    type: GET_MEMBERWISE_ACTIVITY_SETTINGS,
    payload: value,
  };
}
export function updateMembersList(value) {
  return {
    type: UPDATE_MEMBERWISE_ACTIVITY_SETTINGS,
    payload: value,
  };
}

export function getIdleTimeSettings(value) {
  return {
    type: GET_IDLE_TIME_SETTINGS,
    payload: value,
  };
}
export function updateIdleTimeSettings(value) {
  return {
    type: UPDATE_IDLE_TIME_SETTINGS,
    payload: value,
  };
}
