import { useState, useEffect } from "react";
import {
  CommonFlex,
  CommonGrid,
  CommonImage,
  CommonText,
  RoundMiniImage,
} from "../../styledComponents/common";
import {
  AssigneesManageRoundImage,
  Cross,
  CrossIcon,
} from "../../styledComponents/createProject";
import crossIcon from "../../assets/img/icons/cross_black.svg";
import FirstRoundLetterComp from "../../components/FirstRoundLetter/FirstRoundLetter";
import settingsIcon from "../../assets/img/Sidebar/settings.svg";
import {
  formatNotificationDate,
  manageNotificationActions,
} from "./notifications";
import {
  DeleteIconWrapper,
  NotificationContainer,
  NotificationHeader,
  NotificationItem,
  NotificationTitle,
  HighlightedText,
} from "./notificationStyles"; // Import the new styled components
import bellIcon from "../../assets/img/icons/bell_Icon.svg";

const NotificationsPanel = ({
  isVisible,
  setIsVisible,
  notificationSectionRef,
  history,
  notifications,
  selectedOrganization,
  markAsRead,
  deleteNotification,
}) => {
  const handleDeleteNotification = (id) => {
    if (id && selectedOrganization?.id)
      deleteNotification({ id, organizationId: selectedOrganization.id });
  };
  const handleMarkAsRead = (id) => {
    selectedOrganization?.id &&
      markAsRead({ id, organizationId: selectedOrganization.id });
  };
  const handleClickOutside = (event) => {
    if (
      notificationSectionRef.current &&
      !notificationSectionRef.current.contains(event.target)
    ) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    if (isVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isVisible]);

  return (
    <NotificationContainer isVisible={isVisible}>
      <NotificationHeader>
        <CommonText fontSize="15px" fontWeight="600">
          Notifications
        </CommonText>{" "}
        {/* <div style={{ cursor: "pointer" }}>
          <CommonImage src={settingsIcon} size="20px" />
        </div> */}
      </NotificationHeader>
      <hr />
      <div style={{ marginBottom: "16px" }}>
        {notifications.slice(0, 5).map((notification) => (
          <NotificationItem key={notification.id} className="notification-item">
            {notification.user.thumbnail ? (
              <AssigneesManageRoundImage imageSize={"40px"}>
                <RoundMiniImage
                  src={notification.user.thumbnail}
                  alt=""
                  imageSize={"40px"}
                />
              </AssigneesManageRoundImage>
            ) : (
              <FirstRoundLetterComp text={notification.user.fullname} />
            )}
            <div style={{ flex: 1, marginRight: "10px" }}>
              <div
                style={{ margin: 0, cursor: "pointer" }}
                onClick={() => {
                  !notification.read && handleMarkAsRead(notification.id);
                  manageNotificationActions({ history, notification });
                  setIsVisible(false);
                }}
              >
                <NotificationTitle>
                  {notification.title
                    .split(/~~([^~]+)~~/g)
                    .map((part, index) =>
                      index % 2 === 1 ? (
                        <HighlightedText key={index}>{part}</HighlightedText>
                      ) : (
                        part
                      )
                    )}
                </NotificationTitle>
              </div>
              <p style={{ fontSize: "0.8em", color: "#888", margin: "0" }}>
                {formatNotificationDate(notification.created_at)}
              </p>
            </div>
            {notification.read ? (
              <DeleteIconWrapper>
                <Cross
                  onClick={() => handleDeleteNotification(notification.id)}
                  style={{
                    margin: "0 auto",
                    cursor: "pointer",
                  }}
                  className="delete-icon"
                >
                  <CrossIcon src={crossIcon} alt="" />
                </Cross>
              </DeleteIconWrapper>
            ) : (
              <div
                style={{
                  backgroundColor: "#4D94FB",
                  width: "8px",
                  height: "8px",
                  borderRadius: "50%",
                  margin: "0 auto",
                  marginTop: "10px",
                }}
              />
            )}
          </NotificationItem>
        ))}
      </div>
      {notifications.length > 0 ? (
        <div
          style={{
            color: "#007bff",
            border: "none",
            padding: "8px 16px",
            cursor: "pointer",
            textAlign: "center",
            width: "max-content",
            margin: "0 auto",
          }}
          onClick={() => {
            setIsVisible(false);
            history.push("/user/notifications");
          }}
        >
          See All Notifications
        </div>
      ) : (
        <CommonFlex
          direction="column"
          gap="5px"
          style={{ textAlign: "center" }}
        >
          <CommonImage src={bellIcon} size="30px" />
          <CommonText fontWeight="550">No Notifications Found.</CommonText>
        </CommonFlex>
      )}
    </NotificationContainer>
  );
};

export default NotificationsPanel;
