import moment from "moment-timezone";
import {
  GET_TIMESHEET_APPROVAL_AWAITING_LIST,
  GET_TIMESHEET_APPROVAL_AWAITING_LIST_SUCCESS,
  GET_TIMESHEET_APPROVAL_AWAITING_LIST_FAILURE,
  GET_TIMESHEET_APPROVAL_HISTORY,
  GET_TIMESHEET_APPROVAL_HISTORY_SUCCESS,
  GET_TIMESHEET_APPROVAL_HISTORY_FAILURE,
  APPROVE_OR_REJECT_TIMESHEETS,
  APPROVE_OR_REJECT_TIMESHEETS_SUCCESS,
  APPROVE_OR_REJECT_TIMESHEETS_FAILURE,
  LOAD_MORE_TIMESHEET_APPROVAL_LIST,
  LOAD_MORE_TIMESHEET_APPROVAL_LIST_SUCCESS,
  LOAD_MORE_TIMESHEET_APPROVAL_LIST_FAILURE,
  CLEAR_LOAD_MORE_TIMESHEET_APPROVAL_LIST,
  LOGIN_PROFILE_CREATED_RESET,
  DELETE_AWAITING_TIMESHEETS_LIST,
  DELETE_AWAITING_TIMESHEETS_LIST_SUCCESS,
  DELETE_AWAITING_TIMESHEETS_LIST_FAILURE,
} from "../../modules/constants";

import { createNotification } from "../../modules/notificationManager";
import { processTimeEntry } from "../Timesheet/TimesheetUtils";

const noInternetMsg =
  "No Internet Connection! Please connect to the internet and try again";

const getApprovalData = (approvalData, timesheetList) => {
  timesheetList.forEach((timesheet) => {
    const timesheetData = {
      id: timesheet.id,
      project: timesheet.project,
      task: timesheet.task,
      startTimestamp: timesheet.start_timestamp,
      endTimestamp: timesheet.end_timestamp,
      adjustedStart: timesheet.start_timestamp,
      adjustedEnd: timesheet.end_timestamp,
      // display: true,
      completed: false,
      idleTimeDuration: 0,
    };
    const duration = timesheet.end_timestamp - timesheet.start_timestamp;

    if (duration > 0) {
      let acceptedDuration = 0;
      let rejectedDuration = 0;
      if (timesheet.approval_logs && timesheet.approval_logs.length > 0) {
        // const logs = timesheet.approval_logs.sort((a, b) => a.start - b.start);
        // timesheetData.adjustedEnd = logs[0].start;
        timesheet.approval_logs.forEach((log) => {
          if (log.is_approved) {
            acceptedDuration += log.end - log.start;
            if (timesheetData.adjustedStart < log.end) {
              timesheetData.adjustedStart = log.end;
            }
          } else {
            rejectedDuration += log.end - log.start;
            if (log.start < timesheetData.adjustedEnd) {
              timesheetData.adjustedEnd = log.start;
            }
          }
        });
      }
      timesheetData.acceptedDuration = acceptedDuration;
      timesheetData.rejectedDuration = rejectedDuration;
      // if (rejectedDuration > 0) {
      //   timesheetData.adjustedEnd =
      //     timesheet.end_timestamp - rejectedDuration - 1;
      // }

      if (acceptedDuration + rejectedDuration >= duration) {
        timesheetData.completed = true;
      }

      let idleDuration = 0;
      if (timesheet.idle_times && timesheet.idle_times.length > 0) {
        timesheet.idle_times.forEach((idleTime) => {
          if (idleTime.timesheet_id === timesheetData.id) {
            idleDuration += idleTime.end_timestamp - idleTime.start_timestamp;
          }
        });
        timesheetData.idleTimeDuration = idleDuration;
      }

      if (approvalData.has(timesheet.date)) {
        const userData = approvalData.get(timesheet.date);
        if (userData.has(timesheet.user_id)) {
          const currentData = userData.get(timesheet.user_id);
          // const currentDuration = currentData.duration;
          const currentTimesheets = JSON.parse(
            JSON.stringify(currentData.timesheets)
          );
          const hasTimesheet = currentTimesheets.findIndex(
            (item) => item.id === timesheetData.id
          );
          if (hasTimesheet === -1) {
            currentTimesheets.push(timesheetData);
          }
          currentData.timesheets = [...currentTimesheets].sort(
            (a, b) => a.startTimestamp - b.startTimestamp
          );
          currentData.duration += duration;
          currentData.acceptedDuration += acceptedDuration;
          currentData.rejectedDuration += rejectedDuration;
          currentData.idleTimeDuration += idleDuration;
          userData.set(timesheet.user_id, currentData);
        } else {
          userData.set(timesheet.user_id, {
            user: null,
            timesheets: [timesheetData],
            duration,
            acceptedDuration,
            rejectedDuration,
            idleTimeDuration: idleDuration,
          });
        }
      } else {
        const userData = new Map();
        userData.set(timesheet.user_id, {
          user: null,
          timesheets: [timesheetData],
          duration,
          acceptedDuration,
          rejectedDuration,
          idleTimeDuration: idleDuration,
        });
        approvalData.set(timesheet.date, userData);
      }
    }
  });

  return approvalData;
};

const updateAwaitingListTimesheetData = (oldData, timeSheetUpdates) => {
  const { old_timesheet, new_timesheet } = timeSheetUpdates;

  const updatedTimesheets = oldData.timesheets?.filter(
    (timesheet) => timesheet.id !== old_timesheet.id
  );

  let newAcceptedDuration = 0;
  let newRejectedDuration = 0;
  new_timesheet.adjustedStart = new_timesheet.start_timestamp;
  new_timesheet.adjustedEnd = new_timesheet.end_timestamp;
  if (new_timesheet.approval_logs && new_timesheet.approval_logs.length > 0) {
    new_timesheet.approval_logs.forEach((log) => {
      if (log.is_approved) {
        newAcceptedDuration += log.end - log.start;
        if (new_timesheet.adjustedStart < log.end) {
          new_timesheet.adjustedStart = log.end;
        }
      } else {
        newRejectedDuration += log.end - log.start;
        if (log.start < new_timesheet.adjustedEnd) {
          new_timesheet.adjustedEnd = log.start;
        }
      }
    });
  }

  updatedTimesheets.push({
    id: new_timesheet.id,
    project: new_timesheet.project,
    task: new_timesheet.task,
    startTimestamp: new_timesheet.start_timestamp,
    endTimestamp: new_timesheet.end_timestamp,
    adjustedStart: new_timesheet.adjustedStart,
    adjustedEnd: new_timesheet.adjustedEnd,
    completed:
      newAcceptedDuration + newRejectedDuration >=
      new_timesheet.end_timestamp - new_timesheet.start_timestamp,
    idleTimeDuration: new_timesheet.idle_times.reduce(
      (acc, idleTime) =>
        acc + (idleTime.end_timestamp - idleTime.start_timestamp),
      0
    ),
    acceptedDuration: newAcceptedDuration,
    rejectedDuration: newRejectedDuration,
  });

  if (old_timesheet.id !== new_timesheet.id) {
    let oldAcceptedDuration = 0;
    let oldRejectedDuration = 0;
    old_timesheet.adjustedStart = old_timesheet.start_timestamp;
    old_timesheet.adjustedEnd = old_timesheet.end_timestamp;
    if (old_timesheet.approval_logs && old_timesheet.approval_logs.length > 0) {
      old_timesheet.approval_logs.forEach((log) => {
        if (log.is_approved) {
          oldAcceptedDuration += log.end - log.start;
          if (old_timesheet.adjustedStart < log.end) {
            old_timesheet.adjustedStart = log.end;
          }
        } else {
          oldRejectedDuration += log.end - log.start;
          if (log.start < old_timesheet.adjustedEnd) {
            old_timesheet.adjustedEnd = log.start;
          }
        }
      });
    }

    updatedTimesheets.push({
      id: old_timesheet.id,
      project: old_timesheet.project,
      task: old_timesheet.task,
      startTimestamp: old_timesheet.start_timestamp,
      endTimestamp: old_timesheet.end_timestamp,
      adjustedStart: old_timesheet.start_timestamp,
      adjustedEnd: old_timesheet.end_timestamp,
      completed:
        oldAcceptedDuration + oldRejectedDuration >=
        old_timesheet.end_timestamp - old_timesheet.start_timestamp,
      idleTimeDuration: old_timesheet.idle_times.reduce(
        (acc, idleTime) =>
          acc + (idleTime.end_timestamp - idleTime.start_timestamp),
        0
      ),
      acceptedDuration: oldAcceptedDuration,
      rejectedDuration: oldRejectedDuration,
    });
  }

  oldData.timesheets = updatedTimesheets.sort(
    (a, b) => a.startTimestamp - b.startTimestamp
  );

  oldData.duration = updatedTimesheets.reduce(
    (acc, timesheet) =>
      acc + (timesheet.endTimestamp - timesheet.startTimestamp),
    0
  );
  oldData.idleTimeDuration = updatedTimesheets.reduce(
    (acc, timesheet) => acc + timesheet.idleTimeDuration,
    0
  );
  oldData.acceptedDuration = updatedTimesheets.reduce(
    (acc, timesheet) => acc + timesheet.acceptedDuration,
    0
  );
  oldData.rejectedDuration = updatedTimesheets.reduce(
    (acc, timesheet) => acc + timesheet.rejectedDuration,
    0
  );

  return oldData;
};

const getHistoryData = (historyData, timesheetList) => {
  timesheetList.forEach((timesheet) => {
    const duration = timesheet.end_timestamp - timesheet.start_timestamp;

    if (duration > 0) {
      let acceptedDuration = 0;
      let rejectedDuration = 0;

      // let display = false;
      if (timesheet.approval_logs && timesheet.approval_logs.length > 0) {
        // display = true;
        timesheet.approval_logs.forEach((log) => {
          if (log.is_approved) {
            acceptedDuration += log.end - log.start;
          } else {
            rejectedDuration += log.end - log.start;
          }
        });
      }

      if (historyData.has(timesheet.date)) {
        const userData = historyData.get(timesheet.date);
        if (userData.has(timesheet.user_id)) {
          const currentData = userData.get(timesheet.user_id);
          currentData.duration += duration;
          currentData.acceptedDuration += acceptedDuration;
          currentData.rejectedDuration += rejectedDuration;
          // currentData.display = display;
          userData.set(timesheet.user_id, currentData);
        } else {
          userData.set(timesheet.user_id, {
            user: null,
            duration,
            acceptedDuration,
            rejectedDuration,
            // display,
          });
        }
      } else {
        const userData = new Map();
        userData.set(timesheet.user_id, {
          user: null,
          duration,
          acceptedDuration,
          rejectedDuration,
          // display,
        });
        historyData.set(timesheet.date, userData);
      }
    }
  });

  return historyData;
};

const getMoreApprovalData = (approvalData, timesheetList, date) => {
  timesheetList.forEach((timesheet) => {
    const timesheetData = {
      id: timesheet.id,
      project: timesheet.project,
      task: timesheet.task,
      startTimestamp: timesheet.start_timestamp,
      endTimestamp: timesheet.end_timestamp,
      adjustedStart: timesheet.start_timestamp,
      adjustedEnd: timesheet.end_timestamp,
      completed: false,
      idleTimeDuration: 0,
    };
    const duration = timesheet.end_timestamp - timesheet.start_timestamp;

    if (duration > 0) {
      let acceptedDuration = 0;
      let rejectedDuration = 0;
      if (timesheet.approval_logs && timesheet.approval_logs.length > 0) {
        // const logs = timesheet.approval_logs.sort((a, b) => a.start - b.start);
        // timesheetData.adjustedEnd = logs[0].start;
        timesheet.approval_logs.forEach((log) => {
          if (log.is_approved) {
            acceptedDuration += log.end - log.start;
            if (timesheetData.adjustedStart < log.end) {
              timesheetData.adjustedStart = log.end;
            }
          } else {
            rejectedDuration += log.end - log.start;
            if (log.start < timesheetData.adjustedEnd) {
              timesheetData.adjustedEnd = log.start;
            }
          }
        });
      }
      timesheetData.acceptedDuration = acceptedDuration;
      timesheetData.rejectedDuration = rejectedDuration;

      if (acceptedDuration + rejectedDuration >= duration) {
        timesheetData.completed = true;
      }

      let idleDuration = 0;
      if (timesheet.idle_times && timesheet.idle_times.length > 0) {
        timesheet.idle_times.forEach((idleTime) => {
          if (idleTime.timesheet_id === timesheetData.id) {
            idleDuration += idleTime.end_timestamp - idleTime.start_timestamp;
          }
        });
        timesheetData.idleTimeDuration = idleDuration;
      }

      const userData = approvalData.get(date);
      if (userData.has(timesheet.user_id)) {
        const currentData = userData.get(timesheet.user_id);
        const currentTimesheets = JSON.parse(
          JSON.stringify(currentData.timesheets)
        );
        const hasTimesheet = currentTimesheets.findIndex(
          (item) => item.id === timesheetData.id
        );
        if (hasTimesheet === -1) {
          currentTimesheets.push(timesheetData);
        }
        currentData.timesheets = [...currentTimesheets].sort(
          (a, b) => a.startTimestamp - b.startTimestamp
        );
        currentData.duration += duration;
        currentData.acceptedDuration += acceptedDuration;
        currentData.rejectedDuration += rejectedDuration;
        currentData.idleTimeDuration += idleDuration;
        userData.set(timesheet.user_id, currentData);
      } else {
        userData.set(timesheet.user_id, {
          user: null,
          timesheets: [timesheetData],
          duration,
          acceptedDuration,
          rejectedDuration,
          idleTimeDuration: idleDuration,
        });
      }
    }
  });

  return approvalData;
};
const getMoreHistoryData = (historyData, timesheetList, date) => {
  timesheetList.forEach((timesheet) => {
    const duration = timesheet.end_timestamp - timesheet.start_timestamp;

    if (duration > 0) {
      let acceptedDuration = 0;
      let rejectedDuration = 0;

      // let display = false;
      if (timesheet.approval_logs && timesheet.approval_logs.length > 0) {
        // display = true;
        timesheet.approval_logs.forEach((log) => {
          if (log.is_approved) {
            acceptedDuration += log.end - log.start;
          } else {
            rejectedDuration += log.end - log.start;
          }
        });
      }

      const userData = historyData.get(date);
      if (userData.has(timesheet.user_id)) {
        const currentData = userData.get(timesheet.user_id);
        currentData.duration += duration;
        currentData.acceptedDuration += acceptedDuration;
        currentData.rejectedDuration += rejectedDuration;
        // currentData.display = display;
        userData.set(timesheet.user_id, currentData);
      } else {
        userData.set(timesheet.user_id, {
          user: null,
          duration,
          acceptedDuration,
          rejectedDuration,
          // display,
        });
      }
    }
  });
  return historyData;
};

const getApprovalDataAfterApproveOrReject = (approvalData, logData, date) => {
  const userData = approvalData.get(date);
  logData.forEach((log) => {
    const currentData = userData.get(log.user_id);
    const currentTimesheets = JSON.parse(
      JSON.stringify(currentData.timesheets)
    );
    const acceptedOrRejectedDuration = log.end - log.start;
    currentTimesheets.some((timesheet) => {
      if (timesheet.id === log.timesheet_id) {
        // if (timesheet.adjustedEnd >= log.start) {
        //   timesheet.adjustedEnd = log.start;
        // }
        if (log.is_approved) {
          timesheet.acceptedDuration += acceptedOrRejectedDuration;
          if (timesheet.adjustedStart < log.end) {
            timesheet.adjustedStart = log.end;
          }
        } else {
          timesheet.rejectedDuration += acceptedOrRejectedDuration;
          if (log.start < timesheet.adjustedEnd) {
            timesheet.adjustedEnd = log.start;
          }
        }
        if (
          timesheet.acceptedDuration + timesheet.rejectedDuration >=
          timesheet.endTimestamp - timesheet.startTimestamp
        ) {
          timesheet.completed = true;
        }
        return true;
      }
      return false;
    });
    currentData.timesheets = [...currentTimesheets].sort(
      (a, b) => a.startTimestamp - b.startTimestamp
    );
    if (log.is_approved) {
      currentData.acceptedDuration += acceptedOrRejectedDuration;
    } else {
      currentData.rejectedDuration += acceptedOrRejectedDuration;
    }
    userData.set(log.user_id, currentData);
  });

  return approvalData;
};

export const handleDeleteAwaitingTimesheetsRequest = (state, action) => {
  return {
    ...state,
    deleteTimesheetLoading: true,
  };
};

export const handleDeleteAwaitingTimesheetsRequestSuccess = (state, action) => {
  const {
    payload: { timesheet_id, data, timezone, date, memberId },
  } = action;
  const memberData = state.awaitingList.get(date)?.get(memberId);
  const approvalLogs =
    state.timeSheets?.find((timesheet) => timesheet.id === timesheet_id)
      ?.approval_logs || [];

  data.data.old_timesheet.approval_logs = approvalLogs;
  data.data.old_timesheet.date = date;
  data.data.new_timesheet.date = date;
  if (data.data.new_timesheet.id === data.data.old_timesheet.id) {
    data.data.new_timesheet.approval_logs = approvalLogs;
  }
  updateAwaitingListTimesheetData(memberData, data.data);

  const unprocessedTimeSheets = state.unprocessedTimeSheets.filter(
    (timesheet) => timesheet.id !== timesheet_id
  );
  unprocessedTimeSheets.push(data.data.new_timesheet);

  const processedTimesheetList = state.timeSheets.filter(
    (timesheet) => timesheet.id !== timesheet_id
  );

  const newProcessedTimesheet = processTimeEntry(
    data.data.new_timesheet,
    timezone
  );
  if (data.data.new_timesheet.idle_times?.length) {
    newProcessedTimesheet.idle_times = [...data.data.new_timesheet.idle_times]
      .map((idleTime) => processTimeEntry(idleTime, timezone))
      .sort((a, b) => a.start_timestamp - b.start_timestamp);
  }
  processedTimesheetList.push(newProcessedTimesheet);
  if (data.data.new_timesheet.id !== data.data.old_timesheet.id) {
    unprocessedTimeSheets.push(data.data.old_timesheet);
    const oldProcessedTimesheet = processTimeEntry(
      data.data.old_timesheet,
      timezone
    );
    if (data.data.old_timesheet.idle_times?.length) {
      oldProcessedTimesheet.idle_times = [...data.data.old_timesheet.idle_times]
        .map((idleTime) => processTimeEntry(idleTime, timezone))
        .sort((a, b) => a.start_timestamp - b.start_timestamp);
    }
    processedTimesheetList.push(oldProcessedTimesheet);
  }
  return {
    ...state,
    deleteTimesheetLoading: false,
    timeSheets: [...processedTimesheetList],
    unprocessedTimeSheets: [...unprocessedTimeSheets],
  };
};

export const handleDeleteAwaitingTimesheetsRequestFailure = (state, action) => {
  createNotification(
    "error",
    "Sorry, An error occured! Please try again.",
    3000
  );
  return {
    ...state,
    deleteTimesheetLoading: false,
  };
};

// ------------------------------------
// Action handler methods
// ------------------------------------
export const handleTimesheetApprovalAwaitingListRequest = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    isLoading: true,
    allLoadedDates: {},
    timeSheets: [],
    unprocessedTimeSheets: [],
  };
};
export const handleTimesheetApprovalAwaitingListRequestSuccess = (
  state,
  action
) => {
  const {
    payload: { data, timezone },
  } = action;
  if (data.status === 200) {
    const timesheetList = JSON.parse(JSON.stringify(data.data));
    const approvalDataMap = new Map();
    const approvalData = getApprovalData(approvalDataMap, timesheetList);
    const processedTimesheetList = [...timesheetList].map((timesheet) => {
      const processedTimesheet = processTimeEntry(timesheet, timezone);
      if (timesheet.idle_times?.length) {
        processedTimesheet.idle_times = [...timesheet.idle_times]
          .map((idleTime) => processTimeEntry(idleTime, timezone))
          .sort((a, b) => a.start_timestamp - b.start_timestamp);
      }
      return processedTimesheet;
    });
    return {
      ...state,
      isLoading: false,
      awaitingList: approvalData,
      timeSheets: processedTimesheetList,
      unprocessedTimeSheets: timesheetList,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};
export const handleTimesheetApprovalAwaitingListRequestFailure = (
  state,
  action
) => {
  return {
    ...state,
    isLoading: false,
    awaitingList: null,
  };
};

export const handleTimesheetApprovalHistoryRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    allLoadedDates: {},
  };
};
export const handleTimesheetApprovalHistoryRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;

  if (data.status === 200) {
    const timesheetList = JSON.parse(JSON.stringify(data.data));
    const historyDataMap = new Map();
    const historyData = getHistoryData(historyDataMap, timesheetList);

    return {
      ...state,
      isLoading: false,
      historyList: historyData,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};
export const handleTimesheetApprovalHistoryRequestFailure = (state, action) => {
  return {
    ...state,
    isLoading: false,
    historyList: null,
  };
};

export const handleApproveOrRejectTimesheetsRequest = (state, action) => {
  const { payload } = action;
  if (payload.action === "approve") {
    return {
      ...state,
      approveIsLoading: true,
    };
  }
  return {
    ...state,
    rejectIsLoading: true,
  };
};
export const handleApproveOrRejectTimesheetsRequestSuccess = (
  state,
  action
) => {
  const {
    payload: { data, approval_data, date },
  } = action;

  if (
    data &&
    data.status === 200 &&
    approval_data &&
    approval_data.length > 0 &&
    date &&
    state.awaitingList
  ) {
    const approvalDataMap = new Map(state.awaitingList);
    const approvalData = getApprovalDataAfterApproveOrReject(
      approvalDataMap,
      approval_data,
      date
    );
    let timeSheets = state.timeSheets;
    if (timeSheets) {
      const approvalTimesheetIds = approval_data.map((log) => log.timesheet_id);
      timeSheets = state.timeSheets.map((timesheet) => {
        if (approvalTimesheetIds.includes(timesheet.id)) {
          approval_data.forEach((log) => {
            if (timesheet.id === log.timesheet_id) {
              timesheet?.approval_logs?.push({
                start: log.start,
                end: log.end,
                is_approved: log.is_approved,
                user_id: log.user_id,
              });
            }
          });
        }
        return timesheet;
      });
    }

    return {
      ...state,
      approveIsLoading: false,
      rejectIsLoading: false,
      awaitingList: approvalData,
      timeSheets: timeSheets,
    };
  }

  return {
    ...state,
    approveIsLoading: false,
    rejectIsLoading: false,
  };
};
export const handleApproveOrRejectTimesheetsRequestFailure = (
  state,
  action
) => {
  createNotification(
    "error",
    "Sorry, An error occured! Please try again.",
    3000
  );
  return {
    ...state,
    approveIsLoading: false,
    rejectIsLoading: false,
  };
};

export const handleLoadMoreTimesheetApprovalListRequest = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    seeMoreIsLoading: payload.start_date,
  };
};
export const handleLoadMoreTimesheetApprovalListRequestSuccess = (
  state,
  action
) => {
  const {
    payload: { data, date, type },
  } = action;
  if (data.status === 200 && date) {
    if (data.data && data.data.length > 0) {
      const timesheetList = JSON.parse(JSON.stringify(data.data));

      if (type === "awaiting") {
        const approvalDataMap = new Map(state.awaitingList);
        const approvalData = getMoreApprovalData(
          approvalDataMap,
          timesheetList,
          date
        );
        return {
          ...state,
          seeMoreIsLoading: null,
          awaitingList: approvalData,
        };
      } else if (type === "history") {
        const historyDataMap = new Map(state.historyList);
        const historyData = getMoreHistoryData(
          historyDataMap,
          timesheetList,
          date
        );
        return {
          ...state,
          seeMoreIsLoading: null,
          historyList: historyData,
        };
      }
    } else if (data.data && data.data.length === 0) {
      const currentLoadedDates = JSON.parse(
        JSON.stringify(state.allLoadedDates)
      );

      if (!currentLoadedDates.hasOwnProperty(date)) {
        currentLoadedDates[date] = true;
      }
      return {
        ...state,
        seeMoreIsLoading: null,
        allLoadedDates: { ...currentLoadedDates },
      };
    }
    return {
      ...state,
      seeMoreIsLoading: null,
    };
  }
  return {
    ...state,
    seeMoreIsLoading: null,
  };
};
export const handleLoadMoreTimesheetApprovalListRequestFailure = (
  state,
  action
) => {
  return {
    ...state,
    seeMoreIsLoading: null,
  };
};

export const clearLoadMoreTimesheetApprovalList = (state, action) => {
  return {
    ...state,
    seeMoreIsLoading: null,
    allLoadedDates: {},
  };
};

export const handleLogoutRequest = (state, action) => {
  return {
    ...state,
    ...initialState,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  [GET_TIMESHEET_APPROVAL_AWAITING_LIST]:
    handleTimesheetApprovalAwaitingListRequest,
  [GET_TIMESHEET_APPROVAL_AWAITING_LIST_SUCCESS]:
    handleTimesheetApprovalAwaitingListRequestSuccess,
  [GET_TIMESHEET_APPROVAL_AWAITING_LIST_FAILURE]:
    handleTimesheetApprovalAwaitingListRequestFailure,

  [GET_TIMESHEET_APPROVAL_HISTORY]: handleTimesheetApprovalHistoryRequest,
  [GET_TIMESHEET_APPROVAL_HISTORY_SUCCESS]:
    handleTimesheetApprovalHistoryRequestSuccess,
  [GET_TIMESHEET_APPROVAL_HISTORY_FAILURE]:
    handleTimesheetApprovalHistoryRequestFailure,

  [APPROVE_OR_REJECT_TIMESHEETS]: handleApproveOrRejectTimesheetsRequest,
  [APPROVE_OR_REJECT_TIMESHEETS_SUCCESS]:
    handleApproveOrRejectTimesheetsRequestSuccess,
  [APPROVE_OR_REJECT_TIMESHEETS_FAILURE]:
    handleApproveOrRejectTimesheetsRequestFailure,

  [LOAD_MORE_TIMESHEET_APPROVAL_LIST]:
    handleLoadMoreTimesheetApprovalListRequest,
  [LOAD_MORE_TIMESHEET_APPROVAL_LIST_SUCCESS]:
    handleLoadMoreTimesheetApprovalListRequestSuccess,
  [LOAD_MORE_TIMESHEET_APPROVAL_LIST_FAILURE]:
    handleLoadMoreTimesheetApprovalListRequestFailure,

  [CLEAR_LOAD_MORE_TIMESHEET_APPROVAL_LIST]: clearLoadMoreTimesheetApprovalList,

  [LOGIN_PROFILE_CREATED_RESET]: handleLogoutRequest,

  [DELETE_AWAITING_TIMESHEETS_LIST]: handleDeleteAwaitingTimesheetsRequest,
  [DELETE_AWAITING_TIMESHEETS_LIST_SUCCESS]:
    handleDeleteAwaitingTimesheetsRequestSuccess,
  [DELETE_AWAITING_TIMESHEETS_LIST_FAILURE]:
    handleDeleteAwaitingTimesheetsRequestFailure,
};

// default initial state
const initialState = {
  isLoading: false,
  seeMoreIsLoading: null,
  approveIsLoading: false,
  rejectIsLoading: false,

  awaitingList: null,
  historyList: null,
  timeSheets: [],

  // pagination
  paginationSize: 10,
  allLoadedDates: {},
};

export default function timesheetApprovalReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
