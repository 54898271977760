import { useState, useEffect } from "react";
import moment from "moment";
import { getHours } from "../../utils/helper";

import {
  DashboardCardTitle,
  ColorCircle,
} from "../../styledComponents/dashboard";
import {
  NoDataContainer,
  NoDataImg,
  NoDataText,
  CommonCardContainer,
  WeeklyActivityTitleContainer,
  CardLoaderContainer,
  OngoingTasksContainer,
  SingleOngoingTaskDiv,
  OngoingTaskName,
  OngoingTaskProjectAndTimeContainer,
  OngoingTaskData,
} from "./cardStyles";

import { toHHMMSS } from "../../utils/helper";

import RecentTasksLoader from "../ContentLoader/RecentTasksLoader";

import noDataImg from "../../assets/img/common/nodata_tasks.svg";

const colors = ["#E7F0FE", "#EAF9F7", "#FBF3DA", "#ECECFC"];

const RecentTasksCard = (props) => {
  const [tasksData, setTasksData] = useState([]);
  useEffect(() => {
    if (props.ongoingTasks && props.ongoingTasks.length > 0) {
      let data = [];
      props.ongoingTasks.slice(0, 4).map((item, index) => {
        data.push({
          taskName: item.task_name,
          projectName: item.project_name,
          spentTime:
            item.duration && item.duration > 0
              ? toHHMMSS(item.duration)
              : "0:00:00",
        });
      });
      setTasksData(data);
    } else if (
      (props.ongoingTasks && props.ongoingTasks.length === 0) ||
      !props.ongoingTasks
    ) {
      setTasksData([]);
    }
  }, [props.ongoingTasks]);
  return (
    <CommonCardContainer>
      <WeeklyActivityTitleContainer>
        <DashboardCardTitle>Ongoing Tasks</DashboardCardTitle>
      </WeeklyActivityTitleContainer>
      {props.isLoading && (
        <CardLoaderContainer padding="20px">
          <RecentTasksLoader />
        </CardLoaderContainer>
      )}
      {!props.isLoading && tasksData && tasksData.length === 0 && (
        <NoDataContainer>
          <NoDataImg src={noDataImg} alt="no-data" />
          <NoDataText>No data found.</NoDataText>
        </NoDataContainer>
      )}
      {!props.isLoading && tasksData && tasksData.length > 0 && (
        <OngoingTasksContainer length={tasksData.length}>
          {tasksData.map((item, index) => {
            return (
              <SingleOngoingTaskDiv key={index} background={colors[index]}>
                <OngoingTaskName>{item.taskName}</OngoingTaskName>
                <OngoingTaskProjectAndTimeContainer>
                  <OngoingTaskData>{item.projectName}</OngoingTaskData>
                  <OngoingTaskData>{getHours(item.spentTime)}</OngoingTaskData>
                </OngoingTaskProjectAndTimeContainer>
              </SingleOngoingTaskDiv>
            );
          })}
        </OngoingTasksContainer>
      )}
    </CommonCardContainer>
  );
};

export default RecentTasksCard;
