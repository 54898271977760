//Effects
import { all, call, put, takeLatest } from "redux-saga/effects";

//Service
import { defaultApi } from "../../utils/axiosApi";

//Constants
import {
  GET_CLIENTS_LIST,
  GET_CLIENTS_LIST_SUCCESS,
  GET_CLIENTS_LIST_FAILURE,
  GET_CLIENT_DETAILS,
  GET_CLIENT_DETAILS_SUCCESS,
  GET_CLIENT_DETAILS_FAILURE,
  CREATE_CLIENT,
  CREATE_CLIENT_SUCCESS,
  CREATE_CLIENT_FAILURE,
  UPDATE_CLIENT,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAILURE,
  DELETE_CLIENT,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAILURE,
} from "../../modules/constants";

export function* getClientList({ payload }) {
  try {
    const { organization_id } = payload;
    let url = `${organization_id}/clients/`;
    const response = yield defaultApi(url, "GET");
    yield put({
      type: GET_CLIENTS_LIST_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_CLIENTS_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* getClientDetails({ payload }) {
  try {
    const { organization_id, client_id } = payload;
    let url = `${organization_id}/clients/${client_id}/`;
    let method = "GET";
    if (!client_id) {
      throw new Error ("client_id is null")
    }
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_CLIENT_DETAILS_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_CLIENT_DETAILS_FAILURE,
      payload: err,
      client_id: payload.client_id
    });
  }
}

export function* handleCreateClient({ payload }) {
  try {
    const { organization_id, ...details } = payload;
    let url = `${organization_id}/clients/`;
    let method = "POST";
    const response = yield defaultApi(url, method, details);
    yield put({
      type: CREATE_CLIENT_SUCCESS,
      payload: { data: response, notify: "POST" },
    });
  } catch (err) {
    yield put({
      type: CREATE_CLIENT_FAILURE,
      payload: err,
    });
  }
}

export function* handleUpdateClient({ payload }) {
  try {
    const { organization_id, client_id, getList, action, ...details } = payload;
    let url = `${organization_id}/clients/${client_id}/`;
    let method = "PATCH";
    const response = yield defaultApi(url, method, details);
    if (getList) {
      yield call(getClientList, { payload });
    } else {
      yield call(getClientDetails, { payload });
    }
    yield put({
      type: UPDATE_CLIENT_SUCCESS,
      payload: { data: response, actionType: action, notify: method },
    });
  } catch (err) {
    yield put({
      type: UPDATE_CLIENT_FAILURE,
      payload: err,
    });
  }
}

export function* handleDeleteClient({ payload }) {
  try {
    const { organization_id, client_id } = payload;
    let url = `${organization_id}/clients/${client_id}/`;
    let method = "DELETE";
    const response = yield defaultApi(url, method);
    yield put({
      type: DELETE_CLIENT_SUCCESS,
      payload: { data: response, client_id, notify: method },
    });
  } catch (err) {
    yield put({
      type: DELETE_CLIENT_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(GET_CLIENTS_LIST, getClientList),
    takeLatest(GET_CLIENT_DETAILS, getClientDetails),
    takeLatest(CREATE_CLIENT, handleCreateClient),
    takeLatest(UPDATE_CLIENT, handleUpdateClient),
    takeLatest(DELETE_CLIENT, handleDeleteClient),
  ]);
}
