import {
  GET_CLIENTS_LIST,
  GET_CLIENTS_LIST_SUCCESS,
  GET_CLIENTS_LIST_FAILURE,
  GET_CLIENT_DETAILS,
  GET_CLIENT_DETAILS_SUCCESS,
  GET_CLIENT_DETAILS_FAILURE,
  CREATE_CLIENT,
  CREATE_CLIENT_SUCCESS,
  CREATE_CLIENT_FAILURE,
  UPDATE_CLIENT,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAILURE,
  DELETE_CLIENT,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAILURE,
  LOGIN_PROFILE_CREATED_RESET,
} from "../../modules/constants";
import { createNotification } from "../../modules/notificationManager";

export const clientListRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const clientListRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      isLoading: false,
      clientList: data.data,
    };
  }
  return {
    ...state,
    isLoading: false,
    clientList: [],
  };
};
export const clientListRequestFailureHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
    clientList: [],
  };
};

//client details
export const clientDetailsRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const clientDetailsRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      isLoading: false,
      clientDetails: data.data,
    };
  }
  return {
    ...state,
    isLoading: false,
    clientDetails: null,
  };
};
export const clientDetailsRequestFailureHandler = (state, action) => {

  const { client_id } = action;
  if (client_id === null) {
    createNotification("error", "Client not found.", 2000);
  }
  else if (action.payload.response.status == 404) {
    createNotification("error", "Client not found.", 2000);
  }
  return {
    ...state,
    isLoading: false,
    clientDetails: null,
  };
};

//Create client
export const createClientHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const createClientSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (state.clientList) {
    const list = [...state.clientList, data.data];
    createNotification("success", "Client added.", 2000);
    return {
      ...state,
      isLoading: false,
      clientList: list,
      createdClientId: data.data.id,
    };
  }
  return {
    ...state,
    isLoading: false,
    createdClientId: data.data.id,
  };
};
export const createClientFailureHandler = (state, action) => {
  createNotification(
    "error",
    "Could not create client. Please try again!",
    3000
  );
  return {
    ...state,
    isLoading: false,
    createdClientId: null,
  };
};

// Update client
export const updateClientHandler = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    clientUpdateLoading: true,
    updatingClientId: payload.client_id,
  };
};
export const updateClientSuccessHandler = (state, action) => {
  const {
    payload: {
      actionType,
      data: {
        data: { is_active },
      },
    },
  } = action;

  if (is_active && actionType == "archive")
    createNotification("success", "Client un-archived.", 2000);
  if (!is_active && actionType == "archive")
    createNotification("success", "Client archived.", 2000);
  if (actionType == "update")
    createNotification("success", "Client updated.", 2000);
  return {
    ...state,
    clientUpdateLoading: false,
    updatingClientId: null,
  };
};
export const updateClientFailureHandler = (state, action) => {
  createNotification("error", "Could not update. Please try again!", 3000);
  return {
    ...state,
    clientUpdateLoading: false,
    updatingClientId: null,
  };
};

// Delete client
export const deleteClientHandler = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    clientDeleteLoading: true,
    deletingClientId: payload.client_id,
  };
};
export const deleteClientSuccessHandler = (state, action) => {
  const {
    payload: { client_id },
  } = action;
  if (state.clientList && state.clientList.length) {
    createNotification("success", "Client deleted.", 2000);
    return {
      ...state,
      clientDeleteLoading: false,
      deletingClientId: null,
      clientList: state.clientList.filter((client) => client.id !== client_id),
    };
  }
  return {
    ...state,
    clientDeleteLoading: false,
    deletingClientId: null,
  };
};
export const deleteClientFailureHandler = (state, action) => {
  createNotification("error", "Could not delete. Please try again!", 3000);
  return {
    ...state,
    clientDeleteLoading: false,
    deletingClientId: null,
  };
};

export const handleLogoutRequest = (state, action) => {
  return {
    ...state,
    ...initialState,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  [GET_CLIENTS_LIST]: clientListRequestHandler,
  [GET_CLIENTS_LIST_SUCCESS]: clientListRequestSuccessHandler,
  [GET_CLIENTS_LIST_FAILURE]: clientListRequestFailureHandler,

  [GET_CLIENT_DETAILS]: clientDetailsRequestHandler,
  [GET_CLIENT_DETAILS_SUCCESS]: clientDetailsRequestSuccessHandler,
  [GET_CLIENT_DETAILS_FAILURE]: clientDetailsRequestFailureHandler,

  [CREATE_CLIENT]: createClientHandler,
  [CREATE_CLIENT_SUCCESS]: createClientSuccessHandler,
  [CREATE_CLIENT_FAILURE]: createClientFailureHandler,

  [UPDATE_CLIENT]: updateClientHandler,
  [UPDATE_CLIENT_SUCCESS]: updateClientSuccessHandler,
  [UPDATE_CLIENT_FAILURE]: updateClientFailureHandler,

  [DELETE_CLIENT]: deleteClientHandler,
  [DELETE_CLIENT_SUCCESS]: deleteClientSuccessHandler,
  [DELETE_CLIENT_FAILURE]: deleteClientFailureHandler,

  [LOGIN_PROFILE_CREATED_RESET]: handleLogoutRequest,
};

// default initial state
const initialState = {
  isLoading: false,
  clientUpdateLoading: false,
  updatingClientId: null,
  clientDeleteLoading: false,
  deletingClientId: null,
  redirect: false,
  clientList: [],
  clientDetails: null,
  createdClientId: null,
};

export default function clientReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
