import styled from "styled-components";

export const DateCellMaskContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: transparent;
  z-index: 100;
  display: flex;
  justify-content: center;
  overflow: visible;
  height: fit-content;
`;

export const DateCellMaskInner = styled.div`
  position: relative;
  z-index: 100;
  font-weight: bold;
  cursor: pointer;
  padding: 2px;
  border-radius: 50%;
  background-color: transparent;
  color: transparent;
  height: 25px;
  width: 25px;
  margin-top: 2px;
  &:hover .hover-element {
    visibility: visible;
  }
`;
