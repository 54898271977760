import { useState, useEffect } from "react";
import moment from "moment";
import TagManager from "react-gtm-module";
import {
  DashboardPageHeader,
  TitleAndModeSelectorDiv,
  DateRangeContainer,
  DateRangeTooltip,
  DateRangeDiv,
  DateRangeText,
  DashboardPageContent,
  DashboardCardContainer,
  SmallCardContainer,
  SmallCardLabel,
} from "./dashboardStyles";

import {
  BillingTooltip,
  BillingTooltipText,
} from "../../styledComponents/createProject";
import { PageTitle, ColoredText } from "../../styledComponents/common";

import TopUsersActivityCard from "../../components/DashboardCards/TopUsersActivityCard";
import TopUsersTimeCard from "../../components/DashboardCards/TopUsersTimeCard";
import TotalHourCard from "../../components/DashboardCards/TotalHourCardNew";
import MostProjectActivityCard from "../../components/DashboardCards/MostProjectActivityCard";
import MostProjectHourCard from "../../components/DashboardCards/MostProjectHourCardNew";
import TodaysActivityCard from "../../components/DashboardCards/TodaysActivityCard";
import WeeklyActivityCard from "../../components/DashboardCards/WeeklyActivityCard";
import TopProjectsCard from "../../components/DashboardCards/TopProjectsCard";
import RecentTasksCard from "../../components/DashboardCards/RecentTasksCard";
import TimesheetCard from "../../components/DashboardCards/TimesheetCard";

import { GreetingsModalOwner } from "./GreetingsModal/GreetingsModal";
import DashboardModeSelector from "./DashboardModeSelector";

const chartLabels = [null, null, null, null, null, null, null];

const getTime = (time) => {
  let timeInSeconds = parseInt(time);
  let minutes = Math.floor(timeInSeconds / 60) % 60;
  let hours = Math.floor(timeInSeconds / 3600);

  return `${hours}h ${minutes}m`;
};

const DateRangeSection = ({ startDate, endDate, year }) => {
  return (
    <DateRangeContainer>
      <ColoredText type="blue" fontSize="12px">
        {startDate} - {endDate}, {year}
      </ColoredText>
      <DateRangeTooltip>
        ?
        <BillingTooltip top="32px" left="-230px">
          <BillingTooltipText>
            This is the Organization’s weekly data that starts every Monday and
            ends next Sunday.
          </BillingTooltipText>
        </BillingTooltip>
      </DateRangeTooltip>
    </DateRangeContainer>
  );
};
// Please note that today's data is not included here - this text has been removed

const Dashboard = (props) => {
  const [currentMode, setCurrentMode] = useState("organization");
  const [role, setRole] = useState("member");
  const [orgPlan, setOrgPlan] = useState("monitored");
  const [weekMostHourOrg, setWeekMostHourOrg] = useState(null);
  const [weekMostHourUser, setWeekMostHourUser] = useState(null);
  const [projectMostHourOrg, setProjectMostHourOrg] = useState(null);
  const [projectMostHourUser, setProjectMostHourUser] = useState(null);
  const [highestProjectActivityOrg, setHighestProjectActivityOrg] = useState(
    null
  );
  const [highestProjectActivityUser, setHighestProjectActivityUser] = useState(
    null
  );
  const [todaysActivityOrg, setTodaysActivityOrg] = useState({});
  const [todaysActivityUser, setTodaysActivityUser] = useState({});
  const [weeklyTimesheetOrg, setWeeklyTimesheetOrg] = useState([]);
  const [weeklyTimesheetUser, setWeeklyTimesheetUser] = useState([]);
  const [weeklyActivityOrg, setWeeklyActivityOrg] = useState([]);
  const [weeklyActivityUser, setWeeklyActivityUser] = useState([]);

  const [fromOnboardingOwner, setFromOnboardingOwner] = useState(false);
  const [fromOnboardingUser, setFromOnboardingUser] = useState(false);

  //new apis
  const [topUsersByActivity, setTopUsersByActivity] = useState([]);
  const [topUsersByHour, setTopUsersByHour] = useState([]);

  const [apiCalled, setApiCalled] = useState(false);
  const [apiCalledOrgId, setApiCalledOrgId] = useState(null);

  const startOfWeek = moment().subtract(1, "day").startOf("isoWeek");
  const endOfWeek = moment().subtract(1, "day").endOf("isoWeek");

  const tagManagerArgs = {
    gtmId: "GTM-NH9X5RJV",
  };

  const {
    selectedOrganization,
    location,
    getDashboardCompetitiveAnalytics,
    getDashboardWeeklyAggregatedData,
    getDashboardLastWeekAggregatedData,
    getDashboardOrganizationAnalytics,
    getDashboardTodaysActivity,
    getDashboardUserAnalytics,
    dashboardData,
    clearAdvertiseInfo,
    advertise,
  } = props;

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
    const organizationId = localStorage.getItem("selected_organization_id");
    const orgRole = localStorage.getItem("selected_organization_role");
    const orgPlan = localStorage.getItem("selected_organization_plan");
    if (organizationId && orgRole && !selectedOrganization) {
      setApiCalled(true);
      setApiCalledOrgId(organizationId);
      if (orgPlan && orgPlan === "Solo") {
        setRole("member");
      } else {
        setRole(orgRole);
      }
      const payload = {
        organization_id: organizationId,
        start_date: startOfWeek.format("YYYY-MM-DD"),
        end_date: endOfWeek.format("YYYY-MM-DD"),
      };
      if (
        orgRole &&
        (orgRole === "owner" ||
          orgRole === "admin" ||
          orgRole === "team_leader") &&
        (!orgPlan || (orgPlan && orgPlan !== "Solo"))
      ) {
        let lastWeekPayload = {
          organization_id: organizationId,
          start_date: moment(startOfWeek)
            .subtract(7, "days")
            .format("YYYY-MM-DD"),
          end_date: moment(startOfWeek)
            .subtract(1, "days")
            .format("YYYY-MM-DD"),
        };
        setCurrentMode("organization");
        getDashboardCompetitiveAnalytics(payload);
        getDashboardWeeklyAggregatedData(payload);
        getDashboardLastWeekAggregatedData(lastWeekPayload);
        getDashboardOrganizationAnalytics(payload);
        let activityPayload = {
          organization_id: organizationId,
          date: moment().format("YYYY-MM-DD"),
        };
        getDashboardTodaysActivity(activityPayload);
      } else {
        setCurrentMode("me");
        getDashboardUserAnalytics(payload);
      }
    }
    if (location.state && location.state.message) {
      if (location.state.message === "onboard") {
        setFromOnboardingOwner(true);
      } else if (location.state.message === "user-onboard") {
        setFromOnboardingUser(true);
      }
    }
    return () => {
      setFromOnboardingOwner(false);
      setFromOnboardingUser(false);
      setApiCalled(false);
    };
  }, []);

  useEffect(() => {
    if (
      location.state &&
      location.state.message &&
      location.state.message === "onboard" &&
      location.state.advertise &&
      location.state.advertise === "google"
    ) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "dashboard",
        page: location.pathname + location.search, // Send the current URL as part of the event
      });
      setTimeout(clearAdvertiseInfo(), 100);
    }
  }, [location]);

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      if (selectedOrganization.plan) {
        if (selectedOrganization.plan.screenshot_track) {
          setOrgPlan("monitored");
        } else {
          setOrgPlan("unmonitored");
        }
      }
      if (
        selectedOrganization.plan &&
        selectedOrganization.plan.name === "Solo"
      ) {
        setRole("member");
      } else {
        setRole(selectedOrganization.role);
      }

      if (
        !apiCalled ||
        (apiCalledOrgId && apiCalledOrgId !== selectedOrganization.id)
      ) {
        setWeekMostHourOrg(null);
        setWeekMostHourUser(null);
        setProjectMostHourOrg(null);
        setProjectMostHourUser(null);
        setHighestProjectActivityOrg(null);
        setHighestProjectActivityUser(null);
        setWeeklyTimesheetOrg([]);
        setWeeklyTimesheetUser([]);
        setTodaysActivityOrg({});
        setTodaysActivityUser({});
        setApiCalledOrgId(selectedOrganization.id);
        const payload = {
          organization_id: selectedOrganization.id,
          start_date: startOfWeek.format("YYYY-MM-DD"),
          end_date: endOfWeek.format("YYYY-MM-DD"),
        };
        if (
          selectedOrganization.role &&
          (selectedOrganization.role === "owner" ||
            selectedOrganization.role === "admin") &&
          selectedOrganization.plan &&
          selectedOrganization.plan.name !== "Solo"
        ) {
          let lastWeekPayload = {
            organization_id: selectedOrganization.id,
            start_date: moment(startOfWeek)
              .subtract(7, "days")
              .format("YYYY-MM-DD"),
            end_date: moment(startOfWeek)
              .subtract(1, "days")
              .format("YYYY-MM-DD"),
          };
          setCurrentMode("organization");
          getDashboardCompetitiveAnalytics(payload);
          getDashboardWeeklyAggregatedData(payload);
          getDashboardLastWeekAggregatedData(lastWeekPayload);
          getDashboardOrganizationAnalytics(payload);
          let activityPayload = {
            organization_id: selectedOrganization.id,
            date: moment().format("YYYY-MM-DD"),
          };
          getDashboardTodaysActivity(activityPayload);
        } else {
          setCurrentMode("me");
          getDashboardUserAnalytics(payload);
        }
      }
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (dashboardData.orgCompetitiveAnalytics) {
      if (
        dashboardData.orgCompetitiveAnalytics.top_5_active_user &&
        dashboardData.orgCompetitiveAnalytics.top_5_active_user.length > 0
      ) {
        setTopUsersByActivity(
          dashboardData.orgCompetitiveAnalytics.top_5_active_user
        );
      } else if (
        dashboardData.orgCompetitiveAnalytics.top_5_active_user &&
        dashboardData.orgCompetitiveAnalytics.top_5_active_user.length === 0
      ) {
        setTopUsersByActivity([]);
      }
      if (
        dashboardData.orgCompetitiveAnalytics.top_5_logged_user &&
        dashboardData.orgCompetitiveAnalytics.top_5_logged_user.length > 0
      ) {
        setTopUsersByHour(
          dashboardData.orgCompetitiveAnalytics.top_5_logged_user
        );
      } else if (
        dashboardData.orgCompetitiveAnalytics.top_5_logged_user &&
        dashboardData.orgCompetitiveAnalytics.top_5_logged_user.length === 0
      ) {
        setTopUsersByHour([]);
      }
    }
  }, [dashboardData.orgCompetitiveAnalytics]);

  useEffect(() => {
    if (dashboardData.orgWeeklyAggregatedData) {
      if (dashboardData.orgWeeklyAggregatedData.total_time_logged_this_week) {
        let object = { ...weekMostHourOrg };
        object.orgThisWeek =
          dashboardData.orgWeeklyAggregatedData.total_time_logged_this_week;
        setWeekMostHourOrg(object);
      }
      if (
        dashboardData.orgWeeklyAggregatedData.most_logged_project_this_week &&
        dashboardData.orgWeeklyAggregatedData.most_logged_project_this_week
          .length > 0
      ) {
        let object = { ...projectMostHourOrg };
        object.orgThisWeek =
          dashboardData.orgWeeklyAggregatedData.most_logged_project_this_week[0];
        setProjectMostHourOrg(object);
      }
      if (
        dashboardData.orgWeeklyAggregatedData.most_active_project_this_week &&
        dashboardData.orgWeeklyAggregatedData.most_active_project_this_week
          .length > 0
      ) {
        let object = { ...highestProjectActivityOrg };
        object.orgThisWeek =
          dashboardData.orgWeeklyAggregatedData.most_active_project_this_week[0];
        setHighestProjectActivityOrg(object);
      }
    }
  }, [dashboardData.orgWeeklyAggregatedData]);

  useEffect(() => {
    if (dashboardData.orgLastWeekAggregatedData) {
      if (dashboardData.orgLastWeekAggregatedData.total_time_logged_this_week) {
        let object = { ...weekMostHourOrg };
        object.orgLastWeek =
          dashboardData.orgLastWeekAggregatedData.total_time_logged_this_week;
        setWeekMostHourOrg(object);
      }
      if (
        dashboardData.orgLastWeekAggregatedData.most_logged_project_this_week &&
        dashboardData.orgLastWeekAggregatedData.most_logged_project_this_week
          .length > 0
      ) {
        let object = { ...projectMostHourOrg };
        object.orgLastWeek =
          dashboardData.orgLastWeekAggregatedData.most_logged_project_this_week[0];
        setProjectMostHourOrg(object);
      }
      if (
        dashboardData.orgLastWeekAggregatedData.most_active_project_this_week &&
        dashboardData.orgLastWeekAggregatedData.most_active_project_this_week
          .length > 0
      ) {
        let object = { ...highestProjectActivityOrg };
        object.orgLastWeek =
          dashboardData.orgLastWeekAggregatedData.most_active_project_this_week[0];
        setHighestProjectActivityOrg(object);
      }
    }
  }, [dashboardData.orgLastWeekAggregatedData]);

  useEffect(() => {
    if (
      dashboardData.orgTodaysActivity &&
      dashboardData.orgTodaysActivity.length > 0
    ) {
      const today = moment().format("DD");

      if (dashboardData.orgTodaysActivity.length === 1) {
        if (dashboardData.orgTodaysActivity[0].date.split("-")[2] === today) {
          setTodaysActivityOrg({
            todaysActivity: dashboardData.orgTodaysActivity[0],
            yesterdaysActivity: {
              average_activity: 0,
              date: moment().subtract(1, "days").format("YYYY-MM-DD"),
            },
          });
        } else {
          setTodaysActivityOrg({
            todaysActivity: {
              average_activity: 0,
              date: moment().format("YYYY-MM-DD"),
            },
            yesterdaysActivity: dashboardData.orgTodaysActivity[0],
          });
        }
      } else if (dashboardData.orgTodaysActivity.length === 2) {
        if (dashboardData.orgTodaysActivity[0].date.split("-")[2] === today) {
          setTodaysActivityOrg({
            todaysActivity: dashboardData.orgTodaysActivity[0],
            yesterdaysActivity: dashboardData.orgTodaysActivity[1],
          });
        } else {
          setTodaysActivityOrg({
            todaysActivity: dashboardData.orgTodaysActivity[1],
            yesterdaysActivity: dashboardData.orgTodaysActivity[0],
          });
        }
      }
    } else if (
      dashboardData.orgTodaysActivity &&
      dashboardData.orgTodaysActivity.length === 0
    ) {
      setTodaysActivityOrg({});
    }
  }, [dashboardData.orgTodaysActivity]);

  useEffect(() => {
    if (dashboardData.orgAnalytics) {
      if (
        dashboardData.orgAnalytics.weekly_timesheet_each_day &&
        dashboardData.orgAnalytics.weekly_timesheet_each_day.length > 0
      ) {
        let times = [];
        let dates = [];
        let startDate = moment(startOfWeek);
        while (moment(startDate).isSameOrBefore(moment(endOfWeek))) {
          times.push(0);
          dates.push(startDate);
          startDate = moment(startDate).add(1, "days");
        }
        dashboardData.orgAnalytics.weekly_timesheet_each_day.map((item) => {
          dates.map((date, index) => {
            if (moment(date).format("YYYY-MM-DD") === item.date) {
              times[index] = item.total_hours;
            }
          });
        });
        setWeeklyTimesheetOrg(times);
      } else if (
        dashboardData.orgAnalytics.weekly_timesheet_each_day &&
        dashboardData.orgAnalytics.weekly_timesheet_each_day.length === 0
      ) {
        setWeeklyTimesheetOrg([]);
      }
      if (
        dashboardData.orgAnalytics.weekly_average_activity_each_day &&
        dashboardData.orgAnalytics.weekly_average_activity_each_day.length > 0
      ) {
        let activities = [];
        let dates = [];
        let startDate = moment(startOfWeek);
        while (moment(startDate).isSameOrBefore(moment(endOfWeek))) {
          activities.push(0);
          dates.push(startDate);
          startDate = moment(startDate).add(1, "days");
        }
        dashboardData.orgAnalytics.weekly_average_activity_each_day.map(
          (item) => {
            dates.map((date, index) => {
              if (moment(date).format("YYYY-MM-DD") === item.date) {
                activities[index] = item.average_activity;
              }
            });
          }
        );
        setWeeklyActivityOrg(activities);
      } else if (
        dashboardData.orgAnalytics.weekly_average_activity_each_day &&
        dashboardData.orgAnalytics.weekly_average_activity_each_day.length === 0
      ) {
        setWeeklyActivityOrg([]);
      }
    } else {
      setWeeklyActivityOrg([]);
      setWeeklyTimesheetOrg([]);
    }
  }, [dashboardData.orgAnalytics]);

  useEffect(() => {
    if (dashboardData.userAnalytics) {
      let object = { ...weekMostHourUser };
      if (dashboardData.userAnalytics.total_time_logged_this_week) {
        object.userThisWeek =
          dashboardData.userAnalytics.total_time_logged_this_week;
        if (dashboardData.userAnalytics.total_time_logged_last_week) {
          object.userLastWeek =
            dashboardData.userAnalytics.total_time_logged_last_week;
        }
        setWeekMostHourUser(object);
      }
      if (
        dashboardData.userAnalytics.user_today_activity &&
        dashboardData.userAnalytics.user_today_activity.length > 0
      ) {
        const today = moment().format("DD");
        if (dashboardData.userAnalytics.user_today_activity.length === 1) {
          if (
            dashboardData.userAnalytics.user_today_activity[0].date.split(
              "-"
            )[2] === today
          ) {
            setTodaysActivityUser({
              todaysActivity:
                dashboardData.userAnalytics.user_today_activity[0],
              yesterdaysActivity: {
                average_activity: 0,
                date: moment().subtract(1, "days").format("YYYY-MM-DD"),
              },
            });
          } else {
            setTodaysActivityUser({
              todaysActivity: {
                average_activity: 0,
                date: moment().format("YYYY-MM-DD"),
              },
              yesterdaysActivity:
                dashboardData.userAnalytics.user_today_activity[0],
            });
          }
        } else if (
          dashboardData.userAnalytics.user_today_activity.length === 2
        ) {
          if (
            dashboardData.userAnalytics.user_today_activity[0].date.split(
              "-"
            )[2] === today
          ) {
            setTodaysActivityUser({
              todaysActivity:
                dashboardData.userAnalytics.user_today_activity[0],
              yesterdaysActivity:
                dashboardData.userAnalytics.user_today_activity[1],
            });
          } else {
            setTodaysActivityUser({
              todaysActivity:
                dashboardData.userAnalytics.user_today_activity[1],
              yesterdaysActivity:
                dashboardData.userAnalytics.user_today_activity[0],
            });
          }
        }
      } else if (
        dashboardData.userAnalytics.user_today_activity &&
        dashboardData.userAnalytics.user_today_activity.length === 0
      ) {
        setTodaysActivityUser({});
      }
      if (
        dashboardData.userAnalytics.most_active_project_this_week &&
        dashboardData.userAnalytics.most_active_project_this_week.length > 0
      ) {
        let object = { ...highestProjectActivityUser };
        object.userThisWeek =
          dashboardData.userAnalytics.most_active_project_this_week[0];
        if (
          dashboardData.userAnalytics.most_active_project_last_week &&
          dashboardData.userAnalytics.most_active_project_last_week.length > 0
        ) {
          object.userLastWeek =
            dashboardData.userAnalytics.most_active_project_last_week[0];
        }
        setHighestProjectActivityUser(object);
      }
      if (
        dashboardData.userAnalytics.most_logged_project_this_week &&
        dashboardData.userAnalytics.most_logged_project_this_week.length > 0
      ) {
        let object = { ...projectMostHourUser };
        object.userThisWeek =
          dashboardData.userAnalytics.most_logged_project_this_week[0];
        if (
          dashboardData.userAnalytics.most_logged_project_last_week &&
          dashboardData.userAnalytics.most_logged_project_last_week.length > 0
        ) {
          object.userLastWeek =
            dashboardData.userAnalytics.most_logged_project_last_week[0];
        }
        setProjectMostHourUser(object);
      }
      if (
        dashboardData.userAnalytics.weekly_timesheet_each_day &&
        dashboardData.userAnalytics.weekly_timesheet_each_day.length > 0
      ) {
        let times = [];
        let dates = [];
        let startDate = moment(startOfWeek);
        while (moment(startDate).isSameOrBefore(moment(endOfWeek))) {
          times.push(0);
          dates.push(startDate);
          startDate = moment(startDate).add(1, "days");
        }
        dashboardData.userAnalytics.weekly_timesheet_each_day.map((item) => {
          dates.map((date, index) => {
            if (moment(date).format("YYYY-MM-DD") === item.date) {
              times[index] = item.total_hours;
            }
          });
        });
        setWeeklyTimesheetUser(times);
      } else if (
        dashboardData.userAnalytics.weekly_timesheet_each_day &&
        dashboardData.userAnalytics.weekly_timesheet_each_day.length === 0
      ) {
        setWeeklyTimesheetUser([]);
      }
      if (
        dashboardData.userAnalytics.weekly_average_activity_each_day &&
        dashboardData.userAnalytics.weekly_average_activity_each_day.length > 0
      ) {
        let activities = [];
        let dates = [];
        let startDate = moment(startOfWeek);
        while (moment(startDate).isSameOrBefore(moment(endOfWeek))) {
          activities.push(0);
          dates.push(startDate);
          startDate = moment(startDate).add(1, "days");
        }
        dashboardData.userAnalytics.weekly_average_activity_each_day.map(
          (item) => {
            dates.map((date, index) => {
              if (moment(date).format("YYYY-MM-DD") === item.date) {
                activities[index] = item.average_activity;
              }
            });
          }
        );
        setWeeklyActivityUser(activities);
      } else if (
        dashboardData.userAnalytics.weekly_average_activity_each_day &&
        dashboardData.userAnalytics.weekly_average_activity_each_day.length ===
          0
      ) {
        setWeeklyActivityUser([]);
      }
    }
  }, [dashboardData.userAnalytics]);

  const onMeModeSelect = () => {
    setCurrentMode("me");
    if (selectedOrganization && selectedOrganization.id) {
      let payload = {
        organization_id: selectedOrganization.id,
        start_date: startOfWeek.format("YYYY-MM-DD"),
        end_date: endOfWeek.format("YYYY-MM-DD"),
      };
      getDashboardUserAnalytics(payload);
    }
  };
  const onOrganizationModeSelect = () => {
    setCurrentMode("organization");
  };

  return (
    <div className="content">
      {/* <GreetingsModalOwner
        isOpen={fromOnboardingOwner || fromOnboardingUser}
        toggle={() => {
          if (fromOnboardingOwner) {
            setFromOnboardingOwner(false);
          }
          if (fromOnboardingUser) {
            setFromOnboardingUser(false);
          }
        }}
        role={fromOnboardingUser ? "user" : "owner"}
      /> */}
      <DashboardPageHeader>
        <TitleAndModeSelectorDiv>
          <PageTitle style={{ marginBottom: "0" }}>Dashboard</PageTitle>
          {role && role !== "member" && (
            <DashboardModeSelector
              currentMode={currentMode}
              onMeModeSelect={onMeModeSelect}
              onOrganizationModeSelect={onOrganizationModeSelect}
              role={role}
            />
          )}
        </TitleAndModeSelectorDiv>
        {/* <DateRangeDiv>
          <DateRangeText>
            These data are showed from {startOfWeek.format("ddd, MMMM DD")} to{" "}
            {endOfWeek.format("ddd, MMMM DD")}, {endOfWeek.format("YYYY")}
          </DateRangeText>
        </DateRangeDiv> */}
        <DateRangeSection
          startDate={startOfWeek.format("ddd, MMMM DD")}
          endDate={endOfWeek.format("ddd, MMMM DD")}
          year={endOfWeek.format("YYYY")}
        />
      </DashboardPageHeader>
      <DashboardPageContent
        marginTop={
          (role && role === "member") ||
          (currentMode && currentMode !== "organization")
            ? "0px"
            : "20px"
        }
      >
        <DashboardCardContainer
          marginTop={orgPlan && orgPlan === "unmonitored" && "20px"}
        >
          {role &&
            role !== "member" &&
            currentMode &&
            currentMode === "organization" && (
              <>
                <TopUsersTimeCard
                  usersData={topUsersByHour}
                  isLoading={dashboardData.orgCompetitiveAnalyticsIsLoading}
                />
                {orgPlan && orgPlan === "monitored" && (
                  <TopUsersActivityCard
                    usersData={topUsersByActivity}
                    isLoading={dashboardData.orgCompetitiveAnalyticsIsLoading}
                  />
                )}
              </>
            )}
          {orgPlan && orgPlan === "unmonitored" && (
            <TopProjectsCard
              title={"Top Projects"}
              projectsData={
                (role && role === "member") ||
                (currentMode && currentMode === "me")
                  ? dashboardData.userAnalytics &&
                    dashboardData.userAnalytics.top_5_assigned_project
                  : dashboardData.orgAnalytics &&
                    dashboardData.orgAnalytics.top_5_assigned_project
              }
              isLoading={
                (role && role === "member") ||
                (currentMode && currentMode === "me")
                  ? dashboardData.userAnalyticsIsLoading
                  : dashboardData.orgAnalyticsIsLoading
              }
            />
          )}
        </DashboardCardContainer>
        <DashboardCardContainer marginTop="20px">
          <DashboardCardContainer>
            <SmallCardContainer>
              <TotalHourCard
                chartData={
                  (role && role === "member") ||
                  (currentMode && currentMode === "me")
                    ? weeklyTimesheetUser
                    : weeklyTimesheetOrg
                }
                label={"Total Hours"}
                organization={selectedOrganization}
                value={
                  currentMode && currentMode === "me"
                    ? weekMostHourUser
                    : weekMostHourOrg
                }
                currentMode={currentMode}
              />
              <SmallCardLabel>Total hours this week</SmallCardLabel>
            </SmallCardContainer>
            <SmallCardContainer>
              <MostProjectHourCard
                organization={selectedOrganization}
                value={
                  (role && role === "member") ||
                  (currentMode && currentMode === "me")
                    ? projectMostHourUser
                    : projectMostHourOrg
                }
                currentMode={currentMode}
              />
              <SmallCardLabel>Most Hour Logged Project</SmallCardLabel>
            </SmallCardContainer>
          </DashboardCardContainer>
          {orgPlan && orgPlan === "monitored" ? (
            <DashboardCardContainer>
              <SmallCardContainer>
                <MostProjectActivityCard
                  organization={selectedOrganization}
                  value={
                    (role && role === "member") ||
                    (currentMode && currentMode === "me")
                      ? highestProjectActivityUser
                      : highestProjectActivityOrg
                  }
                  currentMode={currentMode}
                />
                <SmallCardLabel>Most Project Activity</SmallCardLabel>
              </SmallCardContainer>
              <SmallCardContainer>
                <TodaysActivityCard
                  todaysActivity={
                    (role && role === "member") ||
                    (currentMode && currentMode === "me")
                      ? todaysActivityUser
                      : todaysActivityOrg
                  }
                  currentMode={currentMode}
                />
                <SmallCardLabel>Today's activity</SmallCardLabel>
              </SmallCardContainer>
            </DashboardCardContainer>
          ) : (
            <RecentTasksCard
              ongoingTasks={
                (role && role === "member") ||
                (currentMode && currentMode === "me")
                  ? dashboardData.userAnalytics &&
                    dashboardData.userAnalytics.top_5_ongoing_task
                  : dashboardData.orgAnalytics &&
                    dashboardData.orgAnalytics.top_3_ongoing_task
              }
              getTime={getTime}
              isLoading={
                (role && role === "member") ||
                (currentMode && currentMode === "me")
                  ? dashboardData.userAnalyticsIsLoading
                  : dashboardData.orgAnalyticsIsLoading
              }
            />
          )}
        </DashboardCardContainer>
        {orgPlan && orgPlan === "monitored" && (
          <DashboardCardContainer marginTop="20px">
            <WeeklyActivityCard
              role={role}
              currentMode={currentMode}
              weeklyActivity={
                (role && role === "member") ||
                (currentMode && currentMode === "me")
                  ? dashboardData.userAnalytics &&
                    dashboardData.userAnalytics.weekly_average_activity_each_day
                  : dashboardData.orgAnalytics &&
                    dashboardData.orgAnalytics.weekly_average_activity_each_day
              }
              isLoading={
                (role && role === "member") ||
                (currentMode && currentMode === "me")
                  ? dashboardData.userAnalyticsIsLoading
                  : dashboardData.orgAnalyticsIsLoading
              }
            />
            <TopProjectsCard
              title={"Top Projects"}
              projectsData={
                (role && role === "member") ||
                (currentMode && currentMode === "me")
                  ? dashboardData.userAnalytics &&
                    dashboardData.userAnalytics.top_5_assigned_project
                  : dashboardData.orgAnalytics &&
                    dashboardData.orgAnalytics.top_5_assigned_project
              }
              isLoading={
                (role && role === "member") ||
                (currentMode && currentMode === "me")
                  ? dashboardData.userAnalyticsIsLoading
                  : dashboardData.orgAnalyticsIsLoading
              }
            />
          </DashboardCardContainer>
        )}

        <DashboardCardContainer
          marginTop="20px"
          columns={orgPlan && orgPlan === "monitored" ? "44% 55%" : "100%"}
        >
          {orgPlan && orgPlan === "monitored" && (
            <RecentTasksCard
              ongoingTasks={
                (role && role === "member") ||
                (currentMode && currentMode === "me")
                  ? dashboardData.userAnalytics &&
                    dashboardData.userAnalytics.top_5_ongoing_task
                  : dashboardData.orgAnalytics &&
                    dashboardData.orgAnalytics.top_3_ongoing_task
              }
              getTime={getTime}
              isLoading={
                (role && role === "member") ||
                (currentMode && currentMode === "me")
                  ? dashboardData.userAnalyticsIsLoading
                  : dashboardData.orgAnalyticsIsLoading
              }
            />
          )}
          <TimesheetCard
            weeklyTimesheet={
              (role && role === "member") ||
              (currentMode && currentMode === "me")
                ? dashboardData.userAnalytics &&
                  dashboardData.userAnalytics.weekly_timesheet_each_day
                : dashboardData.orgAnalytics &&
                  dashboardData.orgAnalytics.weekly_timesheet_each_day
            }
            getTime={getTime}
            isLoading={
              (role && role === "member") ||
              (currentMode && currentMode === "me")
                ? dashboardData.userAnalyticsIsLoading
                : dashboardData.orgAnalyticsIsLoading
            }
            plan={orgPlan}
          />
        </DashboardCardContainer>
      </DashboardPageContent>
    </div>
  );
};

export default Dashboard;
