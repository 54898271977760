import React, { useEffect, useState, useMemo } from "react";
import LinearActivityBar from "../../../components/ProgressBar/LinearActivityBar";
import moment from "moment";
import {
  formatDurationToHoursMinutes,
  getFullName,
} from "../../../utils/helper";
import {
  AssigneesManageImgTextGrid,
  AssigneesManageRoundImage,
  BillingQues,
  BillingTooltip,
  BillingTooltipText,
} from "../../../styledComponents/createProject";
import {
  UserActivityRow,
  UserActivitySection,
  OuterBox,
  VerticalLine,
} from "../../../styledComponents/Activity";
import {
  FlexSection,
  ActivityTextSection,
} from "../../../styledComponents/members";
import {
  CommonText,
  RoundMiniImage,
  TableText,
  CommonImage,
  CommonFlex,
} from "../../../styledComponents/common";
import alertIcon from "../../../assets/img/icons/alert-grey.svg";

import FirstRoundLetterComp from "../../../components/FirstRoundLetter/FirstRoundLetter";
import {
  UserDotCircle,
  UserDotDivFirstRoundLetterComp,
} from "../../LiveFeed/CurrentlyTracking/currentlyTrackingStyles";
import { Modal, ModalBody } from "reactstrap";
import styled from "styled-components";
import { PrimaryButton } from "../../../styledComponents/buttons";

const CustomModal = styled(Modal)`
  max-width: 540px;
  padding-top: 0px;
  margin-top: 35vh;
  box-shadow: 6px 6px 15px rgba(0, 0, 0, 0.2); 
  border-radius: 8px; 
`;

const ManualTimeModalBody = styled(ModalBody)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px;
`;

const CommImageNameComp = ({ imgSource, name, color, backColor, isOnline }) => (
  <AssigneesManageImgTextGrid>
    {imgSource ? (
      <AssigneesManageRoundImage>
        <RoundMiniImage src={imgSource} alt="" />
        {isOnline && (
          <UserDotDivFirstRoundLetterComp>
            <UserDotCircle />
          </UserDotDivFirstRoundLetterComp>
        )}
      </AssigneesManageRoundImage>
    ) : (
      <FirstRoundLetterComp
        text={name}
        color={color}
        backColor={backColor}
        isOnline={isOnline}
      />
    )}

    <TableText name>{name}</TableText>
  </AssigneesManageImgTextGrid>
);

const UserActivityInfo = ({
  userActivity,
  selectedOrganization,
  clockInOutList,
  liveMembersList,
  setIdleTimeModalVisible,
}) => {
  const [today, setToday] = useState(moment());
  const [clockIn, setClockIn] = useState(null);
  const [clockOut, setClockOut] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [activeTime, setActiveTime] = useState(0);
  const [neutralTime, setNeutralTime] = useState(0);
  const [idleTime, setIdleTime] = useState(0);

  useMemo(() => {
    if (userActivity?.total_duration) {
      const activeDuration =
        userActivity.total_duration * (userActivity.average_activity / 100);
      const idleDuration = userActivity.total_idle_time;
      const neutralDuration = parseInt(
        userActivity.total_duration - activeDuration - idleDuration
      );

      setActiveTime(activeDuration);
      setNeutralTime(neutralDuration);
      setIdleTime(idleDuration);
    }
  }, [userActivity]);

  useEffect(() => {
    if (clockInOutList?.[0]?.attendance?.[0]) {
      const attendance = clockInOutList[0].attendance[0];

      if (attendance.date) {
        setToday(moment(attendance.date));
      }

      if (attendance.clock_in) {
        setClockIn(attendance.clock_in);
      }

      if (attendance.clock_out) {
        setClockOut(attendance.clock_out);
      }
    }
  }, [clockInOutList]);

  const toggleModal = () => {
    setIsModalOpen(false);
  };

  const dateFormat = localStorage.getItem("date_format") || "DD-MM-YYYY";
  return userActivity && userActivity.total_duration ? (
    <OuterBox>
      <React.Fragment>
        <UserActivityRow>
          <UserActivitySection align="left" column="70px">
            {userActivity?.user?.id ? (
              <CommImageNameComp
                name={getFullName(userActivity.user)}
                imgSource={userActivity.user.avatar || null}
                color={userActivity.user.color}
                isOnline={
                  liveMembersList?.find(
                    (member) => member.user.id === userActivity.user.id
                  )
                    ? true
                    : false
                }
              />
            ) : null}
            <ActivityTextSection>
              <CommonText fontSize="13.5px" $title>
                {today.format(dateFormat)}
              </CommonText>
              <CommonText $label fontSize="12px" fontWeight="500">
                {today.format("dddd")}
              </CommonText>
            </ActivityTextSection>

            <ActivityTextSection>
              <CommonFlex gap="5px">
                <CommonText fontSize="13.5px" $title>
                  {clockIn ? moment(clockIn).format("h:mm a") : ""} {"- "}
                  {clockOut && moment(clockOut).isBefore(moment(), "day")
                    ? moment(clockOut).format("h:mm a")
                    : ""}
                </CommonText>
                {clockOut && moment(clockOut).isSame(moment(), "day") && (
                  <BillingQues onClick={() => setIsModalOpen(!isModalOpen)}>
                    ?
                    {isModalOpen && (
                      <CustomModal
                        isOpen={isModalOpen}
                        toggle={toggleModal}
                        unmountOnClose={true}
                      >
                        <ManualTimeModalBody>
                          <CommonText>
                          Clock out time will be recorded after midnight.
                          </CommonText>
                          <PrimaryButton
                            width="100px"
                            margin="0 0 0 auto"
                            onClick={toggleModal}
                          >
                            Ok
                          </PrimaryButton>
                        </ManualTimeModalBody>
                      </CustomModal>
                    )}
                  </BillingQues>
                )}
              </CommonFlex>
              <CommonText $label fontSize="12px" fontWeight="500">
                Clock In/Out
              </CommonText>
            </ActivityTextSection>

            <ActivityTextSection>
              <CommonText fontSize="13.5px" $title>
                {formatDurationToHoursMinutes(
                  userActivity.total_duration,
                  false
                )}
              </CommonText>
              <CommonText $label fontSize="12px" fontWeight="500">
                Total Time
              </CommonText>
            </ActivityTextSection>
          </UserActivitySection>

          <VerticalLine />
          {selectedOrganization?.configuration?.mouse_track ||
          selectedOrganization?.configuration?.keystroke_track ? (
            <UserActivitySection align="right">
              <ActivityTextSection>
                <FlexSection gap="10px">
                  <LinearActivityBar
                    percent={parseInt(userActivity.average_activity)}
                  />
                  <CommonText fontSize="13.5px" $title>
                    {`${parseInt(userActivity.average_activity)}%`}
                  </CommonText>
                </FlexSection>
                <CommonText $label fontSize="12px" fontWeight="500">
                  Activity
                </CommonText>
              </ActivityTextSection>

              <ActivityTextSection>
                <CommonText fontSize="13.5px" $title>
                  {formatDurationToHoursMinutes(activeTime, true)}
                </CommonText>
                <CommonText $label fontSize="12px" fontWeight="500">
                  Active Time
                </CommonText>
              </ActivityTextSection>

              <ActivityTextSection>
                <CommonText fontSize="13.5px" $title>
                  {formatDurationToHoursMinutes(neutralTime, false)}
                </CommonText>
                <CommonText $label fontSize="12px" fontWeight="500">
                  Neutral Time
                </CommonText>
              </ActivityTextSection>
              <ActivityTextSection
                onClick={() =>
                  setIdleTimeModalVisible && setIdleTimeModalVisible(true)
                }
                style={
                  setIdleTimeModalVisible
                    ? {
                        cursor: "pointer",
                        padding: "5px 10px",
                        borderRadius: "5px",
                      }
                    : {}
                }
                onMouseEnter={(e) => {
                  if (setIdleTimeModalVisible)
                    e.currentTarget.style.backgroundColor = "#EAF9F7";
                }}
                onMouseLeave={(e) => {
                  if (setIdleTimeModalVisible)
                    e.currentTarget.style.backgroundColor = "transparent";
                }}
              >
                <CommonText
                  fontSize="13.5px"
                  $title
                  danger={idleTime ? true : false}
                >
                  {idleTime ? (
                    formatDurationToHoursMinutes(idleTime, true)
                  ) : (
                    <span style={{ display: "flex", justifyContent: "center" }}>
                      -
                    </span>
                  )}
                </CommonText>
                <CommonText $label fontSize="12px" fontWeight="500">
                  Idle Time
                </CommonText>
              </ActivityTextSection>
            </UserActivitySection>
          ) : (
            <UserActivitySection align="right" alert>
              <CommonImage src={alertIcon} alt="" size="20px" />
              <CommonText $label fontSize="12px" fontWeight="500">
                Activity tracking is turned off from activity settings.
              </CommonText>
            </UserActivitySection>
          )}
        </UserActivityRow>
      </React.Fragment>
    </OuterBox>
  ) : null;
};

export default UserActivityInfo;
