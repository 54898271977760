import {
  GET_TIMESHEET_APPROVAL_AWAITING_LIST,
  GET_TIMESHEET_APPROVAL_HISTORY,
  APPROVE_OR_REJECT_TIMESHEETS,
  LOAD_MORE_TIMESHEET_APPROVAL_LIST,
  CLEAR_LOAD_MORE_TIMESHEET_APPROVAL_LIST,
  DELETE_AWAITING_TIMESHEETS_LIST,
} from "../../modules/constants";

export function getTimesheetApprovalAwaitingList(payload) {
  return {
    type: GET_TIMESHEET_APPROVAL_AWAITING_LIST,
    payload,
  };
}
export function getTimesheetApprovalHistory(payload) {
  return {
    type: GET_TIMESHEET_APPROVAL_HISTORY,
    payload,
  };
}
export function approveOrRejectTimesheets(payload) {
  return {
    type: APPROVE_OR_REJECT_TIMESHEETS,
    payload,
  };
}

export function loadMoreTimesheetApprovalList(payload) {
  return {
    type: LOAD_MORE_TIMESHEET_APPROVAL_LIST,
    payload,
  };
}

export function clearLoadMoreTimesheetApprovalList() {
  return {
    type: CLEAR_LOAD_MORE_TIMESHEET_APPROVAL_LIST,
  };
}

export function deleteAwaitingTimesheets(value) {
  return {
    type: DELETE_AWAITING_TIMESHEETS_LIST,
    payload: value,
  };
}
