import { useState, useEffect } from "react";
import moment from "moment";
import DatePicker from "../../components/SingleDatePicker/SingleDatePicker";
import SortIcon from "../../assets/img/icons/sortIcon.svg";
import DownloadIcon from "../../assets/img/icons/download-dark.svg";
import AllNotesIcon from "../../assets/img/icons/all-notes.svg";
import LeftArrow_3 from "../../assets/img/icons/LeftArrow_3.svg";
import RightArrow_3 from "../../assets/img/icons/RightArrow_3.svg";
import {
  InputWithLabelSection,
  FilterLabel,
  CommonImage,
  CommonFlex,
} from "../../styledComponents/common";
import { FilterButton, PrimaryButton } from "../../styledComponents/buttons";
import { IconArrow } from "../../styledComponents/Activity";

import CommonFilter from "../../components/CommonFilter/commonFilterContainer";

import TimezoneDropdown from "../../components/TimezoneDropdown/TimezoneDropdown";
import { getDateLabel } from "../../utils/helper";
import { useDispatch } from "react-redux";
import { setPathName } from "../../filterReducer";
import CommonTooltip from "../../components/Tooltip/CommonTooltip";

const ActivityFilter = (props) => {
  const {
    filters,
    selectedOrganization,
    getLiveMembersList,
    fetchAllNotes,
    setFetchAllNotes,
    reloadScreenshotList,
    title,
    setScreenshotMemberId,
    changeSortType,
    getAllNotes,
    getScreenshotList,
    getUserActivity,
    getClockInOutList,
    getScreenCastList,
    getAppsList,
    getUrlList,
    getLocationList,
    handleAllNotesClick,
    sortType,
    sortOptions,
    toggleRecordingDownloadModal,
    setDate,
  } = props

  
  const dispatch = useDispatch();
  const activityUrls = [
    "/user/activity/screenshots",
    "/user/activity/screen-records",
    "/user/activity/urls",
    "/user/activity/apps",
  ];
  const [selectedDate, setSelectedDate] = useState(
    activityUrls.includes(filters?.pathname) && filters?.selectedDate
      ? filters?.selectedDate
      : moment()
  );
  const [selectedProject, setSelectedProject] = useState(
    activityUrls.includes(filters?.pathname) && filters?.selectedProject
      ? filters?.selectedProject
      : { value: null, label: "(All Active Projects)" }
  );
  const [selectedTask, setSelectedTask] = useState(
    activityUrls.includes(filters?.pathname) && filters?.selectedTask
      ? filters?.selectedTask
      : { value: "", label: "(All Tasks)" }
  );
  const [selectedMember, setSelectedMember] = useState(
    activityUrls.includes(filters?.pathname) && filters?.selectedMember
      ? filters?.selectedMember
      : ""
  );
  const [selectedTimezone, setSelectedTimezone] = useState(
    activityUrls.includes(filters?.pathname) && filters?.selectedTimezone
      ? filters?.selectedTimezone
      : null
  );

  const [sortTooltipVisible, setSortTooltipVisible] = useState(false);

  const isToday = selectedDate.isSame(moment(), "day");

  useEffect(() => {
    if (setDate) {
      setDate(selectedDate);
    }
  }, [selectedDate]);

  useEffect(() => {
    dispatch(
      setPathName({
        pathname: location.pathname,
        selectedTask,
        selectedDate,
        selectedProject,
        selectedMember,
      })
    );
  }, [selectedTask, selectedDate, selectedProject, selectedMember]);

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      let payload = {
        organization_id: selectedOrganization.id,
        date: selectedDate.format("YYYY-MM-DD"),
      };
      if (selectedOrganization.timezone) {
        setSelectedTimezone({
          type: "Organization",
          timezone: selectedOrganization.timezone,
          offset: selectedOrganization.timezoneoffset,
        });
        payload.timezone = selectedOrganization.timezone;
      }

      setSelectedProject(
        activityUrls.includes(filters?.pathname) && filters?.selectedProject
          ? filters?.selectedProject
          : { value: null, label: "(All Active Projects)" }
      );
      setSelectedTask(
        activityUrls.includes(filters?.pathname) && filters?.selectedTask
          ? filters?.selectedTask
          : { value: "", label: "(All Tasks)" }
      );
      setSelectedMember(
        activityUrls.includes(filters?.pathname) && filters?.selectedMember
          ? filters?.selectedMember
          : ""
      );
      if (
        activityUrls.includes(filters?.pathname) &&
        filters?.selectedMember?.id
      ) {
        payload.user_id = filters?.selectedMember.id;
      }
      if (
        activityUrls.includes(filters?.pathname) &&
        filters?.selectedProject?.value
      ) {
        payload.project_id = filters?.selectedProject.value;
      }
      if (
        activityUrls.includes(filters?.pathname) &&
        filters?.selectedTask?.value
      ) {
        payload.task_id = filters?.selectedTask.value;
      }

      if (title === "Screenshots") {
        setScreenshotMemberId(null);
        changeSortType({
          label: "Time (Old to new)",
          value: "OldToNew",
        });
      }

      actionsReq(payload);
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (fetchAllNotes) {
      if (selectedOrganization && selectedOrganization.id && selectedDate) {
        let payload = {
          organization_id: selectedOrganization.id,
          date: selectedDate.format("YYYY-MM-DD"),
          user_id: selectedMember
            ? selectedMember.id
            : localStorage.getItem("user_id"),
        };
        getAllNotes(payload);
      }
      setFetchAllNotes(false);
    }
  }, [fetchAllNotes]);

  useEffect(() => {
    if (reloadScreenshotList) {
      if (selectedOrganization && selectedOrganization.id) {
        const payload = {
          organization_id: selectedOrganization.id,
          date: selectedDate.format("YYYY-MM-DD"),
        };
        if (selectedMember && selectedMember.id) {
          payload.user_id = selectedMember.id;
        }
        if (selectedProject && selectedProject.value) {
          payload.project_id = selectedProject.value;
        }
        if (selectedTask && selectedTask.value) {
          payload.task_id = selectedTask.value;
        }
        if (selectedTimezone && selectedTimezone.timezone) {
          payload.timezone = selectedTimezone.timezone;
        }
        actionsReq(payload);
      }
    }
  }, [reloadScreenshotList]);

  const actionsReq = (payload) => {
    const user_id = localStorage.getItem("user_id");
    if (title === "Screenshots") {
      getScreenshotList(payload);
      getUserActivity(payload);
      getClockInOutList({
        organization_id: payload.organization_id,
        start_date: payload.date,
        end_date: payload.date,
        page: 1,
        tz: payload.timezone,
        user_id: payload.user_id || user_id,
      });
    } else if (title === "ScreenCasts") {
      getScreenCastList(payload);
      getUserActivity(payload);
      getClockInOutList({
        organization_id: payload.organization_id,
        start_date: payload.date,
        end_date: payload.date,
        page: 1,
        tz: payload.timezone,
        user_id: payload.user_id || user_id,
      });
    } else if (title === "Apps") {
      getAppsList(payload);
    } else if (title === "URLs") {
      getUrlList(payload);
    } else if (title === "Location") {
      getLocationList(payload);
    }
  };

  const selectProject = (e) => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        date: selectedDate.format("YYYY-MM-DD"),
      };
      if (selectedMember && e?.value) {
        payload.user_id = selectedMember.id;
      }
      if (e && e.value) {
        payload.project_id = e.value;
      }
      if (selectedTimezone && selectedTimezone.timezone) {
        payload.timezone = selectedTimezone.timezone;
      }
      actionsReq(payload);
    }

    if (
      e &&
      e.value &&
      selectedProject &&
      selectedProject.value &&
      e.value !== selectedProject.value
    ) {
      setSelectedProject(e);
      setSelectedTask({ value: "", label: "(All Tasks)" });
    } else if ((e && !e.value) || e == null) {
      setSelectedMember("");
      setSelectedTask({ value: "", label: "(All Tasks)" });
      setSelectedProject(e);
      if (title === "Screenshots") {
        setScreenshotMemberId(null);
      }
    } else {
      setSelectedProject(e);
    }
  };

  const selectTask = (e) => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        date: selectedDate.format("YYYY-MM-DD"),
      };
      if (typeof selectedProject === "object" && selectedProject.value) {
        payload.project_id = selectedProject.value;
      }
      if (selectedMember) {
        payload.user_id = selectedMember.id;
      }
      if (e && e.value) {
        payload.task_id = e.value;
      }
      if (selectedTimezone && selectedTimezone.timezone) {
        payload.timezone = selectedTimezone.timezone;
      }
      actionsReq(payload);
    }
    setSelectedTask(e);
  };

  const selectTeamMember = (e) => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        date: selectedDate.format("YYYY-MM-DD"),
        user_id: e.id,
      };
      if (selectedProject && selectedProject.value) {
        payload.project_id = selectedProject.value;
      }
      if (selectedTask && selectedTask.value) {
        payload.task_id = selectedTask.value;
      }
      if (
        selectedTimezone &&
        selectedTimezone.type === "Member" &&
        e.timezone &&
        e.timezone.tz
      ) {
        payload.timezone = e.timezone.tz;
        setSelectedTimezone({
          type: "Member",
          timezone: e.timezone.tz,
          offset: e.timezone.offset,
        });
      } else if (
        selectedTimezone &&
        selectedTimezone.type === "Organization" &&
        selectedTimezone.timezone
      ) {
        payload.timezone = selectedTimezone.timezone;
      }
      setSelectedMember(e);
      if (title === "Screenshots") {
        setScreenshotMemberId(e.id);
      }
      actionsReq(payload);
    }
  };

  const clearMember = () => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        date: selectedDate.format("YYYY-MM-DD"),
      };
      if (selectedProject && selectedProject.value) {
        payload.project_id = selectedProject.value;
      }
      if (selectedTask && selectedTask.value) {
        payload.task_id = selectedTask.value;
      }
      if (selectedOrganization.timezone) {
        setSelectedTimezone({
          type: "Organization",
          timezone: selectedOrganization.timezone,
          offset: selectedOrganization.timezoneoffset,
        });
        payload.timezone = selectedOrganization.timezone;
      }
      actionsReq(payload);
    }
    setSelectedMember("");
    if (title === "Screenshots") {
      setScreenshotMemberId(null);
    }
  };

  const selectDate = (date) => {
    let organizationId = selectedOrganization ? selectedOrganization.id : "";
    setSelectedDate(date);
    if (organizationId) {
      const payload = {
        organization_id: organizationId,
        date: date.format("YYYY-MM-DD"),
      };
      if (selectedProject && selectedProject.value) {
        payload.project_id = selectedProject.value;
      }
      if (selectedTask && selectedTask.value) {
        payload.task_id = selectedTask.value;
      }
      if (selectedMember && selectedMember.id !== "") {
        payload.user_id = selectedMember.id;
      }
      if (selectedTimezone && selectedTimezone.timezone) {
        payload.timezone = selectedTimezone.timezone;
      }
      actionsReq(payload);
    }
  };

  const singleDateChange = (direction) => {
    setSelectedDate((prevDate) => {
      const newDate =
        direction === "right"
          ? moment(prevDate).add(1, "days")
          : moment(prevDate).subtract(1, "days");
      selectDate(newDate);
      return newDate;
    });
  };

  const selectToday = () => {
    selectDate(moment());
  };

  const onTimezoneSelect = (timezone) => {
    let organizationId = selectedOrganization ? selectedOrganization.id : "";
    setSelectedTimezone(timezone);
    if (organizationId) {
      const payload = {
        organization_id: organizationId,
        date: selectedDate.format("YYYY-MM-DD"),
      };
      if (selectedProject && selectedProject.value) {
        payload.project_id = selectedProject.value;
      }
      if (selectedTask && selectedTask.value) {
        payload.task_id = selectedTask.value;
      }
      if (selectedMember && selectedMember.id !== "") {
        payload.user_id = selectedMember.id;
      }
      if (timezone && timezone.timezone) {
        payload.timezone = timezone.timezone;
      }
      actionsReq(payload);
    }
  };

  useEffect(() => {
    const member_id = localStorage.getItem("user_id");
    if (selectedOrganization && selectedOrganization.id) {
      if (selectedMember?.id || member_id) {
        getLiveMembersList &&
          getLiveMembersList({
            organization_id: selectedOrganization.id,
            user_id: selectedMember?.id ? selectedMember?.id : member_id,
          });
      }
    }
  }, [selectedMember, selectedOrganization]);

  const [sortingOrder, setSortingOrder] = useState(
    sortOptions && sortOptions[0]
  );

  const toggleSort = () => {
    if (sortingOrder.value === "OldToNew") {
      setSortingOrder(sortOptions[1]);
      changeSortType(sortOptions[1]);
    } else {
      setSortingOrder(sortOptions[0]);
      changeSortType(sortOptions[0]);
    }
  };

  return (
    <CommonFlex style={{ marginTop: "30px" }} columns={"auto auto"}>
      <CommonFlex
        style={{
          flexGrow: 1,
        }}
      >
        <CommonFilter
          selectedProject={selectedProject}
          selectProject={selectProject}
          selectedTask={selectedTask}
          selectTask={selectTask}
          selectedMember={selectedMember}
          selectMember={selectTeamMember}
          clearMember={clearMember}
          addAllProjects={true}
          addAllTasksOption={true}
          columns={title === "Location" ? "180px 57px" : "180px 180px 57px"}
          isLocation={title === "Location"}
        />
        <InputWithLabelSection>
          <FilterLabel>
            Date
            {getDateLabel({
              startDate: selectedDate,
              endDate: selectedDate,
              onlyToday: true,
            })}
          </FilterLabel>
          <CommonFlex gap="2px">
            <DatePicker
              id="activityFilterDate"
              date={selectedDate}
              onDateChange={selectDate}
              dateDisplayFormat={"DD/MM/YYYY"}
              placeholder={"Select date..."}
              allowPreviousDates
              disableFutureDates
            />

            <CommonFlex
              gap="0px"
              style={{
                minWidth: "max-content",
              }}
            >
              <IconArrow
                onClick={() => {
                  singleDateChange("left");
                }}
                padding="5px"
                size="40px"
                src={LeftArrow_3}
              />
              <IconArrow
                margin="0 0 0 2px"
                onClick={() => {
                  if (!isToday) {
                    singleDateChange("right");
                  }
                }}
                padding="5px"
                size="40px"
                src={RightArrow_3}
                disabled={isToday}
              />
            </CommonFlex>
            <div>
              <FilterButton
                type="gray"
                padding="10px"
                onClick={() => selectToday()}
              >
                Today
              </FilterButton>
            </div>
          </CommonFlex>
        </InputWithLabelSection>
      </CommonFlex>
      <CommonFlex
        direction="row-reverse"
        gap="10px"
        width="100%"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        {["Screenshots", "ScreenCasts"].includes(title) && (
          <CommonFlex gap="10px">
            <InputWithLabelSection style={{ position: "relative" }}>
              <FilterButton
                style={{ width: "max-content", padding: "10px" }}
                onClick={toggleSort}
                onMouseEnter={() => setSortTooltipVisible(true)}
                onMouseLeave={() => setSortTooltipVisible(false)}
              >
                <CommonImage
                  src={SortIcon}
                  style={{
                    transform:
                      sortingOrder.value === "OldToNew"
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                    transition: "transform 0.3s ease",
                  }}
                />
                {sortTooltipVisible && (
                  <CommonTooltip
                    tooltipText={sortingOrder.label}
                    top="-36px"
                    left="-48px"
                    width="max-content"
                    height="28px"
                  />
                )}
              </FilterButton>
            </InputWithLabelSection>
            {title === "ScreenCasts" && (
              <InputWithLabelSection>
                <FilterButton
                  style={{ width: "max-content", padding: "10px" }}
                  onClick={toggleRecordingDownloadModal}
                >
                  <CommonImage src={DownloadIcon} />
                </FilterButton>
              </InputWithLabelSection>
            )}
            <InputWithLabelSection>
              <FilterButton
                style={{ width: "max-content", padding: "10px" }}
                onClick={() => handleAllNotesClick()}
              >
                <CommonImage src={AllNotesIcon} />
              </FilterButton>
            </InputWithLabelSection>
          </CommonFlex>
        )}

        {selectedTimezone && title !== "Location" && (
          <InputWithLabelSection>
            <FilterLabel>Timezone</FilterLabel>
            <TimezoneDropdown
              selectedOrganization={selectedOrganization}
              selectedMember={selectedMember}
              selectTimezone={onTimezoneSelect}
              selectedTimezone={selectedTimezone}
              dropdownWidth="140px"
            />
          </InputWithLabelSection>
        )}
      </CommonFlex>
    </CommonFlex>
  );
};

export default ActivityFilter;
