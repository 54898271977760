import styled from "styled-components";

import { Progress, Card } from "reactstrap";

import { Link } from "react-router-dom";

export const DashboardModeSelect = styled.div`
  width: ${({ role }) => (role && role === "team_leader" ? "140px" : "185px")};
  display: flex;
  justify-content: space-between;
  height: 34px;
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  border-radius: 6px;
  background: #fff;
  margin-left: 15px;
  padding: 3px;
`;

//dashboard card
export const DashboardCard = styled(Card)`
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  border-radius: 10px;
  background: #fff;
  box-shadow: none;
  margin-bottom: 0;
`;
export const DashboardCardTitle = styled.span`
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  color: ${({ theme }) => theme.colors.main.textMain};
`;
export const DashboardCardSubTitle = styled.span`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.main.labelText};
  margin-left: 15px;
`;
export const LinkToReport = styled(Link)`
  font-size: 13px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  color: ${({ theme }) => theme.colors.main.primary};
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.hover.primary};
    text-decoration: none;
  }
`;

//small card
export const DashboardSmallCard = styled(Card)`
  height: 13.5rem;
  overflow: hidden !important;
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  border-radius: 10px;
  background: #fff;
  margin-bottom: 0;
  box-shadow: none;
`;
export const SmallCardLabel = styled.span`
  font-size: ${({ fontSize }) => fontSize || `14px`};
  color: ${({ theme }) => theme.colors.main.labelText};
`;

export const SmallCardLabelEllipsis = styled.span`
  font-size: ${({ fontSize }) => fontSize || `14px`};
  color: ${({ theme, name }) =>
    name ? theme.colors.main.textMain : theme.colors.main.labelText};
  max-width: 12.5vw; // Default for larger screens
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @media screen and (max-width: 768px) {
    // For mobile screens
    max-width: 50vw; // Adjusted for smaller screens
  }
`;

export const LargeCardNameEllipsis = styled.span`
  font-size: ${({ fontSize }) => fontSize || `14px`};
  color: ${({ theme }) => theme.colors.main.textMain};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SmallCardPercentage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 5px;
  font-size: 11px;
  background: ${({ increase, percentage, theme }) =>
    increase === true && percentage !== "0.00%"
      ? theme.colors.main.success
      : increase === false && percentage !== "0.00%"
      ? theme.colors.main.error
      : percentage !== "0.00%"
      ? `#e8eaee`
      : `#e8eaee`};
  border-radius: 10px;
  color: #fff;
`;
export const SmallCardContent = styled.span`
  font-size: 22px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  color: ${({ theme }) => theme.colors.main.textMain};
  margin-top: ${({ marginTop }) => marginTop || "0"};
`;

//activity card
export const ActivityCardDate = styled.div`
  background: ${({ theme, bgColor }) => bgColor || theme.colors.main.white};
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  border-radius: 12px;
  padding: 12px 10px;
  color: ${({ color, theme }) => color || theme.colors.main.labelText};
  text-align: center;
  font-size: 12px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.main.primary};
    border-color: ${({ theme }) => theme.colors.main.primary};
    color: ${({ theme }) => theme.colors.main.white};
  }
  @media (max-width: 1400px) {
    padding: 8px 8px;
  }
`;

//top projects card
export const PieChartContainer = styled.div`
  width: 100%;
  background: #f7f8f9;
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
  border-radius: 6px;
  padding: 15px 15px;
  margin-top: 15px;
`;

export const ColorCircle = styled.div`
  width: ${({ size }) => size || "11px"};
  height: ${({ size }) => size || "11px"};
  border-radius: ${({ theme }) => theme.borderRadii.circle};
  background: ${({ background, theme }) =>
    background || theme.colors.main.primary};
`;

//timesheet card
export const TimesheetTime = styled.div`
  width: 100%;
  min-height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid
    ${({ borderColor, theme }) => borderColor || theme.colors.border.secondary};
  margin-top: 10px;
  background: ${({ background }) => background || "#fff"};
  border-radius: 8px;
`;
