import { Fragment, memo } from "react";
import PropTypes from "prop-types";
// import moment from "moment";
import { CommonText } from "../../../../styledComponents/common";
import {
  CalendarContainer,
  UpperTotalHour,
  TimeText,
  TimeSlot,
  TimeSlotSmallInfo,
  TimeSlotCard,
  TimeSlotInnerCard,
  TimesheetTooltipContainer,
  DeleteButton,
  InfoButton,
  EditButton,
} from "../../../../styledComponents/timesheet";
import { ActivityTextSection } from "../../../../styledComponents/members";

import ContentTitle from "./ContentTitle";
import TimesheetDetailsPopup from "./TimesheetDetailsPopup";
import { IdleTimeSlot, IdleTimeDetailsPopup } from "./IdleTimeComponents";
import CommonTooltip from "../../../../components/Tooltip/CommonTooltip";

import {
  getDuration,
  getDurationInMinutes,
  getZeroHourTime,
} from "../helperFunctions";

/* SVG Files */
import deleteWhite from "../../../../assets/img/icons/delete_white.svg";
import Info from "../../../../assets/img/icons/info.svg";
import idleTimeIcon from "../../../../assets/img/icons/idle-time.svg";
import editIcon from "../../../../assets/img/icons/edit-button.svg";

const TimeSlotContent = memo(
  ({
    timesheetList,
    filteredTimeList,
    infoPopup,
    setInfoPopup,
    toggleInfoPopup,
    selectIndex,
    idleTimePopup,
    setIdleTimePopup,
    toggleIdleTimePopup,
    onHoverChange,
    toggleManualTimeModal,
    toggleDeleteTimeModal,
    toggleIdleTimeModal,
    totalIdleTimesheet,
  }) => {
    return (
      <CalendarContainer style={{ marginTop: `40px` }}>
        {/* Left Time Schedules */}
        {filteredTimeList.map((time, index) => (
          <Fragment key={index}>
            <CommonText margin="0">{time.time}</CommonText>
            <TimeSlot
              index={index}
              // startPosition={moment(timesheetList[0].start_time).hours()}
            />
          </Fragment>
        ))}

        {/* This bracket holds all the time data of the page */}
        {timesheetList?.length > 0 &&
          timesheetList.map((card, index) => (
            <Fragment key={index}>
              {infoPopup === index && (
                <TimesheetDetailsPopup
                  index={index}
                  data={card}
                  startPosition={timesheetList[0].start_time.hours()}
                  getDuration={getDuration}
                  toggleDeleteTimeModal={toggleDeleteTimeModal}
                  toggleManualTimeModal={toggleManualTimeModal}
                  setInfoPopup={setInfoPopup}
                  toggleIdleTimePopup={toggleIdleTimePopup}
                />
              )}
               {/* Activity Box Title */}
               {index == 0 &&
                timesheetList.some(
                  (card) => getDurationInMinutes(card.duration) > 20
                ) && (
                <TimeSlotCard
                  top={card.top - 30}
                  height={20}
                  index={index}
                  left={125}
                  bgColor="none"
                  borderColor="none"
                  startPosition={timesheetList[0].start_time.hours()}
                >
                  <ContentTitle />
                </TimeSlotCard>
              )}
              <TimeSlotCard
                key={index}
                top={card.top}
                height={card.height}
                index={index}
                startPosition={timesheetList[0].start_time.hours()}
                small={getDurationInMinutes(card.duration) < 20}
                clickable={
                  getDurationInMinutes(card.duration) <= 40 || card.idleTimes
                }
                onClick={() =>
                  getDurationInMinutes(card.duration) <= 40
                    ? toggleInfoPopup(index)
                    : card.idleTimes
                    ? toggleIdleTimePopup(index)
                    : undefined
                }
              >
                {getDurationInMinutes(card.duration) < 20 ? (
                  <TimeSlotSmallInfo
                    index={index}
                    top={parseInt(card.height / 2)}
                    onClick={() => {
                      toggleInfoPopup(index);
                    }}
                  >
                    <img src={Info} alt="" />
                  </TimeSlotSmallInfo>
                ) : (
                  <TimeSlotInnerCard
                    onMouseEnter={() => onHoverChange(index)}
                    onMouseLeave={() => onHoverChange(-1)}
                  >
                    <ActivityTextSection justify="flex-start">
                      <CommonText name $title>
                        {card.project ? card.project.name : "No project"}
                      </CommonText>
                      {getDurationInMinutes(card.duration) > 40 && (
                        <CommonText fontSize="12px">
                          {card.task ? card.task.name : "No task"}
                        </CommonText>
                      )}
                    </ActivityTextSection>
                    <ActivityTextSection>
                      <CommonText name $title>
                        {card.start_time.format("h:mm a")}
                      </CommonText>
                    </ActivityTextSection>
                    <ActivityTextSection>
                      <CommonText name $title>
                        {card.end_time.format("h:mm a")}
                      </CommonText>
                    </ActivityTextSection>
                    <ActivityTextSection justify="flex-end">
                      <CommonText name $title>
                        {getZeroHourTime(card.duration)}
                      </CommonText>
                    </ActivityTextSection>

                    {/* Pop ups */}
                    {selectIndex === index && (
                      <>
                        {card.idleTimes && card.idleTimes.length > 0 && (
                          <InfoButton
                            onClick={() => {
                              toggleIdleTimePopup(index);
                            }}
                            bgColor="#7F9DBF"
                            right={
                              getDurationInMinutes(card.duration) <= 40 &&
                              getDurationInMinutes(card.duration) >= 20
                                ? "75px"
                                : "45px"
                            }
                          >
                            <img src={idleTimeIcon} alt="" />
                            <TimesheetTooltipContainer>
                              <CommonTooltip
                                tooltipText="Idle Time"
                                left="-55px"
                                top="-46px"
                                width="95px"
                                height="25px"
                              />
                            </TimesheetTooltipContainer>
                          </InfoButton>
                        )}
                        <DeleteButton
                          onClick={() => toggleDeleteTimeModal(card)}
                        >
                          <img
                            src={deleteWhite}
                            style={{
                              width: "16px",
                              height: "16px",
                            }}
                            alt="delete"
                          />
                          <TimesheetTooltipContainer>
                            <CommonTooltip
                              tooltipText="Delete"
                              left="-50px"
                              top="-46px"
                              width="85px"
                              height="25px"
                            />
                          </TimesheetTooltipContainer>
                        </DeleteButton>
                        <EditButton
                          index={index}
                          onClick={() => toggleManualTimeModal("edit", card)}
                        >
                          <img
                            src={editIcon}
                            style={{
                              width: "16px",
                              height: "16px",
                            }}
                            alt="edit"
                          />
                          <TimesheetTooltipContainer>
                            <CommonTooltip
                              tooltipText="Edit"
                              left="-50px"
                              top="-46px"
                              width="85px"
                              height="25px"
                            />
                          </TimesheetTooltipContainer>
                        </EditButton>
                        {getDurationInMinutes(card.duration) <= 40 &&
                          getDurationInMinutes(card.duration) >= 20 && (
                            <InfoButton
                              index={index}
                              onClick={() => toggleInfoPopup(index)}
                            >
                              <img src={Info} alt="" />
                              <TimesheetTooltipContainer>
                                <CommonTooltip
                                  tooltipText="Details"
                                  left="-50px"
                                  top="-46px"
                                  width="85px"
                                  height="25px"
                                />
                              </TimesheetTooltipContainer>
                            </InfoButton>
                          )}
                      </>
                    )}
                  </TimeSlotInnerCard>
                )}
              </TimeSlotCard>

              {card.idleTimes && card.idleTimes.length > 0 && (
                <>
                  {idleTimePopup === index && (
                    <IdleTimeDetailsPopup
                      index={index}
                      idleTimes={card.idleTimes}
                      timesheetId={card.id}
                      project={card.project}
                      task={card.task}
                      timesheetTop={card.top}
                      height={card.idleTimes.length * 70 + 25 + 50}
                      startPosition={timesheetList[0].start_time.hours()}
                      setIdleTimePopup={setIdleTimePopup}
                      toggleDeleteTimeModal={toggleDeleteTimeModal}
                      toggleIdleTimeModal={toggleIdleTimeModal}
                      totalIdleTimesheet={totalIdleTimesheet}
                    />
                  )}
                  {card.idleTimes.map((idleTimeData, idleIndex) => (
                    <IdleTimeSlot
                      index={idleIndex}
                      timesheetIndex={index}
                      idleTimeData={idleTimeData}
                      timesheetDuration={card.duration}
                      startPosition={timesheetList[0].start_time.hours()}
                    />
                  ))}
                </>
              )}
            </Fragment>
          ))}
      </CalendarContainer>
    );
  }
);

TimeSlotContent.propTypes = {
  timesheetList: PropTypes.array.isRequired,
  filteredTimeList: PropTypes.array.isRequired,
  infoPopup: PropTypes.number.isRequired,
  setInfoPopup: PropTypes.func.isRequired,
  toggleInfoPopup: PropTypes.func.isRequired,
  selectIndex: PropTypes.number.isRequired,
  idleTimePopup: PropTypes.number.isRequired,
  setIdleTimePopup: PropTypes.func.isRequired,
  toggleIdleTimePopup: PropTypes.func.isRequired,
  onHoverChange: PropTypes.func.isRequired,
  toggleManualTimeModal: PropTypes.func.isRequired,
  toggleDeleteTimeModal: PropTypes.func.isRequired,
};

export default TimeSlotContent;
