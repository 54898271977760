import { connect } from "react-redux";

// Component
import ActivitySettings from "./ActivitySettings";

// Actions
import {
  getScreenShotSettings,
  updateScreenShotSettings,
  getMembersList,
  updateMembersList,
  getIdleTimeSettings,
  updateIdleTimeSettings,
} from "./activitySettingsActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  isLoading: state.activitySettings.isLoading,
  membersListLoading: state.activitySettings.membersListLoading,

  selectedOrganization: state.organization.selectedOrganization,

  screenshotsTracking: state.activitySettings.screenshotsTracking,
  screenshotsBlur: state.activitySettings.screenshotsBlur,
  screenshotsFrequency: state.activitySettings.screenshotsFrequency,
  screenshotsDelete: state.activitySettings.screenshotsDelete,
  askInstantScreenshotPermission:
    state.activitySettings.ask_instant_screenshot_permission,
  screenRecords: state.activitySettings.screenRecords,

  membersList: state.activitySettings.membersTrackingList,
  refreshMList: state.activitySettings.refreshMList,

  // idle time
  idleTimeSettings: state.activitySettings.idleTimeSettings,

  // pagination
  totalMembersCount: state.activitySettings.totalMembersCount,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getScreenshotSettings: (payload) => dispatch(getScreenShotSettings(payload)),
  updateScreenshotSettings: (payload) =>
    dispatch(updateScreenShotSettings(payload)),
  getMembersList: (details) => dispatch(getMembersList({ ...details })),
  updateMembersList: (payload) => dispatch(updateMembersList(payload)),
  getIdleTimeSettings: (payload) => dispatch(getIdleTimeSettings(payload)),
  updateIdleTimeSettings: (payload) =>
    dispatch(updateIdleTimeSettings(payload)),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(ActivitySettings);
