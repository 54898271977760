//Effects
import { all, put, takeLatest } from "redux-saga/effects";

//Service
import { defaultApi } from "../../utils/axiosApi";

//Constants
import {
  GET_TIMESHEET_APPROVAL_AWAITING_LIST,
  GET_TIMESHEET_APPROVAL_AWAITING_LIST_SUCCESS,
  GET_TIMESHEET_APPROVAL_AWAITING_LIST_FAILURE,
  GET_TIMESHEET_APPROVAL_HISTORY,
  GET_TIMESHEET_APPROVAL_HISTORY_SUCCESS,
  GET_TIMESHEET_APPROVAL_HISTORY_FAILURE,
  APPROVE_OR_REJECT_TIMESHEETS,
  APPROVE_OR_REJECT_TIMESHEETS_SUCCESS,
  APPROVE_OR_REJECT_TIMESHEETS_FAILURE,
  LOAD_MORE_TIMESHEET_APPROVAL_LIST,
  LOAD_MORE_TIMESHEET_APPROVAL_LIST_SUCCESS,
  LOAD_MORE_TIMESHEET_APPROVAL_LIST_FAILURE,
  DELETE_AWAITING_TIMESHEETS_LIST,
  DELETE_AWAITING_TIMESHEETS_LIST_SUCCESS,
  DELETE_AWAITING_TIMESHEETS_LIST_FAILURE,
} from "../../modules/constants";

//Handle daily timesheet request
export function* getTimesheetApprovalAwaitingListRequest({ payload }) {
  const { organization_id, start_date, end_date, limit, offset, user_id } =
    payload;
  try {
    const url = `${organization_id}/approval-timesheets/?start_date=${start_date}&end_date=${end_date}${
      offset ? `&offset=${offset}` : ""
    }${limit ? `&limit=${limit}` : ""}${user_id ? `&user_id=${user_id}` : ""}`;

    const response = yield defaultApi(url, "GET");

    yield put({
      type: GET_TIMESHEET_APPROVAL_AWAITING_LIST_SUCCESS,
      payload: { ...payload, data: response },
    });
  } catch (err) {
    yield put({
      type: GET_TIMESHEET_APPROVAL_AWAITING_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* getTimesheetApprovalHistoryRequest({ payload }) {
  const { organization_id, start_date, end_date, limit, offset, user_id } =
    payload;
  try {
    const url = `${organization_id}/approval-timesheets/?start_date=${start_date}&end_date=${end_date}&history=true${
      offset ? `&offset=${offset}` : ""
    }${limit ? `&limit=${limit}` : ""}${user_id ? `&user_id=${user_id}` : ""}`;

    const response = yield defaultApi(url, "GET");

    yield put({
      type: GET_TIMESHEET_APPROVAL_HISTORY_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_TIMESHEET_APPROVAL_HISTORY_FAILURE,
      payload: err,
    });
  }
}

export function* approveOrRejectTimesheetsRequest({ payload }) {
  const { organization_id, date, data } = payload;
  try {
    const url = `${organization_id}/approval-timesheets/`;
    const response = yield defaultApi(url, "POST", data);
    yield put({
      type: APPROVE_OR_REJECT_TIMESHEETS_SUCCESS,
      payload: { data: response, approval_data: data, date },
    });
  } catch (err) {
    yield put({
      type: APPROVE_OR_REJECT_TIMESHEETS_FAILURE,
      payload: err,
    });
  }
}

export function* loadMoreTimesheetApprovalListRequest({ payload }) {
  const {
    organization_id,
    start_date,
    end_date,
    limit,
    offset,
    user_id,
    type,
  } = payload;
  try {
    const url = `${organization_id}/approval-timesheets/?start_date=${start_date}&end_date=${end_date}&offset=${offset}&limit=${limit}&history=${
      type === "history" ? "true" : "false"
    }${user_id ? `&user_id=${user_id}` : ""}`;
    const response = yield defaultApi(url, "GET");
    yield put({
      type: LOAD_MORE_TIMESHEET_APPROVAL_LIST_SUCCESS,
      payload: { data: response, date: start_date, type },
    });
  } catch (err) {
    yield put({
      type: LOAD_MORE_TIMESHEET_APPROVAL_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* deleteAwaitingTimesheetsRequest({ payload }) {
  const { organization_id, timesheet_id, ...data } = payload;
  try {
    const url = `${organization_id}/timesheets/timesheets-manual/${timesheet_id}/delete/`;
    const response = yield defaultApi(url, "POST", data);
    yield put({
      type: DELETE_AWAITING_TIMESHEETS_LIST_SUCCESS,
      payload: { ...payload, data: response, timesheet_id },
    });
  } catch (err) {
    yield put({
      type: DELETE_AWAITING_TIMESHEETS_LIST_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(
      GET_TIMESHEET_APPROVAL_AWAITING_LIST,
      getTimesheetApprovalAwaitingListRequest
    ),
    takeLatest(
      GET_TIMESHEET_APPROVAL_HISTORY,
      getTimesheetApprovalHistoryRequest
    ),
    takeLatest(APPROVE_OR_REJECT_TIMESHEETS, approveOrRejectTimesheetsRequest),

    takeLatest(
      LOAD_MORE_TIMESHEET_APPROVAL_LIST,
      loadMoreTimesheetApprovalListRequest
    ),
    takeLatest(
      DELETE_AWAITING_TIMESHEETS_LIST,
      deleteAwaitingTimesheetsRequest
    ),
  ]);
}
