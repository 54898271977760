import { useState, useEffect } from "react";
import styled from "styled-components";
import moment from "moment-timezone";
import SingleDatePicker from "../../../../components/SingleDatePicker/SingleDatePicker";

import {
  CardTitle,
  InputWithLabelSection,
  InputLabel,
  RedStar,
  FormAlert,
  CommonText,
  ColoredText,
} from "../../../../styledComponents/common";
import {
  WhiteButton,
  PrimaryButton,
} from "../../../../styledComponents/buttons";

import TimeRangeComponent from "./TimeRangeComponent";
import TimezoneDropdown from "./ManualTimeTimezone";

import { Modal, ModalBody } from "reactstrap";

import Select from "../../../../components/ReactSelectDropdown";
import ProjectDropdown from "../../../../components/CommonFilter/ProjectDropdown";
import TaskDropdown from "../../../../components/CommonFilter/TaskDropdown";

import { checkUrl, checkHtml, getFullName } from "../../../../utils/helper";

import ModalDropdownStyle from "../../../../components/DropdownStyle/ModalDropdownStyle";
import ButtonTextLoader from "../../../../components/Loaders/ButtonTextLoader";
import { DashDiv, TimeDash } from "../../../../styledComponents/timesheet";

const CustomModal = styled(Modal)`
  max-width: 540px;
  padding-top: 0px;

  @media screen and (min-width: 1400px) {
    margin-top: 10vh;
  }
`;
const ManualTimeModalBody = styled(ModalBody)`
  padding: 30px;
`;
const ModalInputSection = styled(InputWithLabelSection)`
  margin: 0px;
  margin-top: ${({ mt }) => mt ?? "6px"};
  align-self: ${({center}) => center ? "center" : "flex-start"};
`;
const DateTimeContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) => columns ?? "50% 45%"};
  gap: 20px;
  justify-content: space-between;
`;
const CommentTextArea = styled.textarea`
  min-height: 68px;
  background: ${({ theme }) => theme.colors.main.whiteBg};
  border: 1px solid
    ${({ borderColor, theme, error }) =>
      error
        ? theme.input.borderColor.error
        : borderColor
        ? borderColor
        : theme.input.borderColor.default};
  border-radius: ${({ theme }) => theme.input.borderRadius};
  padding: 10px;
  color: ${({ textColor, theme }) => textColor || theme.input.textColor};
  font-size: ${({ fontSize, theme }) => fontSize || theme.input.fontSize};
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${({ theme }) => theme.input.placeholder.color};
  }
  :-ms-input-placeholder {
    color: ${({ theme }) => theme.input.placeholder.color};
  }
  &:hover {
    border-color: ${({ hoverBorderColor, theme, error }) =>
      !error && (hoverBorderColor || theme.input.borderColor.hover)};
    box-shadow: ${({ theme, error }) => !error && theme.input.focusShadow};
  }
  &:focus {
    outline: none;
    border-color: ${({ focusBorderColor, theme, error }) =>
      !error && (focusBorderColor || theme.input.borderColor.focus)};
    box-shadow: ${({ theme, error }) => !error && theme.input.focusShadow};
  }
`;
const ModalButtonSection = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  grid-column-gap: 12px;
  margin-top: 20px;
`;

const TimeTextContainer = styled.div`
  display: flex;
  grid-column-gap: 10px;
  align-items: center;
  margin-top: 8px;
`;

export const getHoursAndMinutes = (mins) => {
  const hours = Math.floor(mins / 60);
  const minutes = mins % 60;
  if (mins <= 0) return "";

  return hours <= 0
    ? `${String(minutes).padStart(2, "0")}m`
    : `${hours}h ${String(minutes).padStart(2, "0")}m`;
};

const ManualTimeModal = (props) => {
  const [memberOptions, setMemberOptions] = useState("");
  const [selectedMember, setSelectedMember] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [selectedTimezone, setSelectedTimezone] = useState(null);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  const [comment, setComment] = useState("");

  const [saveErrors, setSaveErrors] = useState({});

  const {
    isOpen,
    toggle,
    isLoading,
    action,
    selectedOrganization,
    getOrganizationMembersList,
    membersList,
    getProjectAssigneeList,
    projectAssigneeList,
    manualTimeAdded,
    timesheetInfo,
    filterSelectedDate,
    filterSelectedMember,
    handleAddTimesheet,
    handleUpdateTimesheet,
    triggerEffect,
  } = props;

  useEffect(() => {
    if (timesheetInfo?.id && timesheetInfo?.project?.id) {
      setSelectedProject({
        label: timesheetInfo.project.name,
        value: timesheetInfo.project.id,
      });
      if (timesheetInfo?.id && timesheetInfo?.task?.id) {
        setSelectedTask({
          label: timesheetInfo.task.name,
          value: timesheetInfo.task.id,
        });
      }
    }
  }, [timesheetInfo, triggerEffect]);

  useEffect(() => {
    if (isOpen) {
      if (selectedOrganization && selectedOrganization.id) {
        const payload = { organization_id: selectedOrganization.id };
        getOrganizationMembersList(payload);
      }
      if (filterSelectedMember && filterSelectedMember.id) {
        selectMember(filterSelectedMember);
      } else if (membersList && membersList.length > 0) {
        const userId = localStorage.getItem("user_id");
        membersList.some((member) => {
          if (member.id === userId) {
            selectMember(member);
            return true;
          }
          return false;
        });
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      if (selectedOrganization.timezone) {
        setSelectedTimezone({
          type: "Organization",
          timezone: selectedOrganization.timezone,
          offset: selectedOrganization.timezoneoffset,
        });
      }
      // let payload = {
      //   organization_id: selectedOrganization.id,
      // };
      // props.getOrganizationMembersList(payload);
    }
  }, [selectedOrganization]);

  useEffect(() => {
    getMemberOptions();
  }, [membersList]);

  useEffect(() => {
    if (projectAssigneeList && projectAssigneeList.length > 0) {
      let list = [];
      projectAssigneeList.map((assignee) => {
        list.push({
          label: getFullName(assignee.user),
          value: assignee.user.id,
          avatar: assignee.user.avatar,
        });
      });
      setMemberOptions(list);
    } else if (projectAssigneeList && projectAssigneeList.length === 0) {
      setMemberOptions([]);
    }
  }, [projectAssigneeList]);

  useEffect(() => {
    if (manualTimeAdded) {
      clearStates();
    }
  }, [manualTimeAdded]);

  useEffect(() => {
    if (filterSelectedDate) {
      setSelectedDate(filterSelectedDate);
    }
  }, [filterSelectedDate]);

  const getMemberOptions = () => {
    if (membersList && membersList.length > 0) {
      const userId = localStorage.getItem("user_id");
      let list = [];
      membersList.map((member) => {
        list.push({
          label: member.name,
          value: member.id,
          avatar: member.avatar,
          timezone: member.timezone,
        });
      });
      if (
        selectedOrganization &&
        selectedOrganization.role &&
        !(
          selectedOrganization.role === "admin" ||
          selectedOrganization.role === "owner"
        )
      ) {
        if (userId) {
          list = list.filter((item) => item.value === userId);
        }
      }
      setMemberOptions(list);
    } else if (membersList && membersList.length === 0) {
      setMemberOptions([]);
    }
  };

  const selectProject = (project) => {
    const { selectedOrganization } = props;
    if (selectedOrganization) {
      const payload = {
        organization_id: selectedOrganization.id,
        project_id: project.value,
      };
      if (!selectedMember || (selectedMember && !selectedMember.id)) {
        getProjectAssigneeList(payload);
      }
    }
    setSelectedProject(project);
    setSelectedTask(null);
  };

  const selectTask = (task) => {
    setSelectedTask(task);
  };

  const selectMember = (member) => {
    if (action === "create") {
      if (selectedMember?.id) {
        setSelectedProject(null);
      }
    }
    if (member && member.id) {
      setSelectedMember(member);
    } else if (member && member.value) {
      setSelectedMember({
        id: member.value,
        name: member.label,
        avatar: member.avatar,
        timezone: member.timezone,
      });
    } else {
      setSelectedMember(null);
    }

    if (
      member.timezone &&
      selectedTimezone &&
      selectedTimezone.type === "Member"
    ) {
      setSelectedTimezone({
        type: "Member",
        timezone: member.timezone.tz,
        offset: member.timezone.offset,
      });
    }
  };

  const onTimezoneSelect = (timezone) => {
    setSelectedTimezone(timezone);
  };

  const handleStartTime = (time) => {
    if (time) {
      const start_time = time.replace(/ /g, "");
      setStartTime(moment(start_time, "h:mmA").format("HH:mm:ss"));
    } else {
      setStartTime("");
    }
  };
  const handleEndTime = (time) => {
    if (time) {
      const end_time = time.replace(/ /g, "");
      setEndTime(moment(end_time, "h:mmA").format("HH:mm:ss"));
    } else {
      setEndTime("");
    }
  };

  const onDateSelect = (date) => {
    setSelectedDate(moment(date).format("YYYY-MM-DD"));
  };

  const handleAddTime = () => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      checkCommentError() &&
      moment(`${selectedDate}T${endTime}`, "YYYY-MM-DDTHH:mm:ss").isBefore(
        moment()
      )
    ) {
      let start = `${selectedDate}T${startTime}`;
      let end = `${selectedDate}T${endTime}`;
      if (
        selectedTimezone &&
        selectedTimezone.type === "Member" &&
        selectedTimezone.timezone &&
        selectedOrganization.timezone
      ) {
        start = moment
          .tz(start, selectedTimezone.timezone)
          .tz(selectedOrganization.timezone)
          .format("YYYY-MM-DDTHH:mm:ss");
        end = moment
          .tz(end, selectedTimezone.timezone)
          .tz(selectedOrganization.timezone)
          .format("YYYY-MM-DDTHH:mm:ss");
      }
      let payload = {
        organization_id: selectedOrganization.id,
        project: selectedProject.value,
        start_time: start,
        end_time: end,
        comment: comment,
        date: selectedDate,
      };
      if (selectedMember) {
        payload.user = selectedMember;
      }
      if (selectedTask && selectedTask.value) {
        payload.task = selectedTask.value;
      }
      handleAddTimesheet(payload);
    }
  };

  const handleUpdateTime = () => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      timesheetInfo &&
      timesheetInfo.id &&
      checkCommentError()
    ) {
      let payload = {
        organization_id: selectedOrganization.id,
        project: selectedProject.value,
        comment: comment,
        id: timesheetInfo.id,
        start_time: timesheetInfo.start_time,
        end_time: timesheetInfo.end_time,
        user: selectedMember,
        date: selectedDate
      };

      if (selectedTask && selectedTask.value) {
        payload.task = selectedTask.value;
      }

      handleUpdateTimesheet(payload);
    }
  };

  const checkCommentError = () => {
    let formIsValid = true;
    const saveErrors = {};
    if (checkUrl(comment)) {
      formIsValid = false;
      saveErrors["comment"] = "Comment can not contain url.";
    } else if (checkHtml(comment)) {
      formIsValid = false;
      saveErrors["comment"] = "Invalid input in comment.";
    }
    setSaveErrors(saveErrors);
    return formIsValid;
  };

  const checkError = () => {
    let formIsValid = true;
    if (!selectedMember || (selectedMember && !selectedMember.id)) {
      formIsValid = false;
    } else if (!selectedProject || !selectedProject.value) {
      formIsValid = false;
    } else if (!selectedDate && action === "create") {
      formIsValid = false;
    } else if ((!startTime || !endTime) && action === "create") {
      formIsValid = false;
    } else if (!comment.trim()) {
      formIsValid = false;
    }
    if (
      action === "create" &&
      (!moment(`${selectedDate}T${endTime}`, "YYYY-MM-DDTHH:mm:ss").isBefore(
        moment()
      ) ||
        !moment(endTime, "HH:mm:ss").isAfter(moment(startTime, "HH:mm:ss")))
    ) {
      formIsValid = false;
    }
    return formIsValid;
  };
  const toggleModal = () => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
      };
      getOrganizationMembersList(payload);
    }
    // getMemberOptions();
    clearStates();
    toggle();
  };

  const checkTimeError = () => {
    const tmpSaveErrors = { ...saveErrors };
    delete tmpSaveErrors["time"];
    if (!moment(endTime, "HH:mm:ss").isAfter(moment(startTime, "HH:mm:ss"))) {
      tmpSaveErrors["time"] = "End time must be after start time";
    } else if (
      !moment(`${selectedDate}T${endTime}`, "YYYY-MM-DDTHH:mm:ss").isBefore(
        moment()
      )
    ) {
      tmpSaveErrors["time"] = "Future time cannot be set";
    }
    setSaveErrors(tmpSaveErrors);
  };

  useEffect(() => {
    // if (action !== "create") return;
    checkTimeError();
  }, [startTime, endTime, selectedDate]);

  const clearStates = () => {
    setSelectedProject(null);
    setSelectedTask(null);
    setSelectedDate(filterSelectedDate || moment().format("YYYY-MM-DD"));
    setStartTime("");
    setEndTime("");
    setComment("");
    // setTaskOptions([]);

    setSaveErrors({});
    if (selectedOrganization && selectedOrganization.timezone) {
      setSelectedTimezone({
        type: "Organization",
        timezone: selectedOrganization.timezone,
        offset: selectedOrganization.timezoneoffset,
      });
    }
  };

  const disableDropdowns = () => {
    if (action == "edit") return true;
    return false;
  };

  return (
    <CustomModal isOpen={isOpen} toggle={toggleModal} unmountOnClose={true}>
      <ManualTimeModalBody>
        <CardTitle>
          {action == "create" ? "Add Manual Time" : "Edit Timesheet"}
        </CardTitle>
        <ModalInputSection gap="8px">
          <InputLabel>
            People <RedStar>*</RedStar>
          </InputLabel>

          <Select
            isDisabled={disableDropdowns()}
            isSearchable
            placeholder="Select Member"
            value={
              selectedMember && selectedMember.id
                ? {
                    value: selectedMember.id,
                    label: selectedMember.name,
                  }
                : null
            }
            options={memberOptions}
            onChange={(e) => {
              selectMember(e);
            }}
            styles={ModalDropdownStyle()}
          />
        </ModalInputSection>
        <ModalInputSection gap="8px">
          <InputLabel>
            Project <RedStar>*</RedStar>
          </InputLabel>

          <ProjectDropdown
            selectedOrganization={selectedOrganization}
            selectedProject={selectedProject}
            selectProject={selectProject}
            selectedMember={selectedMember}
            defaultProject = {action === 'create'}
            style={ModalDropdownStyle()}
          />
        </ModalInputSection>
        <ModalInputSection gap="8px">
          <InputLabel>Task</InputLabel>
          <TaskDropdown
            selectedOrganization={selectedOrganization}
            selectedProject={selectedProject}
            selectedTask={selectedTask}
            selectTask={selectTask}
            selectedMember={selectedMember}
            isManualTimesheet
            defaultProject = {action === 'create'}
            style={ModalDropdownStyle()}
          />
        </ModalInputSection>
        <ModalInputSection gap="8px">
          <InputLabel>Timezone</InputLabel>

          <TimezoneDropdown
            isDisabled={disableDropdowns()}
            selectedOrganization={selectedOrganization}
            selectedMember={selectedMember}
            selectTimezone={onTimezoneSelect}
            selectedTimezone={selectedTimezone}
          />
        </ModalInputSection>
        <DateTimeContainer columns="auto 220px 58px">
          <ModalInputSection gap="8px">
            <InputLabel>
              Select Date <RedStar>*</RedStar>
            </InputLabel>
            <SingleDatePicker
              disabled={disableDropdowns()}
              dateDisplayFormat="DD/MM/YYYY"
              placeholder="Select Date"
              date={selectedDate ? moment(selectedDate) : null}
              onDateChange={onDateSelect}
              allowPreviousDates
              disableFutureDates
            />
          </ModalInputSection>
          <ModalInputSection gap="8px">
            <InputLabel>
              Time Range <RedStar>*</RedStar>
            </InputLabel>

            {action == "create" ? (
              <TimeRangeComponent
                disabled={disableDropdowns()}
                setStartTime={handleStartTime}
                setEndTime={handleEndTime}
                lastHour={true}
              />
            ) : timesheetInfo?.id ? (
              <TimeTextContainer>
                {moment(timesheetInfo.start_time).format("h:mm A")}
                <DashDiv>
                  <TimeDash />
                </DashDiv>
                {moment(timesheetInfo.end_time).format("h:mm A")}
              </TimeTextContainer>
            ) : null}
          </ModalInputSection>
          <ModalInputSection
            center
            mt="23px"
          >
            {action === "edit" && timesheetInfo ? (
              <ColoredText type="blue" fontSize="13px" margin="8px 0 0 5px">
                {getHoursAndMinutes(
                  moment(timesheetInfo.end_time, "HH:mm:ss").diff(
                    moment(timesheetInfo.start_time, "HH:mm:ss"),
                    "minutes"
                  )
                )}
              </ColoredText>
            ) : (
              <ColoredText type="blue" fontSize="13px" margin="8px 0 0 5px">
                {getHoursAndMinutes(
                  moment(endTime, "HH:mm:ss").diff(
                    moment(startTime, "HH:mm:ss"),
                    "minutes"
                  )
                )}
              </ColoredText>
            )}
          </ModalInputSection>
        </DateTimeContainer>
        {action != "edit" && saveErrors && saveErrors["time"] && (
          <ColoredText type="error" fontSize="11.5px" margin="8px 0 0 178px">
            {saveErrors["time"]}
          </ColoredText>
        )}
        <ModalInputSection gap="8px" mt="15px">
          <InputLabel>
            Why do you want to? <RedStar>*</RedStar>
          </InputLabel>

          <CommentTextArea
            id="comment"
            placeholder="Type your reason here..."
            value={comment}
            onChange={(e) => {
              setComment(e.target.value);
            }}
            error={
              saveErrors &&
              saveErrors["comment"] &&
              saveErrors["comment"] !== undefined
            }
          />
          {saveErrors &&
            saveErrors["comment"] &&
            saveErrors["comment"] !== undefined && (
              <FormAlert margin="0 0 0 5px">{saveErrors["comment"]}</FormAlert>
            )}
        </ModalInputSection>
        <ModalButtonSection>
          <WhiteButton type="cancel" onClick={toggleModal}>
            Cancel
          </WhiteButton>
          <PrimaryButton
            padding={isLoading ? "0 15px" : "0 35px"}
            disabled={!checkError()}
            onClick={action == "edit" ? handleUpdateTime : handleAddTime}
          >
            {isLoading ? (
              <ButtonTextLoader
                loadingText={action == "create" ? "Saving" : "Updating"}
                fontSize="13px"
              />
            ) : (
              `${action == "create" ? "Save" : "Update"}`
            )}
          </PrimaryButton>
        </ModalButtonSection>
      </ManualTimeModalBody>
    </CustomModal>
  );
};

export default ManualTimeModal;
