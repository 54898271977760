import React, { useState, useEffect } from "react";

import { UncontrolledTooltip } from "reactstrap";

import moment from "moment";

import ActivityReportLoader from "../ContentLoader/ActivityReportLoader";

import {
  DashboardCardTitle,
  DashboardCardSubTitle,
  LinkToReport,
  ActivityCardDate,
} from "../../styledComponents/dashboard";
import {
  CommonCardContainer,
  WeeklyActivityTitleContainer,
  CardTitleAndSubtitleContainer,
  VerticalBarsContainer,
  SingleBarContainer,
  NoDataContainer,
  NoDataImg,
  NoDataText,
  CardLoaderContainer,
} from "./cardStyles";
import VerticalProgressBar from "../ProgressBar/VerticalProgressBar";

import noDataImg from "../../assets/img/common/nodata_activity.svg";

const WeeklyActivityCard = (props) => {
  const [activity, setActivity] = useState([]);
  useEffect(() => {
    if (props.weeklyActivity && props.weeklyActivity.length > 0) {
      let activities = [];
      let startDate = moment().subtract(1, "day").startOf("isoWeek");
      while (
        moment(startDate).isSameOrBefore(
          moment().subtract(1, "day").endOf("isoWeek")
        )
      ) {
        activities.push({
          date: startDate,
          activity: 0,
        });
        startDate = moment(startDate).add(1, "days");
      }
      props.weeklyActivity.map((item) => {
        activities.map((date) => {
          if (moment(date.date).format("YYYY-MM-DD") === item.date) {
            date.activity = parseInt(item.average_activity * 100);
          }
        });
      });
      setActivity(activities);
    } else if (
      (props.weeklyActivity && props.weeklyActivity.length === 0) ||
      !props.weeklyActivity
    ) {
      setActivity([]);
    }
  }, [props.weeklyActivity]);
  return (
    <CommonCardContainer>
      <WeeklyActivityTitleContainer>
        <CardTitleAndSubtitleContainer>
          <DashboardCardTitle>Activity Report</DashboardCardTitle>
          <DashboardCardSubTitle>All Projects</DashboardCardSubTitle>
        </CardTitleAndSubtitleContainer>
        {props?.role !== "member" &&
          props?.currentMode === "organization" &&
          activity &&
          activity.length > 0 && (
            <LinkToReport to={"/user/reports/time-and-activity"}>
              View More
            </LinkToReport>
          )}
      </WeeklyActivityTitleContainer>
      {props.isLoading && (
        <CardLoaderContainer>
          <ActivityReportLoader />
        </CardLoaderContainer>
      )}
      {!props.isLoading && activity && activity.length === 0 && (
        <NoDataContainer>
          <NoDataImg src={noDataImg} alt="no-data" />
          <NoDataText>No data found.</NoDataText>
        </NoDataContainer>
      )}
      <VerticalBarsContainer>
        {!props.isLoading &&
          activity &&
          activity.length > 0 &&
          activity.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <SingleBarContainer id={`activityByDate-${index}`}>
                  <VerticalProgressBar
                    width="10px"
                    height="145px"
                    borderRadius="5px"
                    red="#FE5151"
                    yellow="#F2C741"
                    green="#20bead"
                    percent={item.activity}
                    backgroundColor="#B5B5B5"
                  />
                  <ActivityCardDate>
                    <div>{moment(item.date).format("MMM")}</div>
                    <div>{moment(item.date).format("DD")}</div>
                  </ActivityCardDate>
                </SingleBarContainer>
                <UncontrolledTooltip
                  placement="right"
                  target={`activityByDate-${index}`}
                >
                  {`${item.activity}%`}
                </UncontrolledTooltip>
              </React.Fragment>
            );
          })}
      </VerticalBarsContainer>
    </CommonCardContainer>
  );
};

export default WeeklyActivityCard;
