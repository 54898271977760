import { useState, useEffect, useMemo, useCallback } from "react";
import moment from "moment";
import { timeList } from "./timeList";

// hooks
import { useTimesheetData } from "./hooks/useTimesheetData";
import { useTimesheetActions } from "./hooks/useTimesheetActions";

import {
  PageTitle,
  Container,
  CommonGrid,
} from "../../../styledComponents/common";
import {
  ColoredButtonWithIcon,
  ButtonIcon,
} from "../../../styledComponents/buttons";

import TimesheetFilter from "../TimesheetFilter";
import { TimesheetLoading, EmptyTimesheet } from "./components/LoadingStates";
import TimeSlotContent from "./components/TimeSlotContent";
import ManualTimeModal from "./components/ManualTimeModal";
import DeleteTimeModal from "./components/DeleteTimeModal";
import UserActivityInfo from "../../ActivityNew/Screenshots/UserActivityInfo";

/* SVG Files */
import Plus from "../../../assets/img/icons/plus_white.svg";
import IdleTimeModal from "./IdleTimeModal";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Daily = (props) => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  window.history.replaceState(null, "", `${window.location.pathname}`);
  const [valueFromParams, setValueFromParams] = useState({
    date: queryParams.get("date"),
    project: queryParams.get("project"),
    task: queryParams.get("task"),
    member: queryParams.get("member"),
  });

  const [manualTimeModalVisible, setManualTimeModalVisible] = useState(false);
  const [manualTimeAction, setManualTimeAction] = useState("create");
  const [deleteTimesheetModalVisible, setDeleteTimesheetModalVisible] =
    useState(false);
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [selectedProject, setSelectedProject] = useState({
    value: null,
    label: "(All Active Projects)",
  });
  const [selectedTask, setSelectedTask] = useState({
    value: "",
    label: "(All Tasks)",
  });
  const [selectedMember, setSelectedMember] = useState("");
  const [selectedTimesheetInfo, setSelectedTimesheetInfo] = useState(null);
  const [triggerEffect, setTriggerEffect] = useState(0);
  const [selectIndex, setSelectIndex] = useState(-1);
  const [currentTimesheet, setCurrentTimesheet] = useState(null);
  const [infoPopup, setInfoPopup] = useState(-1);
  const [idleTimePopup, setIdleTimePopup] = useState(-1);
  const [idleTimeModalVisible, setIdleTimeModalVisible] = useState(false);
  const [totalIdleTimesheet, setTotalIdleTimesheet] = useState(0);
  const [selectedTimezone, setSelectedTimezone] = useState(null);

  // Memoized values
  const isToday = useMemo(
    () => moment(selectedDate).isSame(moment(), "day"),
    [selectedDate]
  );

  const {
    selectedOrganization,
    getDailyTimesheets,
    timesheet,
    createDailyTimesheets,
    updateDailyTimesheets,
    manualTimeAdded,
    manualTimeDetails,
    clearDailyTimesheetAdded,
    deleteDailyTimesheets,
    projectsList,
    getProjectAssigneeList,
    projectAssigneeList,
    projectTaskList,
    getOrganizationMembersList,
    membersList,
    manualTimeLoading,
    deleteTimesheetLoading,
    isLoading,
    userActivity,
    clockInOutList,
    getUserActivity,
    getClockInOutList,
    liveMembersList,
    getLiveMembersList,
  } = props;

  useEffect(() => {
    if (valueFromParams?.date) {
      setSelectedDate(
        moment(valueFromParams?.date, "DD-MM-YYYY").format("YYYY-MM-DD")
      );
      valueFromParams.date = null;
    }
  }, [valueFromParams?.date]);

  useEffect(() => {
    if (valueFromParams?.member) {
      const member = membersList.find(
        (member) => member.id === valueFromParams?.member
      );
      if (member) {
        setSelectedMember(member);
        valueFromParams.member = null;
      }
    }
  }, [membersList, valueFromParams?.member]);

  const callGetDailyTimesheets = () => {
    let organizationId = selectedOrganization ? selectedOrganization.id : "";
    if (
      organizationId &&
      !valueFromParams?.project &&
      !valueFromParams?.member &&
      !valueFromParams?.task &&
      !valueFromParams?.date
    ) {
      const payload = {
        organization_id: organizationId,
        date: selectedDate,
      };
      if (selectedMember) {
        payload.user_id = selectedMember.id;
      }
      if (selectedProject && selectedProject.value) {
        payload.project_id = selectedProject.value;
      }
      if (selectedTask && selectedTask.value) {
        payload.task_id = selectedTask.value;
      }
      if (selectedTimezone && selectedTimezone.timezone) {
        payload.timezone = selectedTimezone.timezone;
      }
      getDailyTimesheets(payload);
      getClockInOutAndActivity(payload);
    }
  };

  const { timesheetList, durations, idleDurations } =
    useTimesheetData(timesheet);
  const { handleAddTimesheet, handleUpdateTimesheet, handleDeleteTimesheet } =
    useTimesheetActions({
      createDailyTimesheets,
      updateDailyTimesheets,
      deleteDailyTimesheets,
      selectedOrganization,
      getTimesheetList: callGetDailyTimesheets,
      selectedMember,
      setDeleteTimesheetModalVisible,
    });

  const filteredTimeList = useMemo(
    () =>
      timesheetList?.length
        ? timeList.filter(
            (time, index) =>
              index > moment(timesheetList[0].start_time).hours() - 1 &&
              index <
                moment(
                  timesheetList[timesheetList.length - 1].end_time
                ).hours() +
                  2
          )
        : [],
    [timesheetList]
  );

  const getClockInOutAndActivity = (payload) => {
    if (!payload?.organization_id) return;
    const user_id = localStorage.getItem("user_id");

    getClockInOutList({
      organization_id: payload.organization_id,
      start_date: payload.date,
      end_date: payload.date,
      user_id: payload.user_id ?? user_id,
      page: 1,
      tz: payload.timezone ?? "UTC",
    });
    getUserActivity(payload);
  };

  useEffect(() => {
    if (timesheetList?.length) {
      const total = timesheetList
        .map((timesheet) => {
          return timesheet.idleTimes;
        })
        .flat().length;
      setTotalIdleTimesheet(total);
    } else setTotalIdleTimesheet(0);
  }, [timesheetList]);
  useEffect(() => {
    const member_id = localStorage.getItem("user_id");
    if (selectedOrganization && selectedOrganization.id) {
      if (selectedMember?.id || member_id) {
        getLiveMembersList({
          organization_id: selectedOrganization.id,
          user_id: selectedMember?.id ? selectedMember?.id : member_id,
        });
      }
    }
  }, [selectedMember, selectedOrganization]);
  useEffect(() => {
    if (selectedOrganization && selectedOrganization.timezone) {
      setSelectedTimezone({
        type: "Organization",
        timezone: selectedOrganization.timezone,
        offset: selectedOrganization.timezoneoffset,
      });
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (selectedOrganization?.id) {
      callGetDailyTimesheets();
    }
  }, [
    selectedOrganization,
    selectedDate,
    selectedMember,
    selectedProject,
    selectedTask,
    selectedTimezone,
    valueFromParams,
  ]);

  useEffect(() => {
    if (selectedProject.value === valueFromParams?.project) {
      valueFromParams.project = null;
    }
  }, [selectedProject, valueFromParams?.project]);

  useEffect(() => {
    if (
      valueFromParams?.task &&
      !valueFromParams?.project &&
      selectedTask.value === valueFromParams?.task
    ) {
      setTimeout(() => {
        setValueFromParams({});
      }, 100);
    }
  }, [selectedTask, valueFromParams?.task, valueFromParams?.project]);

  const selectProject = (e) => {
    if (
      selectedProject.value === e.value &&
      selectedProject.label === e.label
    ) {
      return;
    } else {
      setSelectedProject(e);
      if (!valueFromParams?.task) {
        setSelectedTask({ value: "", label: "(All Tasks)" });
      }
    }
  };

  const selectTask = (e) => {
    setSelectedTask(e);
  };

  const selectTeamMember = (e) => {
    setSelectedMember(e);
  };

  const clearMember = () => {
    setSelectedMember("");
  };

  const selectDate = (date) => {
    setSelectedDate(date.format("YYYY-MM-DD"));
  };

  const onTimezoneSelect = (timezone) => {
    setSelectedTimezone(timezone);
  };

  const singleDateChange = (direction) => {
    setSelectedDate((prevDate) => {
      const newDate =
        direction === "right"
          ? moment(prevDate).add(1, "days")
          : moment(prevDate).subtract(1, "days");
      selectDate(newDate);
      return newDate;
    });
  };

  const selectToday = () => {
    const date = moment();
    selectDate(date);
  };

  const onHoverChange = (index) => {
    setSelectIndex(index);
  };

  useEffect(() => {
    if (manualTimeAdded) {
      setManualTimeModalVisible(false);
      setTimeout(() => {
        clearDailyTimesheetAdded();
      }, 500);

      if (manualTimeDetails) {
        callGetDailyTimesheets();
        setSelectedDate(manualTimeDetails.date);
        setSelectedMember(manualTimeDetails.user);
      }
    }
  }, [manualTimeAdded, manualTimeDetails]);

  useEffect(() => {
    if (manualTimeDetails) {
      callGetDailyTimesheets();
      setTimeout(() => {
        clearDailyTimesheetAdded();
      }, 500);
    }
  }, [selectedDate, selectedMember]);

  const toggleManualTimeModal = (action = "create", info) => {
    setManualTimeModalVisible(!manualTimeModalVisible);
    setManualTimeAction(action);
    if (action === "edit" && info?.id) {
      setSelectedTimesheetInfo(info);
      setTriggerEffect((prev) => prev + 1);
    }
  };

  const toggleDeleteTimeModal = (timesheet) => {
    if (!deleteTimesheetModalVisible) {
      setCurrentTimesheet(timesheet);
    } else {
      setCurrentTimesheet(null);
    }
    setDeleteTimesheetModalVisible(!deleteTimesheetModalVisible);
  };

  const toggleInfoPopup = (index) => {
    setInfoPopup(index);
  };

  const toggleIdleTimePopup = (index) => {
    setIdleTimePopup(index);
  };

  const toggleIdleTimeModal = () => {
    setIdleTimeModalVisible(!idleTimeModalVisible);
  };

  const renderTimesheetContent = () => {
    if (isLoading) return <TimesheetLoading />;
    if (!timesheetList?.length) return <EmptyTimesheet />;

    return (
      <TimeSlotContent
        timesheetList={timesheetList}
        filteredTimeList={filteredTimeList}
        infoPopup={infoPopup}
        setInfoPopup={setInfoPopup}
        toggleInfoPopup={toggleInfoPopup}
        selectIndex={selectIndex}
        idleTimePopup={idleTimePopup}
        setIdleTimePopup={setIdleTimePopup}
        toggleIdleTimePopup={toggleIdleTimePopup}
        onHoverChange={onHoverChange}
        toggleManualTimeModal={toggleManualTimeModal}
        toggleDeleteTimeModal={toggleDeleteTimeModal}
        toggleIdleTimeModal={toggleIdleTimeModal}
        totalIdleTimesheet={totalIdleTimesheet}
      />
    );
  };

  return (
    <div className="content">
      {/* Modals */}
      <ManualTimeModal
        isOpen={manualTimeModalVisible}
        toggle={toggleManualTimeModal}
        action={manualTimeAction}
        manualTimeAdded={manualTimeAdded}
        selectedOrganization={selectedOrganization}
        getProjectAssigneeList={getProjectAssigneeList}
        projectAssigneeList={projectAssigneeList}
        getOrganizationMembersList={getOrganizationMembersList}
        membersList={membersList}
        handleAddTimesheet={handleAddTimesheet}
        handleUpdateTimesheet={handleUpdateTimesheet}
        isLoading={manualTimeLoading}
        filterSelectedDate={selectedDate}
        filterSelectedMember={selectedMember}
        timesheetInfo={selectedTimesheetInfo}
        triggerEffect={triggerEffect}
      />
      <DeleteTimeModal
        isOpen={deleteTimesheetModalVisible}
        toggle={toggleDeleteTimeModal}
        currentTimesheet={currentTimesheet}
        handleDeleteTimesheet={handleDeleteTimesheet}
        isLoading={deleteTimesheetLoading}
        timezone={selectedTimezone && selectedTimezone.timezone}
      />
      <IdleTimeModal
        isOpen={idleTimeModalVisible}
        toggle={toggleIdleTimeModal}
        timesheets={timesheetList}
        toggleDeleteTimeModal={toggleDeleteTimeModal}
      />

      {/* Header Section */}
      <CommonGrid alignItem="center">
        <PageTitle>Daily Timesheet</PageTitle>
        {(selectedOrganization &&
          selectedOrganization.configuration &&
          selectedOrganization.configuration.allow_user_add_manual_timesheet) ||
        (selectedOrganization &&
          selectedOrganization.role &&
          selectedOrganization.role !== "member") ? (
          <CommonGrid columns="auto">
            <ColoredButtonWithIcon
              style={{ alignSelf: "flex-end" }}
              onClick={() => toggleManualTimeModal("create")}
            >
              <ButtonIcon src={Plus} alt="" />
              Add Manual Time
            </ColoredButtonWithIcon>
          </CommonGrid>
        ) : null}
      </CommonGrid>

      {/* Filter Section */}
      <TimesheetFilter
        page="daily"
        selectedDate={moment(selectedDate)}
        onDateChange={selectDate}
        selectedProject={selectedProject}
        selectProject={selectProject}
        selectedTask={selectedTask}
        selectTask={selectTask}
        selectedMember={selectedMember}
        selectMember={selectTeamMember}
        clearMember={clearMember}
        selectedOrganization={selectedOrganization}
        selectTimezone={onTimezoneSelect}
        selectedTimezone={selectedTimezone}
        singleDateChange={singleDateChange}
        isToday={isToday}
        selectToday={selectToday}
        preselectedProjectId={valueFromParams?.project}
        preselectedTaskId={valueFromParams?.task}
      />

      {/* User Activity Info */}
      {timesheetList?.length > 0 && (
        <UserActivityInfo
          userActivity={userActivity}
          liveMembersList={liveMembersList}
          selectedOrganization={selectedOrganization}
          clockInOutList={clockInOutList}
          setIdleTimeModalVisible={setIdleTimeModalVisible}
        />
      )}

      {/* Main Content */}
      <Container style={{ padding: `10px 30px 30px` }}>
        {renderTimesheetContent()}
      </Container>
    </div>
  );
};

export default Daily;
